import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import Select from 'react-select';
import { handleLogout } from '../../logout';
import styles from './ManagerViewSchedule.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerViewSchedule() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const currentDate = new Date();
    const [ workspaceName, setWorkspaceName ] = useState([]);
    const [ workspaceTier, setWorkspaceTier ] = useState("");
    const [ selectedWorkspace, setSelectedWorkspace ] = useState("");
    const [ workspaceStaff, setWorkspaceStaff ] = useState([]);
    const [ workspaceStaffDetails, setWorkspaceStaffDetails ] = useState([]);
    const [ tasks, setTasks ] = useState([]);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ blockOut, setBlockOut ] = useState(false);
    const [ fullIsClicked, setFullIsClicked ] = useState(false);
    const [ fullDay, setFullDay ] = useState(false);
    const [ autoStaffClicked, setAutoStaffClicked ] = useState(false);
    const [ autoStaff, setAutoStaff ] = useState(false);
    const [ autoManagerClicked, setAutoManagerClicked ] = useState(false);
    const [ autoStaffManager, setAutoStaffManager ] = useState(false);
    const [ selectedSkill, setSelectedSkill ] = useState(null);
    const [ skillOptions, setSkillOptions ] = useState([]);
    const [ autoStaffSkillset, setAutoStaffSkillset ] = useState(false);
    const [ selectedTime, setSelectedTime ] = useState(null);
    const [ autoStaffTimeslot, setAutoStaffTimeslot ] = useState(false);
    const [ timeOptions, setTimeOptions ] = useState([]);
    const [ viewAddingStaff, setViewAddingStaff ] = useState(false);
    const [ createStaffTask, setCreateStaffTask ] = useState({
        taskName: '',
        staff: '',
        startYear: '',
        startMonth: '',
        startDay: '',
        startingTime: '',
        numHours: '',
        workspaceName: '',
        workspaceOwner: '',
    });
    const [ createStaffTaskPopup, setCreateStaffTaskPopup ] = useState(false);
    const [ taskData, setTaskData ] = useState({
        taskName: '',
        numStaff: '',
        numDays: '',
        startDay: '',
        startMonth: '',
        startYear: '',
        startingTime: '',
        numHours: '',
        skill: '',
        timeslot: '',
        workspaceName: '',
        workspaceOwner: '',
        workspaceTier: '',
        automation: autoStaff,
        automationmanager: autoStaffManager ? 1 : 0,
        autoworkspaceskill: autoStaffSkillset ? 1 : 0,
        autotimeslot: autoStaffTimeslot ? 1 : 0,
        blockout: blockOut,
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response) {
                        const decryptedData = JSON.parse(decrypt(response.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'manager') {
                                    // CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
                                        const decryptedToken = JSON.parse(decrypt(response.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support', 'error');
                                                break;
                                            default:
                                                // Find all workspace this manager have
                                                const input = { username: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getAllManagerWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                                                    const decryptedData1 = JSON.parse(decrypt(response1.data));
                                                    switch (decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                        case 'no permission':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            addToast('No workspace found', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support');
                                                            break;
                                                        default:
                                                            let nameArray = [];
                                                            for (var i = 0; i < decryptedData1.length; i++) {
                                                                if (!nameArray.some(item => item.name === decryptedData1[i].name)) {
                                                                    nameArray.push({
                                                                        name: decryptedData1[i].name,
                                                                        owner: decryptedData1[i].owner
                                                                    });
                                                                } else {
                                                                    // Do nothing
                                                                }
                                                            }
                                                            setWorkspaceName(nameArray);
                                                            setSelectedWorkspace(nameArray[0]);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                } 
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    useEffect(() => {
        if (selectedWorkspace) {
            setCreateStaffTask(values => ({
                ...values,
                workspaceName: selectedWorkspace.name,
                workspaceOwner: selectedWorkspace.owner,
            }));
            getPageDetails();
        }
    }, [selectedWorkspace]);
    const getPageDetails = () => {
        // Get csrf token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get workspace staff
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceStaffDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'query not found':
                            case 'all fields required':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'no result found':
                                setWorkspaceStaffDetails([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceStaffDetails(decryptedData);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
        // Get csrf token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get workspace staff
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'query not found':
                            case 'all fields required':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'no result found':
                                setWorkspaceStaff([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                let approved = [];
                                for (let i = 0; i < decryptedData.length; i++) {
                                    if (decryptedData[i].status === 'approved') {
                                        approved.push(decryptedData[i]);
                                    }
                                }
                                setWorkspaceStaff(decryptedData);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
        // Get csrf token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get all task
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getWorkspaceTask', encryptedPayload, {withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'query not found':
                            case 'all fields required':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'no result found':
                                setTasks([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setTasks(decryptedData);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get workspace tier
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceTier', encryptedPayload, {withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setWorkspaceTier('');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceTier(decryptedData);
                                setTaskData(prevTaskData => ({
                                    ...prevTaskData,
                                    workspaceTier: decryptedData,
                                }));
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get workspace skills
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceSkills', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setSkillOptions([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                if (!Array.isArray(decryptedData)) {
                                    const skillArray = decryptedData.split(',').map(skill => skill.trim());
                                    setSkillOptions(skillArray);
                                }
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { workspaceName: selectedWorkspace.name, workspaceOwner: selectedWorkspace.owner, token: decryptedToken};
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get workspace timeslot
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setTimeOptions([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                if (!Array.isArray(decryptedData)) {
                                    const timeArray = decryptedData.split(',').map(skill => skill.trim());
                                    setTimeOptions(timeArray);
                                }
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });                    
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };
    const generateMonth = (year, month) => {
        const daysCount = daysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const days = [];
        for (let i = 1; i <= daysCount; i++) {
            days.push(i);
        }
        const blanks = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            blanks.push('');
        }
        const totalSlots = [...blanks, ...days];
        const rows = [];
        let cells = [];
        totalSlots.forEach((day, i) => {
            if (i % 7 !== 0) {
                cells.push(day);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(day);
            }
            if (i === totalSlots.length - 1) {
                rows.push(cells);
            }
        });
        return (
            <div className={styles.MonthWrapper} key={month}>
                <h2>{`${new Date(year, month).toLocaleString('default', { month: 'long' })} ${year}`}</h2>
                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                    <thead>
                        <tr>
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                        </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            {row.map((cell, index) => {
                                const isCellWithNumber = /^\d+$/.test(cell.toString());
                                const taskForCell = isCellWithNumber ? tasks.filter(task => {
                                    const taskStartYear = parseInt(task.startyear);
                                    const taskStartMonth = parseInt(task.startmonth);
                                    const taskStartDay = parseInt(task.startday);
                                    const taskEndYear = parseInt(task.endyear);
                                    const taskEndMonth = parseInt(task.endmonth);
                                    const taskEndDay = parseInt(task.endday);
                                    return (
                                        (taskStartYear < year || (taskStartYear === year && taskStartMonth <= month + 1)) && // Task starts before or during the current month
                                        (taskEndYear > year || (taskEndYear === year && taskEndMonth >= month + 1)) &&       // Task ends during or after the current month
                                        ((taskStartYear < year || taskStartMonth < month + 1) || taskStartDay <= cell) &&     // Task starts before or during the current month or on the current day
                                        ((taskEndYear > year || taskEndMonth > month + 1) || taskEndDay >= cell)              // Task ends during or after the current month or on the current day
                                    );
                                }) : [];
                                return (
                                    <td key={index} className={styles.TableData}>
                                        <p className={darkTheme ? styles.DateDark : styles.Date} onClick={() => handleDateClick(cell, month, year)}>
                                            {cell}
                                        </p>
                                        {taskForCell.map(task => {
                                            if (task.status === 'blockout') {
                                                return (
                                                    <div key={task.id} className={styles.BlockOutContainer}>
                                                        <span>Blockout: {task.name}</span><br />    
                                                        <span>Hours: {task.numhours}</span>
                                                    </div>
                                                )
                                            } else if (task.status === 'InProgress') {
                                                return (
                                                    <div key={task.id} className={styles.TaskContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Num Staff Neeeded: {task.numstaff}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            } else if (task.status === 'Done') {
                                                return (
                                                    <div key={task.id} className={styles.DoneContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            } else if (task.status === 'staffAvailable') {
                                                return (
                                                    <div key={task.id} className={styles.StaffAvailableContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };
    const generateYear = () => {
        const year = currentDate.getFullYear();
        const months = [];
        for (let i = 0; i < 12; i++) {
            months.push(generateMonth(year, i));
        }
        return months;
    };
    const handleDateClick = (day, month, year) => {
        // Set the selected date
        setSelectedDate({ day, month, year });
        setTaskData(prevTaskData => ({
            ...prevTaskData,
            workspaceName: selectedWorkspace.name,
            workspaceOwner: selectedWorkspace.owner,
            startDay: day,
            startMonth: month,
            startYear: year
        }));
    };
    const handleCloseModal = () => {
        // Clear the selected date
        setSelectedDate(null);
    };
    const handleBlockOutDate = () => {
        const newValue = !blockOut;
        setBlockOut(newValue);
        setTaskData(prevTaskData => ({
            ...prevTaskData,
            blockout: newValue ? 1 : 0,
        }));
    };
    const handleInput = (event) => {
        const { name, value } = event.target;
        setTaskData (values => ({...values, [name]:value}));
    };
    const Uncheck = () => {
        setViewAddingStaff(false);
        setAutoStaffClicked(false);
        setAutoManagerClicked(false);
        setAutoStaff(false);
        setAutoStaffManager(false);
        setAutoStaffSkillset(false);
        setAutoStaffTimeslot(false);
        setSelectedTime([]);
        setTimeOptions([]);
        setTaskData(prevTaskData => ({
            ...prevTaskData,
            automation: 0,
            automationmanager: 0,
            autoworkspaceskill: 0,
            autotimeslot: 0,
        }));
        setFullDay(false);
        setFullIsClicked(false);
    };
    const handleFullDay = () => {
        const newValue = !fullDay;
        setFullDay(newValue);
        setFullIsClicked(newValue);
        setTaskData(prevTaskData => ({
            ...prevTaskData,
            startingTime: newValue ? '0000' : null,
            numHours: newValue ? 24 : null,
        }));
    };
    const submitBlockOutForm = (event) => {
        event.preventDefault();
        // Get csrf token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedData = JSON.parse(decrypt(response.data));
            switch (decryptedData) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const updatedInput = { ...taskData, token: decryptedData };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=createBlockOut', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch(decryptedData) {
                            case true:
                                addToast("Block Out successfully created!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error creating task, please try again later", 'error');
                                break;
                            case 'all fields required':
                            case 'query not found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no token found':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch (error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handleWorkspaceChange = (event) => {
        setSelectedWorkspace(JSON.parse(event.target.value));
        setTaskData(prevTaskData => ({
            ...prevTaskData,
            workspaceName: event.target.value.name,
            workspaceOwner: event.target.value.owner,
        }));
    };
    const handleSkillChange = (selectedOption) => {
        setSelectedSkill(selectedOption);
        const skillValue = selectedOption ? selectedOption.map(option => option.value) : []; // Map selected options to their values
        setTaskData(prevTask => ({
            ...prevTask,
            skill: skillValue,
        }));
    };
    const handleTimeChange = (selectedOption) => {
        setSelectedTime(selectedOption);
        const timeValue = selectedOption ? selectedOption.map(option => option.value) : []; // Map selected options to their values
        setTaskData(value => ({
            ...value,
            timeslot: timeValue,
        }));
    };
    const handleAutomationCheckbox = () => {
        if (autoStaff === false) {
            setAutoStaff(true);
            setViewAddingStaff(true);
            setAutoStaffClicked(true);
            workspaceStaff.sort((a, b) => a.hours - b.hours);
            viewStaffInfo();
            setTaskData(prevTaskData => ({
                ...prevTaskData,
                automation: 1,
            }));
        }
    };
    const handleAutomationManagerCheckbox = () => {
        if (autoStaffManager === true) {
            setAutoStaffManager(false);
            setAutoManagerClicked(false);
            setTaskData(prevTaskData => ({
                ...prevTaskData,
                automationmanager: 0,
            }));
        } else if (autoStaffManager === false) {
            setAutoStaffManager(true);
            setAutoManagerClicked(true);
            setTaskData(prevTaskData => ({
                ...prevTaskData,
                automationmanager: 1,
            }));
        }
    };
    const handleAutomationSkillset = () => {
        if (autoStaffSkillset === true) {
            setAutoStaffSkillset(false);
            workspaceStaff.sort((a, b) => a.hours - b.hours);
            setTaskData(prevTask => ({
                ...prevTask,
                autoworkspaceskill: 0,
            }));
        } else if (autoStaffSkillset === false) {
            setAutoStaffSkillset(true);
            setTaskData(prevTask => ({
                ...prevTask,
                autoworkspaceskill: 1,
            }));
        }
    };
    const handleAutomationTimeslot = () => {
        if (autoStaffTimeslot === true) {
            setAutoStaffTimeslot(false);
            workspaceStaff.sort((a, b) => a.hours - b.hours);
            setTaskData(prevTask => ({
                ...prevTask,
                autotimeslot: 0,
            }));
        } else if (autoStaffTimeslot === false) {
            setAutoStaffTimeslot(true);
            setTaskData(prevTask => ({
                ...prevTask,
                autotimeslot: 1,
            }));
        }
    };
    const viewStaffInfo = (year, month, day) => {
        return (
            <>
                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                    <thead>
                        <tr>
                            <th>Name: </th>
                            <th>Role: </th>
                            <th>Hours: </th>
                            <th>Skills: </th>
                            <th>Timeslot: </th>
                            <th>Action: </th>
                        </tr>
                    </thead>
                    <tbody>
                        {workspaceStaff.filter(ws => {
                            if (autoStaffManager === false) {
                                return !workspaceStaffDetails.find(wsd => wsd.username === ws.username && wsd.role === 'manager');
                            } else {
                                return workspaceStaff;
                            }
                        }).map((ws, index) => (
                            <tr key={index}>
                                <td>{ws.username}</td>
                                <td>{workspaceStaffDetails.find(wsd => wsd.username === ws.username).role}</td>
                                <td>{ws.hours}</td>
                                <td>{ws.skillset}</td>
                                <td>{ws.timeslot}</td>
                                <td>
                                    {tasks.some(task => task.staff === ws.username && task.status === 'staffAvailable' && parseInt(task.startday) == parseInt(day) && parseInt(task.startmonth) == parseInt(month) + 1 && parseInt(task.startyear) == parseInt(year)) ? (
                                        <button onClick={() => OpenCreateTaskWithStaff(ws.username, year, month, day)} className={darkTheme ? styles.TableDarkButton : styles.TableLightButton}>Create Task With {ws.username}</button>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    };
    const OpenCreateTaskWithStaff = (username, year, month, day) => {
        setCreateStaffTaskPopup(true);
        setCreateStaffTask(values => ({
            ...values, 
            staff: username,
            startYear: year,
            startMonth: month,
            startDay: day,
        }));
    };
    const CloseCreateTaskWithStaff = () => {
        setCreateStaffTaskPopup(false);
        setCreateStaffTask(values => ({
            ...values, 
            staff: '',
            startYear: '',
            startMonth: '',
            startDay: '',
        }));
    };
    const handleCreateStaffTaskInput = (event) => {
        const { name, value } = event.target;
        setCreateStaffTask(values => ({...values, [name]: value}));
    };
    const CreateTaskWithStaff = () => {
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...createStaffTask, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=createStaffTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast("Task successfully created!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error has occured, please try again later", 'error');
                                break;
                            case 'task name regex':
                                addToast("Name should not have any special characters", 'error');
                                break;
                            case 'startingtime regex':
                                addToast('Please enter a correct 24 hour time', 'error');
                                break;
                            case 'starting time invalid':
                                addToast('Invalid time', 'error');
                                break;
                            case 'invalid num hours':
                                addToast('Please enter a valid number of working hours', 'error');
                                break;
                            case 'all fields required':
                                addToast("Please input all fields", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const submitCreateTaskForm = (event) => {
        event.preventDefault();
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...taskData, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=createTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch(decryptedData) {
                            case true:
                                addToast("Task successfully created!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error has occured, please try again later", 'error');
                                break;
                            case 'task with same name exist':
                                addToast("Task with same name exist, please use another name", 'error');
                                break;
                            case 'task name regex':
                                addToast("Name should not have any special characters", 'error');
                                break;
                            case 'numdays regex':
                                addToast('You require staff that is more than your limit to do this task?', 'error');
                                break;
                            case 'startingtime regex':
                                addToast('Please enter a correct 24 hour time', 'error');
                                break;
                            case 'starting time invalid':
                                addToast('Invalid time', 'error');
                                break;
                            case 'invalid num hours':
                                addToast('Please enter a valid number of working hours', 'error');
                                break;
                            case 'all fields required':
                                addToast("Please input all fields", 'error');
                                break;
                            case 'no skill found':
                            case 'skill not array':
                                addToast('Error occured, please contact customer service', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch (error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} workspaceTier={workspaceTier} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={styles.ManagerWorkspaceDropdown}>
                        <select className={styles.WorkspaceDropdownSelect} onChange={handleWorkspaceChange}>
                            {workspaceName.map((name, index) => (
                                <option key={index} value={JSON.stringify({ name: name.name, owner: name.owner })}>
                                    {name.name}, {name.owner}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.ScheduleWrapper}>
                        {generateYear()}
                        {selectedDate && (
                            <div className={styles.PopupContainer}>
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    <ToastContainer toasts={toast} removeToast={removeToast} />
                                    <span className={styles.ClosePopup} onClick={handleCloseModal}>&times;</span>
                                    <div className={styles.ButtonContainer}>
                                        <button onClick={handleBlockOutDate} className={darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight}>Block Out</button>
                                    </div>
                                    {blockOut === true ? (
                                        <>
                                            <form onSubmit={submitBlockOutForm}>
                                                <h1>Selected date: {selectedDate.year}/{selectedDate.month + 1}/{selectedDate.day}</h1>
                                                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <label>Reason for block out: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="taskName" placeholder='Input reason here' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>No. of Days: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numDays" placeholder='Input number of days here' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Starting Hour: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="startingTime" placeholder='Starting hour in 24 hour format: 0900' onChange={handleInput} readOnly={fullDay} required={!fullDay}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of hours: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numHours" placeholder='Number of hours here' onChange={handleInput} readOnly={fullDay} required={!fullDay}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" align="right">
                                                                <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>SUBMIT</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={handleFullDay} className={`${darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight} ${fullIsClicked ? styles.Clicked : ''}`}>Full Day</button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <form onSubmit={submitCreateTaskForm}>
                                                <h1>Selected date: {selectedDate.year}/{selectedDate.month + 1}/{selectedDate.day}</h1>
                                                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <label>Name of task: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="taskName" placeholder='Input task name here' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of staff needed: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numStaff" placeholder='Input number of staff for this task' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Days of task: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numDays" placeholder='Input number of days for this task' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Starting Hour: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="startingTime" placeholder='Starting hour in 24 hour format: 0900' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of hours: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numHours" placeholder='Number of hours for this task' onChange={handleInput} required></input>
                                                            </td>
                                                        </tr>
                                                        {autoStaffSkillset && (
                                                            <tr>
                                                                <td>
                                                                    <label>Skills Required: </label>
                                                                </td>
                                                                <td>
                                                                    <Select className={styles.CustomDropdownMenu} value={selectedSkill} onChange={handleSkillChange} options={skillOptions.map(skill => ({ label: skill, value: skill }))} isClearable isMulti placeholder="Search for skills" required />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {autoStaffTimeslot && (
                                                            <tr>
                                                                <td>
                                                                    <label>Timeslot: </label>
                                                                </td>
                                                                <td>
                                                                    <Select className={styles.CustomDropdownMenu} value={selectedTime} onChange={handleTimeChange} options={timeOptions.map(skill => ({ label: skill, value: skill }))} isClearable isMulti placeholder="Search for timeslot" required />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td colSpan="2" align="right">
                                                                <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>SUBMIT</button>
                                                                <input type="hidden" name="startDay" value={selectedDate.day} />
                                                                <input type="hidden" name="startMonth" value={selectedDate.month} />
                                                                <input type="hidden" name="startYear" value={selectedDate.year} />
                                                                <input type="hidden" name="workspaceName" value={selectedWorkspace.name} />
                                                                <input type="hidden" name="workspaceOwner" value={username} />
                                                                <input type="hidden" name="workspaceTier" value={workspaceTier} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                            {userTier === "premium" ? (
                                                <>
                                                    <div className={styles.ButtonContainer}>
                                                        {autoStaffClicked === true ? (
                                                            <button onClick={Uncheck} className={`${darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight} ${styles.Clicked}`}>Auto Add Staff</button>
                                                        ) : (
                                                            <button onClick={handleAutomationCheckbox} className={darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight}>Auto Add Staff</button>
                                                        )}
                                                        {autoStaff && (
                                                            <>
                                                                <button onClick={handleAutomationManagerCheckbox} className={` ${darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight} ${autoManagerClicked ? styles.Clicked : ''}`}>Include Manager</button>
                                                                <button onClick={handleAutomationSkillset} className={darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight}>Auto Skillset</button>
                                                                <button onClick={handleAutomationTimeslot} className={darkTheme ? styles.AutoButtonDark : styles.AutoButtonLight}>Auto Timeslot</button>
                                                            </>
                                                        )}
                                                    </div>
                                                    {viewAddingStaff && (
                                                        viewStaffInfo(selectedDate.year, selectedDate.month, selectedDate.day)
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {createStaffTaskPopup && (
                            <div className={styles.PopupContainerWithFlex}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    <button onClick={CloseCreateTaskWithStaff} className={styles.ClosePopup}>&#10006;</button>
                                    <div className={styles.InputContainer}>
                                        <input type='text' name='taskName' placeholder='Enter Task Name: ' onChange={handleCreateStaffTaskInput} />
                                        <input type='text' name='startingTime' placeholder='Enter Starting Hours in 24 Hours Format: ' onChange={handleCreateStaffTaskInput} />
                                        <input type='text' name='numHours' placeholder='Enter number of hours for this task: ' onChange={handleCreateStaffTaskInput} />
                                    </div>
                                    <div className={styles.ButtonContainer}>
                                        <button onClick={CreateTaskWithStaff} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>CREATE</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default ManagerViewSchedule;