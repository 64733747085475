import axios from 'axios';
import CryptoJS from 'crypto-js';

const handleLogout = (navigate) => {
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    localStorage.removeItem('userRole');
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("workspace");
    localStorage.removeItem("workspaceTier");
    localStorage.removeItem("tempId");
    localStorage.removeItem('tempName');
    localStorage.removeItem('tempOwner');
    localStorage.removeItem('tempTier');
    localStorage.removeItem('workspaceName');
    localStorage.removeItem('workspaceOwner');
    localStorage.removeItem('tempUsername');
    localStorage.removeItem('selectedWorkspace');
    localStorage.removeItem('tempWorkspaceName');
    localStorage.removeItem('tempWorkspaceOwner');
    localStorage.removeItem('staffTempName');
    localStorage.removeItem('staffTempOwner');
    localStorage.removeItem('staffTempTier');
    localStorage.removeItem('staffViewTaskName');
    localStorage.removeItem('staffViewTaskOwner');
    localStorage.removeItem('staffViewTaskTier');
    localStorage.removeItem('staffViewBidName');
    localStorage.removeItem('staffViewBidOwner');
    localStorage.removeItem('staffViewBidTier');
    localStorage.removeItem('tempBidName');
    localStorage.removeItem('tempBidOwner');
    localStorage.removeItem('tempBidTier');
    localStorage.removeItem('tempSetWorkspaceName');
    localStorage.removeItem('tempSetWorkspaceOwner');
    localStorage.removeItem('setSelectedWorkspace');
    localStorage.removeItem('SAEditUserUsername');
    localStorage.removeItem('SAViewWorkspaceTempName');
    localStorage.removeItem('SAViewWorkspaceTempOwner');
    localStorage.removeItem('SAViewWorkspaceTempTier');
    localStorage.removeItem('SAChangePasswordTempUsername');
    localStorage.removeItem('SAViewStaffScheduleUsername');
    localStorage.removeItem('SAViewTempWorkspaceName');
    localStorage.removeItem('SAViewTempWorkspaceOwner');
    localStorage.removeItem('SAViewTempWorkspaceTier');
    localStorage.removeItem('managerTempBidWorkspaceName');
    localStorage.removeItem('managerTempBidWorkspaceOwner');
    localStorage.removeItem('managerTempBidWorkspaceTier');
    localStorage.removeItem('managerViewWorkspaceTempName');
    localStorage.removeItem('managerViewWorkspaceTempOwner');
    localStorage.removeItem('managerViewWorkspaceTempTier');
    // Delete session id
    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=deleteCookie', {}, { withCredentials: true }).then(function(response) {
        const decryptedText = JSON.parse(decrypt(response.data));
        switch (decryptedText) {
            case true:
                navigate('/');
                break;
            case false:
            case 'no query found':
            case 'all fields required':
            case 'no result found':
            default:
                navigate('/homepage')
                break;
        }
    }).catch(error => {
        console.error('Request failed: ', error);
    });
};

export { handleLogout };