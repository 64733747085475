import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleLogout } from '../../logout';
import styles from './401.module.css';

function FourOOne() {
    const navigate = useNavigate();
    const backToLoginPage = () => {
        handleLogout(navigate);
        navigate('/');
    }
    return (
        <div className={styles.UnauthorisedBody}>
            <div className={styles.UnauthorisedWrapper}>
                <h1>ARE YOU SURE YOU ARE SUPPOSED TO ACCESS THIS PAGE?</h1>
                <button onClick={backToLoginPage}>GO BACK TO LOGIN</button>
            </div>
        </div>
    );
};

export default FourOOne;