import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

function HomePage() {
    const navigate = useNavigate();
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    useEffect(() => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details with cookie
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedDetails = JSON.parse(decrypt(response3.data));
                        switch (decryptedDetails) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                // Get CSRF token
                                axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
                                    const decryptedToken = JSON.parse(decrypt(response1.data));
                                    switch (decryptedToken) {
                                        case 'no cookie found':
                                        case 'no query found':
                                            break;
                                        default:
                                            const input = { username: decryptedDetails.username, token: decryptedToken };
                                            const encryptedPayload = encrypt(JSON.stringify(input));
                                            axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspace', encryptedPayload, { withCredentials: true }).then(function(response2) {
                                                const decryptedData = JSON.parse(decrypt(response2.data));
                                                switch (decryptedData) {
                                                    case 'no query found':
                                                    case 'all fields required':
                                                    case 'no result found':
                                                        break;
                                                    default:
                                                        if (decryptedData === 'no workspace found') {
                                                            if (decryptedDetails.role === 'manager') {
                                                                navigate('/managernoworkspace');
                                                            } else if (decryptedDetails.role === 'staff') {
                                                                navigate('/staffpendingworkspace');
                                                            } else if (decryptedDetails.role === 'system admin') {
                                                                navigate('/systemadminviewschedule');
                                                            }
                                                        } else {
                                                            let activeWorkspace = false;
                                                            for (let i = 0; i < decryptedData.length; i++) {
                                                                if (decryptedData[i].status === 'approved') {
                                                                    activeWorkspace = true;
                                                                }
                                                            }
                                                            if (activeWorkspace && decryptedDetails.role === 'staff') {
                                                                navigate('/staffviewschedule');
                                                            } else if (!activeWorkspace && decryptedDetails.role === 'staff') {
                                                                navigate('/staffpendingworkspace');
                                                            } else if (activeWorkspace && decryptedDetails.role === 'manager') {
                                                                navigate('/managerviewschedule');
                                                            } else if (!activeWorkspace && decryptedDetails.role === 'manager') {
                                                                navigate('/managernoworkspace');
                                                            }
                                                        }
                                                        break;
                                                }
                                            }).catch(error => {
                                                console.error('Request failed: ', error);
                                            });
                                    }
                                }).catch(error => {
                                    console.error('Request failed: ', error);
                                });
                        }
                    }).catch(error => {
                        console.error('Request faield: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
};

export default HomePage;