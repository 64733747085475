import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import PasswordStrength from "../../PasswordStrength";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import CountryCode from "../../CountryCode";
import styles from './ViewWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerViewWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('managerViewWorkspaceTempName');
    const workspaceOwner = localStorage.getItem('managerViewWorkspaceTempOwner');
    const workspaceTier = localStorage.getItem('managerViewWorkspaceTempTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceDetails, setWorkspaceDetails ] = useState([]); 
    const [ workspaceStaff, setWorkspaceStaff ] = useState([]);
    const [ deleteWorkspacePopup, setDeleteWorkspacePopup ] = useState(false);
    const [ tempUsername, setTempUsername ] = useState('');
    const [ cancelPendingInvitePopup, setCancelPendingInvitePopup ] = useState(false);
    const [ removeUserConfirmPopup, setRemoveUserConfirmPopup ] = useState(false);
    const [ addSkillsForStaffPopup, setAddSkillsForStaffPopup ] = useState(false);
    const [ addTimeslotForStaffPopup, setAddTimeslotForStaffPopup ] = useState(false);
    const addSkillArray = [];
    const deleteSkillArray = [];
    const deleteTimeslotArray = [];
    const addTimeslotArray = [];
    const [ rejectStaffRequestPopup ,setRejectStaffRequestPopup ] = useState(false);
    const [ rejectStaffRequestUsername, setRejectStaffRequestUsername ] = useState('');
    const [ createUserPopup , setCreateUserPopup ] = useState(false);
    const [ inputs, setInputs ] = useState({
        username: "",
        password: "",
        confirmpassword: "",
        firstname: "",
        familyname: "",
        email: "",
        code: "",
        number: "",
        role: "staff",
        ownerUsername: workspaceOwner,
        workspaceName: workspaceName,
    });
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordStrength, setPasswordStrength ] = useState(false);
    const [ checks, setChecks ] = useState({
        capsLetterCheck : false,
        numberCheck: false,
        passwordLengthCheck: false,
        specialCharCheck: false,
    });
    const [ selectedCountryCode, setSelectedCountryCode ] = useState("");
    const [ numOfStaff, setNumOfStaff ] = useState(0);
    const [ inviteStaffPopup, setInviteStaffPopup ] = useState(false);
    const [ invites, setInvites ] = useState({
        staffEmail: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
        workspaceTier: workspaceTier,
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response) {
                        const decryptedData = JSON.parse(decrypt(response.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'all fields required':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'manager') {
                                    getWorkspaceStaff();
                                    getWorkspaceDetails();
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const getWorkspaceStaff = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceStaffDetails', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no result found':
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceStaff(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getWorkspaceDetails = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no result found':
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceDetails(decryptedData1);
                                // Calculate number of approved or pending staff
                                let staff = decryptedData1.filter(item =>
                                    item.status === "approved" ||
                                    item.status === "staffPending" ||
                                    item.status === "managerPending"
                                );
                                setNumOfStaff(staff.length);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ShowInviteStaffPopup = () => {
        setInviteStaffPopup(true);
    };
    const CloseInviteStaffPopup = () => {
        setInviteStaffPopup(false);
    };
    const handleInviteChange = (event) => {
        const { name, value } = event.target;
        setInvites(values => ({...values, [name]:value}));
    };
    const SubmitInviteForm = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...invites, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=sendInvite', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch(decryptedText) {
                            case true:
                                addToast("Invitation have been sent successfully, goodluck recruiting new staff!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Invitation fail to send, please contact customer support", 'error');
                                break;
                            case 'email regex':
                                addToast('Please enter a valid email', 'error');
                                break;
                            case 'email does not exist':
                                addToast("Email does not exist at our end, please double check", 'error');
                                break;
                            case 'free version one manager':
                                addToast("Free version cannot have more than 1 manager in the workspace", 'error');
                                break;
                            case 'user not premium':
                                addToast('User is using free version and have an pending or active workspace', 'error');
                                break;
                            case 'same':
                                addToast('User is already in your workspace or you have invited them already', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ManagerDeleteWorkspacePopup = () => {
        setDeleteWorkspacePopup(true);
    };
    const CloseManagerDeleteWorkspacePopup = () => {
        setDeleteWorkspacePopup(false);
    }
    const ManagerDeleteWorkspace = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ownerUsername: workspaceOwner, workspaceName: workspaceName, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Starting a new workspace?', 'success');
                                setTimeout(() => {
                                    navigate('/managerviewallworkspace');
                                }, 1500);
                                break;
                            case false:
                                addToast('Something else not done yet?', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenCreateUserPopup = () => {
        setCreateUserPopup(true);
    };
    const CloseCreateUserPopup = () => {
        setCreateUserPopup(false);
        setInputs(values => ({
            ...values,
            username: '',
            password: '',
            confirmpassword: '',
            firstname: '',
            familyname: '',
            email: '',
            code: '',
            number: '',
        }));
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs (values => ({...values, [name]:value}));
    };
    const handleFocus = () => {
        setPasswordStrength(true);
    };
    const handleBlur = () => {
        setPasswordStrength(false);
    };
    const handleKeyUp = (event) => {
        const { value } = event.target;
        const capsLetterCheck = /[A-Z]/.test(value);
        const numberCheck = /[0-9]/.test(value);
        const passwordLengthCheck = value.length > 7 && value.length < 21;
        const specialCharCheck = /[.,/:;'"{}\[\]!@#$%^&*()=_\-+]/.test(value);
        setChecks({
            capsLetterCheck,
            numberCheck,
            passwordLengthCheck,
            specialCharCheck,
        });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
        handleChange(event); // Propagate the change event to update the inputs state
    };
    const CreateUser = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...inputs, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=managerCreateUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`User created successfully and added into ${workspaceName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error registering", 'error');
                                break;
                            case 'all fields required':
                                addToast('All fields are required', 'error');
                                break;
                            case 'no query found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'password no match':
                                addToast("Passwords are not the same", 'error');
                                break;
                            case 'name regex':
                                addToast("Name should only include alphabets and spacing", 'error');
                                break;
                            case 'email regex':
                                addToast("Please enter a valid email", 'error');
                                break;
                            case 'number regex': 
                                addToast("Please enter a valid number", 'error');
                                break;
                            case 'username exist':
                                addToast("Username have been taken, please choose another one", 'error');
                                break
                            case 'email exist':
                                addToast("Email have been taken, please choose another one", 'error');
                                break
                            case 'number exist':
                                addToast("Number exist, please use another number", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                        setSelectedCountryCode(""); // Reset selected country code
                    }).catch(function(error) {
                        console.error('Error occurred:', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const approveUser = (username) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=approveUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch(decryptedText) {
                            case true:
                                addToast("Welcome your latest member to your workspace!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error accepting user, please contact customer support", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error("Request failed: ", error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenRejectStaffRequest = (username) => {
        setRejectStaffRequestPopup(true);
        setRejectStaffRequestUsername(username);  
    };
    const CloseRejectStaffRequest = () => {
        setRejectStaffRequestPopup(false);
        setRejectStaffRequestUsername('');
    };
    const rejectUser = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: rejectStaffRequestUsername, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=rejectUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch(decryptedText) {
                            case true:
                                addToast("Painful rejection, unless you do not know him!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error rejecting, seems like his determination is strong?", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenDeletePendingInvitation = (username) => {
        setTempUsername(username);
        setCancelPendingInvitePopup(true);
    };
    const CloseDeletePendingInvitation = () => {
        setTempUsername('');
        setCancelPendingInvitePopup(false);
    };
    const deletePendingInvite = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ownerUsername: workspaceOwner, workspaceName: workspaceName, staffUsername: tempUsername, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deletePendingInvite', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Invitation to ${tempUsername} have been taken back`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('What have been said cannot be taken back...', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast('Error, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const viewUserSchedule = (username) => {
        localStorage.setItem('managerviewstaffscheduleusername', username);
        navigate('/managerviewstaffschedule');
    };
    const showConfirmRemoveUserPopup = (username) => {
        setTempUsername(username);
        setRemoveUserConfirmPopup(true);
    };
    const closeConfirmRemoveUserPopup = () => {
        setTempUsername('');
        setRemoveUserConfirmPopup(false);
    };
    const deleteUserFromWorkspace = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: tempUsername, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=suspendUserFromWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch(decryptedText) {
                            case true:
                                addToast("Sad to see someone leaving workspace, but the next one will be better!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error deleting from workspace, determination is strong", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const showAddSkillPopup = (username) => {
        setTempUsername(username);
        setAddSkillsForStaffPopup(true);
    };
    const closeAddSkillPopup = () => {
        setTempUsername('')
        setAddSkillsForStaffPopup(false);
    };
    const addSkillsForStaff = (skillname) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { skill: skillname, workspaceName: workspaceName, workspaceOwner: workspaceOwner, staffUsername: tempUsername, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=updateUserSkills', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully added skill to ${tempUsername}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast('Something went wrong, please contact customer support', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const removeSkillsForStaff = (skillname) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, staffUsername: tempUsername, skill: skillname, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Success deleting skill', 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast('Error deleting, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                            case 'no result found':
                            case 'no query found':
                                addToast('Error occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const showAddTimeslotPopup = (username) => {
        setAddTimeslotForStaffPopup(true);
        setTempUsername(username);
    };
    const CloseAddTimeslotPopup = () => {
        setAddTimeslotForStaffPopup(false);
        setTempUsername('');
    };
    const DeleteTimeslot = (username, name, owner, timeslot) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully remove ${timeslot}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error removing ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AddTimeslot = (name, owner, timeslot) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: tempUsername, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=addUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully added ${timeslot}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error adding ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const removeUserFromWorkspace = (username) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeFromDB', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch(decryptedText) {
                            case true:
                                addToast("Acknowledged a loss to opening a slot of someone better", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error has occured, please try again later", 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('managerViewWorkspaceTempName');
        localStorage.removeItem('managerViewWorkspaceTempOwner');
        localStorage.removeItem('managerViewWorkspaceTempTier');
        navigate('/managerviewallworkspace');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('username');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, Search for user here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="username">Username</option>
                                <option value="firstname">First Name</option>
                                <option value="familyname">Family Name</option>
                                <option value="email">Email</option>
                                <option value="role">Role</option>
                                <option value="skillset">Skill</option>
                                <option value="timeslot">Timeslot</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                        <div className={styles.NumberOfStaffContainer}>
                            {workspaceTier === 'free' ? 
                                <h2>Current user count: {numOfStaff} / 10</h2> :
                                <h2>Current user count: {numOfStaff} / 10,000</h2>
                            }
                        </div>
                        {workspaceTier === 'free' && numOfStaff >= 10 ? (
                            <div className={styles.MaxOutHeader}>
                                <h2>You have maxed out your free version of 10 user in workspace</h2>
                            </div>
                        ) : (
                            <div className={styles.ButtonContainer}>
                                <button onClick={ShowInviteStaffPopup} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Send Invitation</button>
                                {numOfStaff === 1 && username === workspaceOwner && (
                                    <button onClick={ManagerDeleteWorkspacePopup} className={darkTheme ? styles.ButtonDark : styles.ButtonLight} style={{ marginLeft: '20px' }}>Delete Workspace</button>
                                )}
                            </div>
                        )}
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Username: </th>
                                    <th>First Name: </th>
                                    <th>Family Name: </th>
                                    <th>Email: </th>
                                    <th>Code: </th>
                                    <th>Number: </th>
                                    <th>Role: </th>
                                    <th>Hours: </th>
                                    <th>Skills: </th>
                                    <th>Timeslot: </th>
                                    <th>Status: </th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {workspaceStaff.filter((user) => {
                                    if (searchField === 'skillset') {
                                        return workspaceDetails.some(ws => ws.username === user.username && ws.skillset.toLowerCase().includes(search.toLowerCase()));
                                    } else if (searchField === 'timeslot') {
                                        return workspaceDetails.some(ws => ws.username === user.username && ws.timeslot.toLowerCase().includes(search.toLowerCase()));
                                    } else {
                                        return search.toLowerCase() === '' ? user : user[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).filter((user) => {
                                    const rejected = workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && (
                                        workspaceUser.status === 'managerRejected' || workspaceUser.status === 'managerDeleted'
                                    ));
                                    return !rejected;
                                }).sort((a, b) => {
                                    const aIsManager = workspaceStaff.find(user => user.username === a.username && user.role === 'manager');
                                    const bIsManager = workspaceStaff.find(user => user.username === b.username && user.role === 'manager');
                                    const aApproved = workspaceDetails.find(user => user.username === a.username && user.status === 'approved');
                                    const bApproved = workspaceDetails.find(user => user.username === b.username && user.status === 'approved');
                                    const aPending = workspaceDetails.find(user => user.username === a.username && user.status === 'staffPending');
                                    const bPending = workspaceDetails.find(user => user.username === b.username && user.status === 'staffPending');
                                    if (aIsManager && !bIsManager) {
                                        return -1; // a comes before b
                                    } else if (!aIsManager && bIsManager) {
                                        return 1; // b comes before a
                                    } else {
                                        if (aApproved && !bApproved) {
                                            return -1; // a comes before b
                                        } else if (!aApproved && bApproved) {
                                            return 1; // b comes before a
                                        } else {
                                            if(aPending && !bPending) {
                                                return -1 // a comes before b
                                            } else if (!aPending && bPending) {
                                                return 1 // b comes before a
                                            } else {
                                                return 0;
                                            }
                                        }
                                    }
                                }).map((user, index) => (
                                    <tr key={index}>
                                        <td><img src={`./pp/${user.picture}`} /></td>
                                        {username === user.username ? (<td>{user.username}(me)</td>) : (<td>{user.username}</td>)}
                                        <td>{user.firstname}</td>
                                        <td>{user.familyname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.code}</td>
                                        <td>{user.number}</td>
                                        <td>{user.role}</td>
                                        <td>{workspaceDetails.find(w => w.username === user.username)?.hours || 'None'}</td>
                                        <td>{workspaceDetails.find(w => w.username === user.username)?.skillset || 'None'}</td>
                                        <td>{workspaceDetails.find(w => w.username === user.username)?.timeslot || 'None'}</td>
                                        <td>
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'suspended') && (
                                                <>Account suspended</>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffRejected') && (
                                                <>Rejected by staff</>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffPending') && (
                                                <>Staff Pending</>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'approved') && (
                                                <>Active</>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'managerPending') && (
                                                <>Pending</>
                                            )}
                                        </td>
                                        <td>
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'managerPending') && (
                                                <>
                                                    <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => approveUser(user.username)}>Approve	&#10004;</button>
                                                    <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => OpenRejectStaffRequest(user.username)}>Reject &#10006;</button>
                                                </>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffPending') && (
                                                <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => OpenDeletePendingInvitation(user.username)}>Cancel Invitation &#10006;</button>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'approved' && workspaceUser.username !== username) && (
                                                <>
                                                    <button onClick={() => viewUserSchedule(user.username)}>View Schedule</button>
                                                    <button onClick={() => showConfirmRemoveUserPopup(user.username)} style={{ backgroundColor: 'red', color: 'white' }}>Remove &#10006;</button>
                                                    <button onClick={() => showAddSkillPopup(user.username)} >Add Skills</button>
                                                    <button onClick={() => showAddTimeslotPopup(user.username)}>Add Time</button>
                                                </>
                                            )}
                                            {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && (workspaceUser.status === 'staffRejected' || workspaceUser.status === 'staffDeleted'
                                                                                                                            || workspaceUser.status === 'suspended')) && (
                                                <>
                                                    <button style={{ backgroundColor: '#f44336' }} onClick={() => removeUserFromWorkspace(user.username)}>Noted &#10004;</button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.ButtonContainer}>
                            <button onClick={OpenCreateUserPopup} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Create User</button>
                            <button onClick={Back} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} style={{ marginLeft: '20px' }}>Back</button>
                        </div>
                        {(deleteWorkspacePopup || removeUserConfirmPopup || addSkillsForStaffPopup || inviteStaffPopup || addTimeslotForStaffPopup || cancelPendingInvitePopup || rejectStaffRequestPopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {deleteWorkspacePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseManagerDeleteWorkspacePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to delete {workspaceName}</h1>
                                            <h1 className={styles.PopupTitle}>Owner By {workspaceOwner}</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={ManagerDeleteWorkspace} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm Delete &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {inviteStaffPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseInviteStaffPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Send an invite to an exisiting staff</h1>
                                            <form className={styles.FormContainer} onSubmit={SubmitInviteForm}>
                                                <input type="text" name="staffEmail" placeholder='Enter email here to invite the user that you want' onChange={handleInviteChange}/>
                                                <div className={styles.ButtonContainer}>
                                                    <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>SEND</button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                    {removeUserConfirmPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={closeConfirmRemoveUserPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to remove staff {tempUsername}</h1>
                                            <h1 className={styles.PopupTitle}>From Workspace {workspaceName}</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={deleteUserFromWorkspace} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm Delete &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {addSkillsForStaffPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={closeAddSkillPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Skills that have been added: </h1>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '20px 25px' }}>Skills: </th>
                                                        <th style={{ padding: '20px 25px' }}>Action: </th>
                                                    </tr>
                                                </thead>
                                                {workspaceDetails.filter(w => w.username === tempUsername).map((workspace, index) => {
                                                    const timeSlotItem = workspace.skillset.split(', ');
                                                    timeSlotItem.forEach(item => {
                                                        deleteSkillArray.push(item);
                                                    });
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = []
                                                                for (var i = 0; i < deleteSkillArray.length; i++) {
                                                                    let skill = deleteSkillArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{skill}</td>
                                                                            <td>
                                                                                {skill === 'none' ? (
                                                                                    <button style={{ backgroundColor: 'grey' }}>No Skill</button>
                                                                                ) : (
                                                                                    <button onClick={() => removeSkillsForStaff(skill)} style={{ backgroundColor: 'red', color: 'white' }}>Delete Skill &#10006;</button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                })}
                                            </table>
                                            <h1 className={styles.PopupTitle}>Add skills for {tempUsername}: </h1>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '20px 25px' }}>Skills: </th>
                                                        <th style={{ padding: '20px 25px' }}>Action: </th>
                                                    </tr>
                                                </thead>
                                                {workspaceDetails.filter(w => w.username === workspaceOwner).map((workspace, index) => {
                                                    const timeSlotItem = workspace.skillset.split(', ');
                                                    timeSlotItem.forEach(item => {
                                                        addSkillArray.push(item);
                                                    });
                                                    const diffArray = addSkillArray.filter(item => !deleteSkillArray.includes(item));
                                                    if (diffArray.length === 0) {
                                                        return (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td>none</td>
                                                                    <td><button style={{ backgroundColor: 'grey' }}>No Skill</button></td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    } else {
                                                        return (
                                                            <tbody key={index}>
                                                                {(() => {
                                                                    let rows = []
                                                                    for (var i = 0; i < diffArray.length; i++) {
                                                                        let skill = diffArray[i];
                                                                        rows.push(
                                                                            <tr key={i}>
                                                                                <td>{skill}</td>
                                                                                <td>
                                                                                    {skill === 'none' ? (
                                                                                        <button style={{ backgroundColor: 'grey' }}>No Skill</button>
                                                                                    ) : (
                                                                                        <button onClick={() => addSkillsForStaff(skill)} style={{ backgroundColor: 'green', color: 'white' }}>Add Skill &#10004;</button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return rows;
                                                                })()}
                                                            </tbody>
                                                        )
                                                    }
                                                })}
                                            </table>
                                        </>
                                    )}
                                    {addTimeslotForStaffPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseAddTimeslotPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Current timeslot for {tempUsername}: </h1>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th>Timeslot: </th>
                                                        <th>Action: </th>
                                                    </tr>
                                                </thead>
                                                {workspaceDetails.filter(w => w.username === tempUsername).map((workspace, index) => {
                                                    const timeSlotItem = workspace.timeslot.split(', ');
                                                    timeSlotItem.forEach(item => {
                                                        deleteTimeslotArray.push(item);
                                                    });
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = []
                                                                for (var i = 0; i < deleteTimeslotArray.length; i++) {
                                                                    let timeslot = deleteTimeslotArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{timeslot}</td>
                                                                            <td>
                                                                                {timeslot === 'none' ? (
                                                                                    <button style={{ backgroundColor: 'grey' }}>No Timeslot</button>
                                                                                ) : (
                                                                                    <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => DeleteTimeslot(workspace.username, workspace.name, workspace.owner, timeslot)}>Delete Timeslot &#10006;</button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                })}
                                            </table>
                                            <h1 className={styles.PopupTitle} style={{ marginTop: '20px' }}>Add timeslot for {tempUsername}</h1>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th>Timeslot: </th>
                                                        <th>Action: </th>
                                                    </tr>
                                                </thead>
                                                {workspaceDetails.filter(w => w.username === workspaceOwner).map((workspace, index) => {
                                                    const timeSlotItem = workspace.timeslot.split(', ');
                                                    timeSlotItem.forEach(item => {
                                                        addTimeslotArray.push(item);
                                                    });
                                                    const diffArray = addTimeslotArray.filter(item => !deleteTimeslotArray.includes(item));
                                                    if (diffArray.length === 0) {
                                                        return (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td>none</td>
                                                                    <td><button style={{ backgroundColor: 'grey' }}>No Timeslot</button></td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    } else {
                                                        return (
                                                            <tbody key={index}>
                                                                {(() => {
                                                                    let rows = [];
                                                                    for (var i = 0; i < diffArray.length; i++) {
                                                                        let timeslot = diffArray[i];
                                                                        rows.push(
                                                                            <tr key={i}>
                                                                                <td>{timeslot}</td>
                                                                                <td>
                                                                                    {timeslot === 'none' ? (
                                                                                        <button style={{ backgroundColor: 'grey' }}>No Timeslot</button>
                                                                                    ) : (
                                                                                        <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => AddTimeslot(workspace.name, workspace.owner, timeslot)}>Add Timeslot &#10004;</button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return rows;
                                                                })()}
                                                            </tbody>
                                                        )
                                                    }
                                                })}
                                            </table>
                                        </>
                                    )}
                                    {cancelPendingInvitePopup && (
                                        <>
                                            <button onClick={CloseDeletePendingInvitation} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm cancelling invitation to {tempUsername}?</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={deletePendingInvite} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {rejectStaffRequestPopup && (
                                        <>
                                            <button onClick={CloseRejectStaffRequest} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to reject {rejectStaffRequestUsername}?</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={rejectUser} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {createUserPopup && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDarkWOFlex : styles.PopupLightWOFlex}>
                                    {createUserPopup && (
                                        <>
                                            <h1 className={styles.PopupTitle}>Create a User for {workspaceName}</h1>
                                            <button className={styles.ClosePopup} onClick={CloseCreateUserPopup}>&#10006;</button>
                                            <form onSubmit={CreateUser} className={styles.FormContainer}>
                                                <table className={darkTheme ? styles.CreateTableDark : styles.CreateTableLight}>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <label>Username: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="username" placeholder="Enter your username" value={inputs.username} onChange={handleChange} required></input> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Password: </label>
                                                            </td>
                                                            <td>
                                                                <div className={styles.PasswordContainer}>
                                                                    <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" value={inputs.password} onChange={handleChange} 
                                                                    onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp} required/>
                                                                    <div className={styles.IconContainer}>
                                                                        {showPassword ? (
                                                                            <IoMdEyeOff className={styles.Icon} onClick={togglePasswordVisibility} />
                                                                        ) : (
                                                                            <IoMdEye className={styles.Icon} onClick={togglePasswordVisibility} />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className={styles.PasswordStrength}>
                                                                    {passwordStrength ? <PasswordStrength capsLetterFlag={checks.capsLetterCheck ? styles.valid : styles.invalid} 
                                                                                        numberFlag={checks.numberCheck ? styles.valid : styles.invalid}
                                                                                        passwordLengthFlag={checks.passwordLengthCheck ? styles.valid : styles.invalid}
                                                                                        specialCharFlag={checks.specialCharCheck ? styles.valid : styles.invalid}/> : null}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Confirm Password: </label>
                                                            </td>
                                                            <td>
                                                                <input type={showPassword ? "text" : "password"} name="confirmpassword" placeholder="Confirm Password" value={inputs.confirmpassword} onChange={handleChange} required/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>First Name: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="firstname" placeholder="Enter you first name" value={inputs.firstname} onChange={handleChange} required />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Family Name: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="familyname" placeholder="Enter your last name" value={inputs.familyname} onChange={handleChange} required />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Email: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="email" placeholder="Enter your email" value={inputs.email} onChange={handleChange} required />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Code: </label>
                                                            </td>
                                                            <td>
                                                                <CountryCode onChange={handleCountryCodeChange} value={selectedCountryCode} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="number" placeholder="Your phone number" value={inputs.number} onChange={handleChange} required />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Role: </label>
                                                            </td>
                                                            <td>
                                                                <input type='text' name='role' value={inputs.role} readOnly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" align="right">
                                                                {checks.capsLetterCheck && checks.numberCheck && checks.passwordLengthCheck && checks.specialCharCheck ? (
                                                                    <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} type="submit">CREATE</button>
                                                                ) : (
                                                                    <h1>Fulfill password strength to unlock button</h1>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default ManagerViewWorkspace;