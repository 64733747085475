import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './ViewAllWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffViewAllWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allWorkspace, setAllWorkspace ] = useState([]);
    const [ findWorkspacePopup, setFindWorkspacePopup ] = useState(false);
    const [ findWorkspace, setFindWorkspace ] = useState({
        ownerEmail: '',
        workspaceName: '',
        staffUsername: '',
    });
    const [ leaveWorkspacePopup, setLeaveWorkspacePopup ] = useState(false);
    const [ leaveWorkspaceDetails, setLeaveWorkspaceDetails ] = useState({
        staffUsername: username,
        workspaceName: '',
        workspaceOwner: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    setFindWorkspace(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    setLeaveWorkspaceDetails(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    const input = { staffUsername: decryptedData.username, token: decryptedToken };
                                    const encryptedPayload = encrypt(JSON.stringify(input));
                                    // Get all workspace that this staff is in
                                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=staffGetWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response2) {
                                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                                        let staffActiveWorkspace = false;
                                        for (var i = 0; i < decryptedData1.length; i++) {
                                            if (decryptedData1[i].status === 'approved') {
                                                staffActiveWorkspace = true;
                                            }
                                        }
                                        if (staffActiveWorkspace === true) {
                                            setAllWorkspace(decryptedData1);
                                        } else {
                                            switch (decryptedData1) {
                                                case 'no query found':
                                                case 'all fields required':
                                                    addToast('Error has occured, please contact customer support', 'error');
                                                    break;
                                                case 'token error':
                                                    addToast('Suspicious activity, please contact customer support', 'error');
                                                    break;
                                                case 'no result found':
                                                default:
                                                    navigate('/staffpendingworkspace');
                                                    break;
                                            }
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const calculateNumberOfStaff = (workspaceName, workspaceOwner) => {
        // Filter the workspace array to find entries with matching name and owner
        const matchingWorkspaces = allWorkspace.filter(workspace => workspace.name === workspaceName && workspace.owner === workspaceOwner);
        const numberOfStaff = matchingWorkspaces.length; // Count the number of matching entries
        return numberOfStaff;
    };
    const ViewWorkspace = (name, owner, tier) => {
        localStorage.setItem('staffTempName', name);
        localStorage.setItem('staffTempOwner', owner);
        localStorage.setItem('staffTempTier', tier);
        navigate('/staffviewworkspace');
    };
    const OpenFindWorkspacePopup = () => {
        setFindWorkspacePopup(true);
    };
    const CloseFindWorkspacePopup = () => {
        setFindWorkspacePopup(false);
    };
    const handleInput = (event) => {
        const { name, value } = event.target;
        setFindWorkspace (values => ({...values, [name]:value}));
    };
    const SendInviteJoinWorkspace = (event) => {
        event.preventDefault();
        // Token 
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...findWorkspace, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=requestAccess', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Request have been successfully delivered', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error occured, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'manager no workspace':
                                addToast('User email you entered does not have an active workspace', 'error');
                                break;
                            case 'free version exceed 10':
                                addToast('Workspace you reuqested to join is free version and have exceeded user limits', 'error');
                                break;
                            case 'duplicate':
                                addToast('You have already joined this workspace', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenLeaveWorkspacePopup = (name, owner) => {
        setLeaveWorkspacePopup(true);
        setLeaveWorkspaceDetails(prevData => ({
            ...prevData,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const CloseLeaveWorkspacePopup = () => {
        setLeaveWorkspacePopup(false);
        setLeaveWorkspaceDetails(prevData => ({
            ...prevData,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const LeaveWorkspace = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...leaveWorkspaceDetails, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=staffLeaveWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully left ${leaveWorkspaceDetails.workspaceName}!`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error leaving ${leaveWorkspaceDetails.workspaceName}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AcceptInvite = (name, owner) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=acceptInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully joined ${name}!`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error joining ${name}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const RejectInvite = (name, owner) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=rejectInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully rejected ${name}!`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error rejecting ${name}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, search for your workspace here: </h1>
                        <form className={styles.SearchForm} onSubmit={(event) => event.preventDefault()}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Name</option>
                                <option value="numstaff">No. of Staff</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder={`Search for ${searchField}`}
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>This is all your active workspace currently: </h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Name: </th>
                                    <th>No. of member</th>
                                    <th>Tier</th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allWorkspace.filter((workspace) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    if (searchField === 'numstaff') {
                                        return search === '' ? workspace : numberOfStaff === parseInt(search, 10);
                                    } else {
                                        return search.toLowerCase() === '' ? workspace : workspace[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).filter((workspace) => {
                                    return !(workspace.status === 'staffRejected' || workspace.status === 'staffDeleted');
                                }).sort((a, b) => {
                                    if (a.status === 'approved' && b.status !== 'approved') {
                                        return -1;
                                    }
                                    if (a.status !== 'approved' && b.status === 'approved') {
                                        return 1;
                                    }
                                    if (a.status === 'staffPending' && b.status !== 'staffPending') {
                                        return -1;
                                    }
                                    if (a.status !== 'staffPending' && b.status === 'staffPending') {
                                        return 1;
                                    }
                                    if (a.status === 'managerPending' && b.status !== 'managerPending') {
                                        return -1;
                                    }
                                    if (a.status !== 'managerPending' && b.status === 'managerPending') {
                                        return 1;
                                    }
                                    return 0;
                                }).map((workspace, key) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    return (
                                        <tr key={key}>
                                            <td>{workspace.name}</td>
                                            <td>{numberOfStaff}</td>
                                            <td>{workspace.tier}</td>
                                            <td>
                                                {workspace.status === 'approved' && (
                                                    <>
                                                        <button onClick={() => ViewWorkspace(workspace.name, workspace.owner, workspace.tier)}>View Workspace</button>
                                                        <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => OpenLeaveWorkspacePopup(workspace.name, workspace.owner)}>Leave &#10006;</button>
                                                    </>
                                                )}
                                                {workspace.status === 'managerPending' && (
                                                    <button style={{ backgroundColor: 'grey' }}>Pending Approval</button>
                                                )}
                                                {workspace.status === 'staffPending' && (
                                                    <>
                                                        <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => AcceptInvite(workspace.name, workspace.owner)}>Accept &#10004;</button>
                                                        <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => RejectInvite(workspace.name, workspace.owner)}>Reject &#10006;</button>
                                                    </>
                                                )}
                                                {workspace.status === 'managerDeleted' && (
                                                    <button style={{ backgroundColor: 'grey' }}>Manager Removed You</button>
                                                )}
                                                {workspace.status === 'suspended' && (
                                                    <button style={{ backgroundColor: 'grey' }}>Your account have been deactivated before</button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {userTier === 'premium' && (
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={OpenFindWorkspacePopup}>Find Workspace</button>
                        </div>
                        )}
                        {(findWorkspacePopup || leaveWorkspacePopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {findWorkspacePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseFindWorkspacePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Find Workspace To Join: </h1>
                                            <form className={styles.PopupForm} onSubmit={SendInviteJoinWorkspace}>
                                                <input type='text' name='ownerEmail' placeholder='Workspace Owner Email: ' onChange={handleInput}></input><br />
                                                <input type='text' name='workspaceName' placeholder='Workspace Name: ' onChange={handleInput}></input><br />
                                                <div className={styles.ButtonContainer}>
                                                    <button type='submit' className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>SEND</button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                    {leaveWorkspacePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseLeaveWorkspacePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to leave {leaveWorkspaceDetails.workspaceName}</h1>
                                            <h2 className={styles.PopupTitle}>Owned by {leaveWorkspaceDetails.workspaceOwner} ?</h2>
                                            <div className={styles.ButtonContainer}>
                                                <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={LeaveWorkspace}>Confirm &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffViewAllWorkspace