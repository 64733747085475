import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './StaffViewSchedule.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffViewSchedule() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const currentDate = new Date();
    const [ workspace, setWorkspace ] = useState([]);
    const [ selectedWorkspaceName, setSelectedWorkspaceName ] = useState('');
    const [ tasks, setTasks ] = useState([]);
    const [ selectedDate, setSelectedDate ] = useState(null);
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    // CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support', 'error');
                                                break;
                                            default:
                                                // Find all workspace this staff have
                                                const input = { staffUsername: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getAllStaffWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch (decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                        case 'no result found':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            let approved = false;
                                                            for (let i = 0; i < decryptedData1.length; i++) {
                                                                if (decryptedData1[i].status === 'approved') {
                                                                    approved = true;
                                                                    setWorkspace(decryptedData1);
                                                                    setSelectedWorkspaceName(decryptedData1[i].name);
                                                                    break;
                                                                }
                                                            }

                                                            if (approved === false) {
                                                                navigate('/staffpendingworkspace');
                                                            }
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    useEffect(() => {
        if (workspace.length > 0) {
            const workspaceDetails = workspace.find((workspace) => workspace.name === selectedWorkspaceName);
            // Get Token
            axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
                const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support');
                        break;
                    default:
                        const updatedInput = { workspaceName: selectedWorkspaceName, workspaceOwner: workspaceDetails.owner, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                        // Get all task
                        axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedData = JSON.parse(decrypt(response1.data));
                            switch (decryptedData) {
                                case 'query not found':
                                case 'all fields required':
                                case 'no result found':
                                    addToast("Error has occured, please contact customer support", 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    setTasks(decryptedData);
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
            }).catch(error => {
                console.error('Request failed: ', error);
            });
        }
    }, [selectedWorkspaceName]);
    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };
    const generateMonth = (year, month) => {
        const daysCount = daysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const days = [];
        for (let i = 1; i <= daysCount; i++) {
            days.push(i);
        }
        const blanks = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            blanks.push('');
        }
        const totalSlots = [...blanks, ...days];
        const rows = [];
        let cells = [];
        totalSlots.forEach((day, i) => {
            if (i % 7 !== 0) {
                cells.push(day);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(day);
            }
            if (i === totalSlots.length - 1) {
                rows.push(cells);
            }
        });
        return (
            <div className={styles.MonthWrapper} key={month}>
                <h2>{`${new Date(year, month).toLocaleString('default', { month: 'long' })} ${year}`}</h2>
                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                    <thead>
                        <tr>
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                        </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            {row.map((cell, index) => {
                                const isCellWithNumber = /^\d+$/.test(cell.toString());
                                const taskForCell = isCellWithNumber ? tasks.filter(task => {
                                    const taskStartYear = parseInt(task.startyear);
                                    const taskStartMonth = parseInt(task.startmonth);
                                    const taskStartDay = parseInt(task.startday);
                                    const taskEndYear = parseInt(task.endyear);
                                    const taskEndMonth = parseInt(task.endmonth);
                                    const taskEndDay = parseInt(task.endday);
                                    return (
                                        (taskStartYear < year || (taskStartYear === year && taskStartMonth <= month + 1)) && // Task starts before or during the current month
                                        (taskEndYear > year || (taskEndYear === year && taskEndMonth >= month + 1)) &&       // Task ends during or after the current month
                                        ((taskStartYear < year || taskStartMonth < month + 1) || taskStartDay <= cell) &&     // Task starts before or during the current month or on the current day
                                        ((taskEndYear > year || taskEndMonth > month + 1) || taskEndDay >= cell)              // Task ends during or after the current month or on the current day
                                    );
                                }) : [];
                                return (
                                    <td key={index} className={styles.TableData}>
                                        <p className={darkTheme ? styles.DateDark : styles.DateLight} onClick={() => handleDateClick(cell, month, year)}>
                                            {cell}
                                        </p>
                                        {taskForCell.map(task => {
                                            if (task.status === 'blockout') {
                                                return (
                                                    <div key={task.id} className={styles.BlockOutContainer}>
                                                        <span>Blockout: {task.name}</span><br />    
                                                        <span>Hours: {task.numhours}</span>
                                                    </div>
                                                )
                                            } else if (task.status === 'InProgress') {
                                                let included = false;
                                                let pos = '';
                                                if (task.staff !== '' || task.staff !== 'none') {
                                                    const staffArray = task.staff.split(', ').map(item => item.trim());
                                                    const staffStatusArray = task.staffstatus.split(', ').map(item => item.trim());
                                                    for (let i = 0; i < staffArray.length; i++) {
                                                        if (username === staffArray[i]) {
                                                            pos = i;
                                                            break;
                                                        }
                                                    }
                                                    if (pos !== '' && staffStatusArray[pos] === 'yes') {
                                                        included = true
                                                    }
                                                }
                                                return (
                                                    <div key={task.id} className={included ? styles.TaskAcceptedContainer : styles.TaskContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            } else if (task.status === 'staffAvailable') {
                                                return (
                                                    <div key={task.id} className={styles.StaffAvailableContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            } else if (task.status === 'Done') {
                                                return (
                                                    <div key={task.id} className={styles.DoneContainer}>
                                                        <span>Task Name: {task.name}</span><br />
                                                        <span>Staff: {task.staff}</span><br />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };
    const generateYear = () => {
        const year = currentDate.getFullYear();
        const months = [];
        for (let i = 0; i < 12; i++) {
            months.push(generateMonth(year, i));
        }
        return months;
    };
    const handleDateClick = (day, month, year) => {
        // Set the selected date
        setSelectedDate({ day, month, year });
    };
    const handleCloseModal = () => {
        // Clear the selected date
        setSelectedDate(null);
    };
    const handleWorkspaceChange = (event) => {
        setSelectedWorkspaceName(event.target.value);
    };
    const StaffAvailableDate = (day, month, year) => {
        // Get Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const workspaceDetails = workspace.find((workspace) => workspace.name === selectedWorkspaceName);
                    const updatedInput = {staffUsername: username, startDay: day, startMonth: month, startYear: year, workspaceName: selectedWorkspaceName, workspaceOwner: workspaceDetails.owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(updatedInput));
                    // Get all task
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=staffIndicateAvailable', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast('Success in indicating availability', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error indicating availability, please contact customer support', 'error');
                                break;
                            case 'query not found':
                            case 'all fields required':
                            case 'no result found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'duplicated record':
                                addToast('You have already indicated you are available this day', 'error');
                                break;
                            default:
                                setTasks(decryptedData);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={styles.Dropdown}>
                        <select className={styles.DropdownSelect} onChange={handleWorkspaceChange}>
                            {workspace.filter((ws) => {
                                return ws.status === 'approved';
                            }).map((ws, index) => (
                                <option key={index} value={ws}>
                                    {ws.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.Wrapper}>
                        {generateYear()}
                        {selectedDate && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    <button className={styles.ClosePopup} onClick={handleCloseModal}>&times;</button>
                                    <div className={styles.ViewTaskContainer}>
                                        <h1 className={styles.PopupTitle}>Selected Date: {selectedDate.day}/{selectedDate.month + 1}/{selectedDate.year}</h1>
                                        {tasks.filter((task) => {
                                            const taskStartDate = new Date(task.startyear, task.startmonth - 1, task.startday); // task start date
                                            const taskEndDate = new Date(task.endyear, task.endmonth - 1, task.endday); // task end date
                                            const selectedDateFormatted = new Date(selectedDate.year, selectedDate.month, selectedDate.day); // selected date
                                            return selectedDateFormatted >= taskStartDate && selectedDateFormatted <= taskEndDate;
                                        }).map((task, index) => (
                                            <div key={index}>
                                                <table className={styles.StaffViewTaskTable}>
                                                    {task.status === 'blockout' && (
                                                        <tbody>
                                                            <tr>
                                                                <td><label>Block out name: </label></td>
                                                                <td><label>{task.name}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Num Days: </label></td>
                                                                <td><label>{task.numdays}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Starting Time: </label></td>
                                                                <td><label>{task.starttime}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Num Hours: </label></td>
                                                                <td><label>{task.numhours}</label></td>
                                                            </tr>
                                                        </tbody>                                             
                                                    )}
                                                    {(task.status === 'Done' || task.status === 'InProgress') && (
                                                        <tbody>
                                                            <tr>
                                                                <td><label>Task Name: </label></td>
                                                                <td><label>{task.name}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Staff Involved: </label></td>
                                                                <td><label>{task.staff === '' || task.staff === 'none' ? 'none' : task.staff}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Num Days: </label></td>
                                                                <td><label>{task.numdays}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Starting Time: </label></td>
                                                                <td><label>{task.starttime}</label></td>
                                                            </tr>
                                                            <tr>
                                                                <td><label>Num Hours: </label></td>
                                                                <td><label>{task.numhours}</label></td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        ))}
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={() => StaffAvailableDate(selectedDate.day, selectedDate.month, selectedDate.year)} className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>Available this day</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffViewSchedule