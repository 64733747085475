import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from './LoginForm.module.css';
import ToastContainer from '../ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';

function LoginForm() {
    const navigate = useNavigate();
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [values, setValues] = useState({
        username: localStorage.getItem("rememberedUsername") || '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
    useEffect(() => {
        if (rememberMe) { // If checkbox is ticked then set to true
            setRememberMe(true);
        }
        if (rememberMe) { // Remembering username when check box is set to true
            const rememberedUsername = localStorage.getItem('rememberedUsername');
            if (rememberedUsername) {
                setValues(prev => ({ ...prev, username: rememberedUsername }));
            }
        } else {
            localStorage.removeItem('rememberedUsername');
        }
    }, [rememberMe]);
    const handleInput = (event) => {
        setValues(prev => ({...prev, [event.target.name]: event.target.value}));
    };
    const handleRememberMe = () => {
        const newState = !rememberMe;
        setRememberMe(newState);
        localStorage.setItem('rememberMe', newState ? 'true' : 'false');
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/account.php?action=login', values, { withCredentials: true }).then(function(response) {
            const decryptedText = JSON.parse(decrypt(response.data));
            switch (decryptedText) {
                case 'Cookie set successfully':
                    if (rememberMe) {
                        localStorage.setItem('rememberedUsername', values.username);
                    }
                    navigate('/homepage');
                    break;
                case 'Failed to set cookie':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                case 'suspended user':
                    addToast("Your account has been suspended, please contact customer support for more details", 'error');
                    break;
                case 'email not verified':
                    addToast("Please verify your account first", 'error');
                    break;
                case 'invalid username or password':
                    addToast("Invalid username or password", 'error');
                    break;
                case 'no records found':
                    addToast('Username not found', 'error');
                    break;
                default:
                    break;
            }
        }).catch(error => {
            console.error('Error during login:', error);
            addToast("An error occurred during login. Please try again.", 'error');
        });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    return (
        <div className={styles.LoginBody}>
            <ToastContainer toasts={toast} removeToast={removeToast} />
            <h1 className={styles.title}>PLANNER</h1>
            <div className={styles.wrapper}>
                <h1>LOGIN TO PLANNER</h1>
                <h2>Before you start, please enter your credentials</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.InputBox}>
                        <input type="text" name="username" placeholder="Username" value={values.username} onChange={handleInput} required/>
                        <FaUser className={styles.icon} />
                    </div>
                    <div className={styles.InputBox}>
                        <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" onChange={handleInput} required/>
                        {showPassword ? (
                            <IoMdEyeOff className={styles.icon} onClick={togglePasswordVisibility} />
                        ) : (
                            <IoMdEye className={styles.icon} onClick={togglePasswordVisibility} />
                        )}
                    </div>
                    <div className={styles.RememberForget}>
                        <label><input type="checkbox" checked={rememberMe} onChange={handleRememberMe} />Remember Me</label>
                    </div>
                    <button className={styles.LoginButton} type="submit">Login</button>
                    <div className={styles.RegisterLink}>
                        <p>Don't have an account? <Link to="/register">Register</Link></p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;