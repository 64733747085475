import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspaceTask.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerViewWorkspaceTask() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('managerViewTaskTempName');
    const workspaceOwner = localStorage.getItem('managerViewTaskTempOwner');
    const workspaceTier = localStorage.getItem('managerViewTaskTempTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceTask, setWorkspaceTask ] = useState([]);
    const [ workspaceStaff, setWorkspaceStaff ] = useState([]);
    const [ workspaceDetails, setWorkspaceDetails ] = useState([]);
    const [ leaveStaffDetails, setLeaveStaffDetails ] = useState([]);
    const ViewAllTaskBoolean = localStorage.getItem('ViewAllTask');
    const [ viewAllTask, setViewAllTask ] = useState(ViewAllTaskBoolean === null ? true : JSON.parse(ViewAllTaskBoolean));
    const ViewStaffLeaveTaskBoolean = localStorage.getItem('ViewStaffLeaveTask');
    const [ viewStaffLeaveTask, setViewStaffLeaveTask ] = useState(ViewStaffLeaveTaskBoolean === null ? false : JSON.parse(ViewStaffLeaveTaskBoolean));
    const [ editTaskPopup, setEditTaskPopup ] = useState(false);
    const [ editTask, setEditTask ] = useState({
        id: '',
        name: '',
        startday: '',
        startmonth: '',
        startyear: '',
        numdays: '',
        starttime: '',
        numhours: '',
        numstaff: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
        workspaceTier: '',
    });
    const [ editStaffPopup, setEditStaffPopup ] = useState(false);
    const [ increaseNumPopup, setIncreaseNumPopup ] = useState(false);
    const [ editStaffDetails, setEditStaffDetails ] = useState({
        id: '',
        name: '',
        username: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
    });
    const [ deleteTaskPopup, setDeleteTaskPopup ] = useState(false);
    const [ deleteTaskDetails, setDeleteTaskDetails ] = useState({});
    const [ rejectStaffLeave, setRejectStaffLeave ] = useState(false);
    const [ tempLeaveId, setTempLeaveId ] = useState({
        leaveId: '',
        reason: '',
    });
    const maxLength = 1000;
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response) {
                        const decryptedData = JSON.parse(decrypt(response.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'manager') {
                                    getWorkspaceTask();
                                    getWorkspaceStaffDetails();
                                    getWorkspaceDetails();
                                    getStaffLeaveTask();
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const getWorkspaceTask = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace task
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setWorkspaceTask([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceTask(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getWorkspaceStaffDetails = () => {
        // CSRF Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace staff
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceStaffDetails', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setWorkspaceStaff([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceStaff(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getWorkspaceDetails = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace details
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setWorkspaceDetails([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceDetails(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getStaffLeaveTask = () => {
        // CSRF Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Leave staff details
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=managerGetStaffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setLeaveStaffDetails([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support');
                                break;
                            default:
                                setLeaveStaffDetails(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ViewAllTask = () => {
        localStorage.setItem('ViewAllTask', true);
        localStorage.setItem('ViewStaffLeaveTask', false);
        setViewAllTask(true);
        setViewStaffLeaveTask(false);
    };
    const ViewStaffLeaveTask = () => {
        localStorage.setItem('ViewAllTask', false);
        localStorage.setItem('ViewStaffLeaveTask', true);
        setViewAllTask(false);
        setViewStaffLeaveTask(true);
    };
    const startDate = (day, month, year) => {
        return day + '/' + month + '/' + year;
    };
    const OpenEditTaskPopup = (id) => {
        let details = workspaceTask.find((task) => task.id === id);
        setEditTask(details);
        setEditTask(prevTaskData => ({
            ...prevTaskData,
            workspaceTier: workspaceTier,
        }));
        setEditTaskPopup(true);
    };
    const CloseEditTaskPopup = () => {
        setEditTask({});
        setEditTaskPopup(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditTask (values => ({...values, [name]:value}));
    };
    const ManagerEditTask = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...editTask, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=editTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Updating task is a success', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error updating, please try again later', 'error');
                                break;
                            case 'task name regex':
                                addToast('Name should only contain alphanumeric', 'error');
                                break;
                            case 'numstaff regex':
                                addToast('Number of staff entered is more than your current staff', 'error');
                                break;
                            case 'numdays regex':
                                addToast('Number of days maximum is 999 days', 'error');
                                break;
                            case 'day regex':
                                addToast('Day should only contain 2 number', 'error');
                                break;
                            case 'month regex':
                                addToast('Month should only contain 2 number', 'error');
                                break;
                            case 'year regex':
                                addToast('Year should only contain 4 number', 'error');
                                break;
                            case '32 day':
                            case '31 on a small month':
                            case 'feb leap':
                            case 'feb no leap':
                                addToast('The month you selected do not have the day you entered', 'error');
                                break;
                            case 'negative value':
                                addToast('No negative value', 'error');
                                break;
                            case 'all fields required':
                                addToast('All fields must be filled', 'error');
                                break;
                            case 'no result found':
                            case 'no query found':
                                addToast('Error has occurd, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'delete staff':
                                addToast('Please delete a staff to lower the number of staff needed', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenEditStaffPopup = (id, name) => {
        setEditStaffPopup(true);
        setEditStaffDetails(values => ({
            ...values,
            id: id,
            name: name,
        }));
    };
    const CloseEditStaffPopup = () => {
        setEditStaffPopup(false);
        setEditStaffDetails(values => ({
            ...values,
            id: '',
            name: '',
        }));
        setSearch1('');
    };
    const RemoveStaffFromTask = (username) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...editStaffDetails, username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=removeStaffFromTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Removing ${username} is a success!`, 'success');
                                getWorkspaceTask();
                                break;
                            case false:
                                addToast(`Error removing ${username}, pelase try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'error updating hours':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenIncreaseNumberOfStaff = (username) => {
        setIncreaseNumPopup(true);
        setEditStaffDetails(values => ({
            ...values,
            username: username,
        }));
    };
    const CloseIncreaseNumberOfStaff = () => {
        setIncreaseNumPopup(false);
        setEditStaffDetails(values => ({
            ...values,
            username: '',
        }));
    };
    const AddStaffToTask = (username) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...editStaffDetails, username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=addStaffToTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Adding ${username} is a success!`, 'success');
                                getWorkspaceTask();
                                break;
                            case false:
                                addToast(`Error adding ${username}, pelase try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'error updating hours':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AddStaffIncrease = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...editStaffDetails, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=addStaffAndIncrease', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Adding ${editStaffDetails.username} is a success!`, 'success');
                                getWorkspaceTask();
                                setIncreaseNumPopup(false);
                                setEditStaffDetails(values => ({
                                    ...values,
                                    username: '',
                                }));
                                break;
                            case false:
                                addToast(`Error adding ${username}, pelase try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'error updating hours':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenDeleteTaskPopup = (id, name) => {
        setDeleteTaskDetails(prevTaskData => ({
            ...prevTaskData,
            id: id,
            name: name,
        }));
        setDeleteTaskPopup(true);
    };
    const CloseDeleteTaskPopup = () => {
        setDeleteTaskDetails({});
        setDeleteTaskPopup(false);
    };
    const deleteTask = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { taskId: deleteTaskDetails.id, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=deleteTaskById', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Deleting task ${deleteTaskDetails.name} is a success`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error deleting ${deleteTaskDetails.name}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields requried':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request fail: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request fail: ', error);
        });
    };
    const ApproveStaffLeaveTaskRequest = (id) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { leaveId: id, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=approveStaffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Successfully approved!', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error approving, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenRejectStaffLeaveTaskPopup = (id) => {
        setRejectStaffLeave(true);
        setTempLeaveId(values => ({
            ...values,
            leaveId: id,
        }));
    };
    const CloseRejectStaffLeaveTaskPopup = () => {
        setRejectStaffLeave(false);
        setTempLeaveId(values => ({
            ...values,
            leaveId: '',
            reason: '',
        }));
    };
    const handleReasonChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= maxLength) {
            setTempLeaveId(values => ({...values, [name]:value}));
        }
    }
    const RejectStaffLeaveTaskRequest = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...tempLeaveId, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=rejectStaffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Rejection is pain, but a success', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error rejecting', 'error');
                                break;
                            case 'reason regex':
                                addToast('Only allow alphanumeric in reason field', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('managerViewTaskTempName');
        localStorage.removeItem('managerViewTaskTempOwner');
        localStorage.removeItem('managerViewTaskTempTier');
        navigate('/managerviewalltask');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    const formatDate = (day, month, year) => {
        // Ensure single-digit days and months are zero-padded
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedYear = year.toString();
        return `${formattedDay}/${formattedMonth}/${formattedYear}`;
    };
    const filterTasksByStartDate = (task, search) => {
        // Extract date components from the task
        const taskStartDate = formatDate(task.startday, task.startmonth, task.startyear);
    
        // Compare formatted dates
        return taskStartDate.toLowerCase().includes(search.toLowerCase());
    };
    const filterTasksByEndDate = (task, search) => {
        // Format task end date
        const taskEndDate = formatDate(task.endday, task.endmonth, task.endyear);
        
        // Compare formatted dates
        return taskEndDate.toLowerCase().includes(search.toLowerCase());
    };
    const [ search1, setSearch1 ] = useState('');
    const [ searchField1, setSearchField1 ] = useState('username');
    const searchRef1 = useRef();
    const [ search2, setSearch2 ] = useState('');
    const [ searchField2, setSearchField2 ] = useState('taskname');
    const searchRef2 = useRef();

    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    {viewAllTask && (
                        <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                            <h1 className={styles.SearchTitle}>Hi {username}, Search for task here: </h1>
                            <form className={styles.SearchForm}>
                                <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                    <option value="name">Task Name</option>
                                    <option value="staff">Staff</option>
                                    <option value="startdate">Start Date</option>
                                    <option value="enddate">End Date</option>
                                    <option value="numdays">No. of Days</option>
                                    <option value="numhours">No. of hours</option>
                                    <option value="status">Status</option>                            
                                </select>
                                <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                                onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                            </form>
                        </div>
                    )}
                    {viewStaffLeaveTask && (
                        <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                            <h1 className={styles.SearchTitle}>Hi {username}, Search for task here: </h1>
                            <form className={styles.SearchForm}>
                                <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField2} onChange={(e) => setSearchField2(e.target.value)}>
                                    <option value="taskname">Task Name</option>
                                    <option value="username">Username</option>
                                    <option value="reason">Reason</option>
                                    <option value="remark">Remark</option>                           
                                </select>
                                <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                                onChange={(e) => setSearch2(e.target.value)} ref={searchRef2}/>
                            </form>
                        </div>
                    )}
                    <div className={styles.ButtonContainer}>
                        <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} onClick={ViewAllTask}>All Task</button> 
                        <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} style={{ marginLeft: '20px' }} onClick={ViewStaffLeaveTask}>Staff Leave Task</button>
                    </div>
                    {viewAllTask && (
                        <div className={styles.Wrapper}>
                            <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                <thead>
                                    <tr>
                                        <th>Task Name: </th>
                                        <th>Staff: </th>
                                        <th>Staff Status: </th>
                                        <th>Start Date: </th>
                                        <th>No. of Days: </th>
                                        <th>Starting Time: </th>
                                        <th>No. of Hours: </th>
                                        <th>Status: </th>
                                        <th>Action: </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workspaceTask.filter((task) => {
                                        if (searchField === 'startdate') {
                                            return search.toLowerCase() === '' ? task : filterTasksByStartDate(task, search);
                                        } else if (searchField === 'enddate') {
                                            return search.toLowerCase() === '' ? task : filterTasksByEndDate(task, search);
                                        } else {
                                            return search.toLowerCase() === '' ? task : task[searchField].toLowerCase().includes(search.toLowerCase());
                                        }
                                    }).map((task, index) => (
                                        <tr key={index}>
                                            <td>{task.name}</td>
                                            <td>{task.staff === 'none' || task.staff === '' ? 'none' : task.staff}</td>
                                            <td>
                                                {(() => {
                                                    const valuesArray = task.staffstatus.split(', ').map(item => item.trim());
                                                    let statusString = ''
                                                    for (let i = 0; i < valuesArray.length; i++) {
                                                        if (valuesArray[i] === 'yes') {
                                                            if (statusString === '') {
                                                                statusString = 'Approved';
                                                            } else {
                                                                statusString += ', Approved';
                                                            }
                                                        } else if (valuesArray[i] === 'no') {
                                                            if (statusString === '') {
                                                                statusString = 'Pending';
                                                            } else {
                                                                statusString += ', Pending';
                                                            }
                                                        } else if (valuesArray[i] === '' || valuesArray[i] === 'none') {
                                                            statusString = 'none';
                                                        }
                                                    }
                                                    return statusString;
                                                })()}
                                            </td>
                                            <td>{startDate(task.startday, task.startmonth, task.startyear)}</td>
                                            <td>{task.numdays}</td>
                                            <td>{task.starttime}</td>
                                            <td>{task.numhours == '24' ? 'Whole Day' : task.numhours}</td>
                                            <td>{task.status}</td>
                                            <td>
                                                <>
                                                    {task.status !== 'Done' && (
                                                        <>
                                                            <button onClick={() => OpenEditTaskPopup(task.id)}>Edit Task</button>
                                                            {task.status !== 'blockout' && (
                                                                <button onClick={() => OpenEditStaffPopup(task.id, task.name)}>Append Staff</button>
                                                            )}
                                                        </>
                                                    )}
                                                    <button onClick={() => OpenDeleteTaskPopup(task.id, task.name)} style={{ backgroundColor: 'red', color: 'white' }}>
                                                        {task.status === 'blockout' ? `Delete Blockout ${String.fromCharCode(10006)}` : `Delete Task ${String.fromCharCode(10006)}`}
                                                    </button>
                                                </>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {viewStaffLeaveTask && (
                        <div className={styles.Wrapper}>
                            <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                <thead>
                                    <tr>
                                        <th>Task Name: </th>
                                        <th>Username: </th>
                                        <th>Reason: </th>
                                        <th>Remark: </th>
                                        <th>Status: </th>
                                        <th>Action: </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaveStaffDetails.filter((task) => {
                                        return search2.toLowerCase() === '' ? task : task[searchField2].toLowerCase().includes(search2.toLowerCase());
                                    }).filter(task => task.status !== 'staffPending').map((task, index) => (
                                        <tr key={index}>
                                            <td>{task.taskname}</td>
                                            <td>{task.username}</td>
                                            <td>{task.reason}</td>
                                            {task.remark === 'rejecttask' && (
                                                <td>Task Reject</td>
                                            )}
                                            {task.remark === 'leavetask' && (
                                                <td>Leave Task</td>
                                            )}
                                            <td>{task.status}</td>
                                            <td>
                                                {task.status === 'approved' && (
                                                    <button style={{ backgroundColor: 'grey' }}>Approved</button>
                                                )}
                                                {task.status === 'managerRejected' && (
                                                    <button style={{ backgroundColor: 'grey' }}>Rejected</button>
                                                )}
                                                {task.status === 'pending' && (
                                                    <>
                                                        <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => ApproveStaffLeaveTaskRequest(task.id)}>Approve &#10004;</button>
                                                        <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => OpenRejectStaffLeaveTaskPopup(task.id)}>Reject &#10006;</button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className={styles.ButtonContainer}>
                        <button onClick={Back} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>BACK</button>
                    </div>
                    {(editTaskPopup || editStaffPopup || rejectStaffLeave) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {editTaskPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseEditTaskPopup}>&#10006;</button>
                                            <form className={styles.EditForm} onSubmit={ManagerEditTask}>
                                                <h1 className={styles.FormTitle}>Edit Task: </h1>
                                                <table className={styles.EditTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <label>Name: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="name" value={editTask.name} onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Start Day: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="startday" value={editTask.startday} placeholder='Without 0 infront if single digit' onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Start Month: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="startmonth" value={editTask.startmonth} placeholder='Without 0 infront if single digit' onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Start Year: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="startyear" value={editTask.startyear} placeholder='Without 0 infront if single digit' onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of days </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numdays" value={editTask.numdays} onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Starting Time: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="starttime" value={editTask.starttime} placeholder='In 24 hour format: 0900' onChange={handleChange} required></input><br/> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of hours: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numhours" value={editTask.numhours} placeholder='How many hours per day' onChange={handleChange} required></input><br/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label>Number of staff needed: </label>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="numstaff" value={editTask.numstaff} placeholder='How many staff needed' onChange={handleChange} required></input><br/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" align="right">
                                                                <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} type="submit">EDIT</button>
                                                                <input type="hidden" name='id' value={editTask.id}></input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </form>
                                        </>
                                    )}
                                    {editStaffPopup && (
                                        <>
                                            <button onClick={CloseEditStaffPopup} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Editing staff for task: </h1>
                                            <form className={styles.SearchForm}>
                                                <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField1} onChange={(e) => setSearchField1(e.target.value)}>
                                                    <option value="username">Username</option>
                                                    <option value="firstname">First Name</option>
                                                    <option value="familyname">Family Name</option>      
                                                    <option value="skillset">Skillset</option>
                                                    <option value="timeslot">Timeslot</option>
                                                </select>
                                                <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                                                onChange={(e) => setSearch1(e.target.value)} ref={searchRef1}/>
                                            </form>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th>Username: </th>
                                                        <th>First name: </th>
                                                        <th>Family name: </th>
                                                        <th>Skills: </th>
                                                        <th>Timeslot: </th>
                                                        <th>Action: </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {workspaceTask.filter(task => task.id === editStaffDetails.id && task.name === editStaffDetails.name).map((task, taskIndex) => {
                                                        const names = task.staff.split(', ').map(name => name.trim());
                                                        const filteredStaff = workspaceStaff.filter(staff => names.includes(staff.username));
                                                        const leftOverStaff = workspaceStaff.filter(staff => !names.includes(staff.username));
                                                        return (
                                                            <React.Fragment key={taskIndex}>
                                                                {filteredStaff.filter((staff) => {
                                                                    if (searchField1 === 'skillset') {
                                                                        return search1 === '' ? true : workspaceDetails.some((user) => user.username === staff.username && user.skillset.toLowerCase().includes(search1.toLowerCase()));
                                                                    } else if (searchField1 === 'timeslot') {
                                                                        return search1 === '' ? true : workspaceDetails.some((user) => user.username === staff.username && user.timeslot.toLowerCase().includes(search1.toLowerCase()));
                                                                    } else {
                                                                        return search1.toLowerCase() === '' ? true : staff[searchField1].toLowerCase().includes(search1.toLowerCase());
                                                                    }
                                                                }).filter((staff) => {
                                                                    const approved = workspaceDetails.some((user) => staff.username === user.username && user.status === 'approved');
                                                                    return approved;
                                                                }).map((user, userIndex) => {
                                                                    const staff = workspaceDetails.filter(staff => staff.username === user.username);
                                                                    return (
                                                                        <tr key={userIndex}>
                                                                            <td>{user.username}</td>
                                                                            <td>{user.firstname}</td>
                                                                            <td>{user.familyname}</td>
                                                                            <td>{staff[0].skillset}</td>
                                                                            <td>{staff[0].timeslot}</td>
                                                                            <td><button onClick={() => RemoveStaffFromTask(user.username)} style={{ backgroundColor: 'red', color: 'white' }}>Remove &#10006;</button></td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                {leftOverStaff.filter((staff) => {
                                                                    if (searchField1 === 'skillset') {
                                                                        return search1 === '' ? true : workspaceDetails.some((user) => user.username === staff.username && user.skillset.toLowerCase().includes(search1.toLowerCase()));
                                                                    } else if (searchField1 === 'timeslot') {
                                                                        return search1 === '' ? true : workspaceDetails.some((user) => user.username === staff.username && user.timeslot.toLowerCase().includes(search1.toLowerCase()));
                                                                    } else {
                                                                        return search1.toLowerCase() === '' ? true : staff[searchField1].toLowerCase().includes(search1.toLowerCase());
                                                                    }
                                                                }).filter((staff) => {
                                                                    const approved = workspaceDetails.some((user) => staff.username === user.username && user.status === 'approved');
                                                                    return approved;
                                                                }).map((user, userIndex) => {
                                                                    const staff = workspaceDetails.filter(staff => staff.username === user.username);
                                                                    return (
                                                                        <tr key={userIndex}>
                                                                            <td>{user.username}</td>
                                                                            <td>{user.firstname}</td>
                                                                            <td>{user.familyname}</td>
                                                                            <td>{staff[0].skillset}</td>
                                                                            <td>{staff[0].timeslot}</td>
                                                                            <td>
                                                                                {parseInt(task.numstaff) === filteredStaff.length ? (
                                                                                    <button onClick={() => OpenIncreaseNumberOfStaff(user.username)} style={{ backgroundColor: 'green', color: 'white' }}>Increase number of staff needed</button>
                                                                                ) : (
                                                                                    <button onClick={() => AddStaffToTask(user.username)} style={{ backgroundColor: 'green', color: 'white' }}>Add</button>
                                                                                )} 
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                    {rejectStaffLeave && (
                                        <>
                                            <button onClick={CloseRejectStaffLeaveTaskPopup} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Write your reason for rejecting: </h1>
                                            <form onSubmit={RejectStaffLeaveTaskRequest} className={styles.LeaveTaskForm}>
                                                <textarea type='text' name='reason' placeholder='Enter your reason for rejecting staff leave request: ' value={tempLeaveId.reason} onChange={handleReasonChange} />
                                                <div className={styles.CountCharacterContainer}>
                                                    {tempLeaveId.reason.length}/{maxLength}
                                                </div>
                                                <div className={styles.ButtonContainer}>
                                                    <button type='submit' className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>SEND</button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {(deleteTaskPopup || increaseNumPopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDarkWithFlex : styles.PopupLightWithFlex}>
                                    {deleteTaskPopup && (
                                        <>
                                            <button onClick={CloseDeleteTaskPopup} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm Delete '{deleteTaskDetails.name}' ?</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} onClick={deleteTask}>Confirm Delete &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {increaseNumPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseIncreaseNumberOfStaff}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to increase the number of staff needed for this task?</h1>
                                            <button onClick={AddStaffIncrease} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                </div>
            </Layout>
        </Layout>
    );
};

export default ManagerViewWorkspaceTask;