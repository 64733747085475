import React, { useEffect } from 'react';
import Toast from '../Toast/Toast';
import styles from './ToastContainer.module.css';

const ToastContainer = ({ toasts, removeToast }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (toasts.length > 0) {
                removeToast(toasts[0].id);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, [toasts, removeToast]);

    return (
        <div className={styles['toast-container']}>
            {toasts.map((toast) => (
                <Toast
                    key={toast.id}
                    message={toast.message}
                    type={toast.type}
                    onClose={() => { removeToast(toast.id) }}
                />
            ))}
        </div>
    );
};

export default ToastContainer;
