import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspaceBids.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminViewWorkspaceBids() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('SAViewBidTempWorkspaceName');
    const workspaceOwner = localStorage.getItem('SAViewBidTempWorkspaceOwner');
    const workspaceTier = localStorage.getItem('SAViewBidTempWorkspaceTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceBids, setWorkspaceBids ] = useState([]);
    const [ workspaceTask, setWorkspaceTask ] = useState([]);
    const [ approveBidPopup, setApproveBidPopup ] = useState(false);
    const [ approveBidDetails, setApproveBidDetails ] = useState({
        bidId: '',
        username: '',
        taskid: '',
        taskname: '',
    });
    const [ removeStaffPopup, setRemoveStaffPopup ] = useState(false);
    const [ removeStaffDetails, setRemoveStaffDetails ] = useState({
        staffUsername: '',
        taskid: '',
        taskname: '',
        reason: '',
    });
    const maxLength = 1000;
    const [ rejectBidPopup, setRejectBidPopup ] = useState(false);
    const [ rejectBid, setRejectBid ] = useState({
        bidId: '',
        taskName: '',
        staffUsername: '',
        reason: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace bid
                                                axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=getWorkspaceBids', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all firelds required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setWorkspaceBids([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setWorkspaceBids(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    })
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get workspace task
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all firelds required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setWorkspaceTask([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setWorkspaceTask(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const startDate = (day, month, year) => {
        return day + '/' + month + '/' + year;
    };
    const OpenTaskDetails = (id, username, taskid, taskname) => {
        setApproveBidPopup(true);
        setApproveBidDetails(values => ({
            ...values,
            bidId: id,
            username: username,
            taskid: taskid,
            taskname: taskname,
        }));
    };
    const CloseTaskDetails = () => {
        setApproveBidPopup(false);
        setApproveBidDetails(values => ({
            ...values,
            bidId: '',
            username: '',
            taskid: '',
            taskname: '',
        }));
    };
    const OpenRemoveStaffPopup = (username, id, name) => {
        setRemoveStaffPopup(true);
        setRemoveStaffDetails(values => ({
            ...values,
            staffUsername: username,
            taskid: id,
            taskname: name,
        }));
    };
    const CloseRemoveStaffPopup = () => {
        setRemoveStaffPopup(false);
        setRemoveStaffDetails(values => ({
            ...values,
            staffUsername: '',
            taskid: '',
            taskname: '',
        }));
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= maxLength) {
            setRemoveStaffDetails(values => ({...values, [name]:value}));
        }
    };
    const RemoveStaffFromTask = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...removeStaffDetails, addUsername: approveBidDetails.username, bidId: approveBidDetails.bidId, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=removeStaffFromTaskAndAddNewStaff', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Success in removing ${removeStaffDetails.staffUsername} and added ${approveBidDetails.username} into ${removeStaffDetails.taskname}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error occured, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const IncreaseAndAdd = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...approveBidDetails, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=IncreaseAndAdd', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully increase the number of staff needed and add ${approveBidDetails.username} into ${approveBidDetails.taskname}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenRejectBid = (id, name, username) => {
        setRejectBidPopup(true);
        setRejectBid(values => ({
            ...values,
            bidId: id,
            taskName: name,
            staffUsername: username,
        }));
    };
    const CloseRejectBid = () => {
        setRejectBidPopup(false);
        setRejectBid(values => ({
            ...values,
            bidId: '',
            taskName: '',
            staffUsername: '',
            reason: '',
        }));
    };
    const handleRejectBidChange = (event) => {
        const { name, value } = event.target;
        setRejectBid(values => ({...values, [name]:value}));
    };
    const RejectStaffBid = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...rejectBid, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=rejectStaffBid', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Success in rejecting ${rejectBid.staffUsername} of ${rejectBid.taskName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error rejecting, making you have second thoughts', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support','error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('SAViewBidTempWorkspaceName');
        localStorage.removeItem('SAViewBidTempWorkspaceOwner');
        localStorage.removeItem('SAViewBidTempWorkspaceTier');
        navigate('/systemadminviewalltask');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} workspaceTier={workspaceTier} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username} ({userRole}), Search for a bid here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="taskname">Task Name</option>
                                <option value="username">Username</option>
                                <option value="comment">Comment</option>
                                <option value="status">Status</option>                         
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Task Name: </th>
                                    <th>Workspace Name: </th>
                                    <th>Username: </th>
                                    <th>Comment: </th>
                                    <th>Status: </th>
                                    <th>Action: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {workspaceBids.filter((bid) => {
                                    return search.toLowerCase() === '' ? bid : bid[searchField].toLowerCase().includes(search.toLowerCase());
                                }).map((bid, index) => (
                                    <tr key={index}>
                                        <td>{bid.taskname}</td>
                                        <td>{bid.workspacename}</td>
                                        <td>{bid.username}</td>
                                        <td>{bid.comment}</td>
                                        <td>{bid.status}</td>
                                        <td>
                                            {bid.status === 'approved' && (
                                                <button style={{ backgroundColor: 'grey' }}>Approved</button>
                                            )}
                                            {bid.status === 'rejected' && (
                                                <button style={{ backgroundColor: 'grey' }}>Rejected</button>
                                            )}
                                            {bid.status === 'pending' && (
                                                <>
                                                    <button onClick={() => OpenTaskDetails(bid.id, bid.username, bid.taskid, bid.taskname)} style={{ backgroundColor: 'green' }}>Approve &#10004;</button>
                                                    <button onClick={() => OpenRejectBid(bid.id, bid.taskname, bid.username)} style={{ backgroundColor: 'red' }}>Reject &#10006;</button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.ButtonContainer}>
                        <button onClick={Back} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>BACK</button>
                    </div>
                    {(approveBidPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {approveBidPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseTaskDetails}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>This is the task details: </h1>
                                        <table className={styles.Table}>
                                            <thead>
                                                <tr>
                                                    <th>Task Name: </th>
                                                    <th>Start Date: </th>
                                                    <th>End Date: </th>
                                                    <th>No. Hours: </th>
                                                    <th>No. Days</th>
                                                    <th>Working Hours: </th>
                                                </tr>
                                            </thead>
                                            {workspaceTask.filter(task => parseInt(task.id) === parseInt(approveBidDetails.taskid) && task.name === approveBidDetails.taskname).map((task, index) => (
                                                <tbody key={index}>
                                                    <tr>
                                                        <td>{task.name}</td>
                                                        <td>{startDate(task.startday, task.startmonth, task.startyear)}</td>
                                                        <td>{startDate(task.endday, task.endmonth, task.endyear)}</td>
                                                        <td>{task.numhours}</td>
                                                        <td>{task.numdays}</td>
                                                        <td>{parseInt(task.numdays) * parseInt(task.numhours)}</td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                        <h1 className={styles.MiddleTitle}>Either to remove staff or to increase the number of needed for this task</h1>
                                        <table className={styles.Table}>
                                            <tbody>
                                                {workspaceTask.filter(task => parseInt(task.id) === parseInt(approveBidDetails.taskid) && task.name === approveBidDetails.taskname).map((task, index) => {
                                                    const names = task.staff.split(', ');
                                                    const status = task.staffstatus.split(', ');
                                                    let staff = [];
                                                    for (let i = 0; i < names.length; i++) {
                                                        staff.push(
                                                            <tr key={i}>
                                                                <td>{names[i]}</td>
                                                                <td>{status[i] === 'no' ? (
                                                                    <>Not Accepted Yet</>
                                                                ) : (
                                                                    <>Accepted Task</>
                                                                )}</td>
                                                                <td><button onClick={() => OpenRemoveStaffPopup(names[i], task.id, task.name)} style={{ backgroundColor: 'red' }}>Remove Staff &#10006;</button></td>
                                                            </tr>
                                                        )
                                                    }
                                                    return staff;
                                                })}
                                            </tbody>
                                        </table>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={IncreaseAndAdd} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Increase number of staff needed</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {(removeStaffPopup || rejectBidPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDarkWithFlex : styles.PopupLightWithFlex}>
                               {removeStaffPopup && (
                                    <>
                                        <button onClick={CloseRemoveStaffPopup} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to remove {removeStaffDetails.staffUsername} from {removeStaffDetails.taskname}?</h1>
                                        <textarea type='text' name='reason' className={styles.ReasonArea} placeholder='Enter your reason for leaving task: ' value={removeStaffDetails.reason} onChange={handleChange} />
                                        <div className={styles.CountCharacterContainer}>
                                            {removeStaffDetails.reason.length}/{maxLength}
                                        </div>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={RemoveStaffFromTask} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {rejectBidPopup && (
                                    <>
                                        <button onClick={CloseRejectBid} className={styles.ClosePopup}>&#10006;</button>
                                        <form className={styles.RejectForm} onSubmit={RejectStaffBid}>
                                            <h1 className={styles.PopupTitle}>Reason to reject {rejectBid.staffUsername}'s bid: </h1>
                                            <textarea type='text' name='reason' className={styles.ReasonArea} placeholder='Enter your reason for rejecting bid: ' value={rejectBid.reason} onChange={handleRejectBidChange} />
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={RejectStaffBid} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>SUBMIT</button>
                                            </div>
                                        </form>
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
}

export default SystemAdminViewWorkspaceBids;