import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewStaffSchedule.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminViewStaffSchedule() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const staffUsername = localStorage.getItem('SAViewStaffScheduleUsername');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allTask, setAllTask ] = useState([]);
    const [ allStaffWorkspace, setAllStaffWorkspace ] = useState([]);
    const [ staffDetails, setStaffDetails ] = useState({});
    const [ removeUserFromTaskPopup, setRemoveUserFromTaskPopup ] = useState(false);
    const [ removeUser, setRemoveUser ] = useState({
        taskId: '',
        name: '',
        staffUsername: staffUsername,
        workspaceName: '',
        workspaceOwner: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: staffUsername, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get user all workspace task
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=systemAdminGetStaffTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'all fields required':
                                                        case 'no query found':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setAllTask([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setAllTask(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: staffUsername, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get Staff Details
                                                axios.post('https://www.plannerforplanning.xyz/api/account.php?action=getUserByUsername', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                        case 'no result found':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setStaffDetails(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: staffUsername, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace working hours
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                        case 'no result found':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setAllStaffWorkspace(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    })
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const startDate = (day, month, year) => {
        return parseInt(day) + '/' + parseInt(month) + '/' + parseInt(year);
    };
    const OpenRemoveUserFromTaskPopup = (id, name, workspacename, workspaceowner) => {
        setRemoveUserFromTaskPopup(true);
        setRemoveUser(values => ({
            ...values,
            taskId: id,
            name: name,
            workspaceName: workspacename,
            workspaceOwner: workspaceowner,
        }));
    };
    const CloseRemoveUserFromTaskPopup = () => {
        setRemoveUserFromTaskPopup(false);
        setRemoveUser(values => ({
            ...values,
            taskId: '',
            name: '',
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const RemoveStaffFromTask = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...removeUser, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/task.php?action=deleteStaffFromTask', encryptedPayload, { withCredentials: true } ).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Staff have been removed task ${removeUser.name}!`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error removing staff from task ${removeUser.name}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'error changing working hour':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('SAViewStaffScheduleUsername');
        navigate('/systemadminviewworkspace');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    const formatDate = (day, month, year) => {
        // Ensure single-digit days and months are zero-padded
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedYear = year.toString();
        return `${formattedDay}/${formattedMonth}/${formattedYear}`;
    };
    const filterTasksByStartDate = (task, search) => {
        // Extract date components from the task
        const taskStartDate = formatDate(task.startday, task.startmonth, task.startyear);
    
        // Compare formatted dates
        return taskStartDate.toLowerCase().includes(search.toLowerCase());
    };
    const filterTasksByEndDate = (task, search) => {
        // Format task end date
        const taskEndDate = formatDate(task.endday, task.endmonth, task.endyear);
        
        // Compare formatted dates
        return taskEndDate.toLowerCase().includes(search.toLowerCase());
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username} ({userRole}), Search for {staffUsername}'s all involved tasks</h1>
                        <form className={styles.SearchStaffSchedule}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Task Name</option>
                                <option value="numdays">No. of days</option>
                                <option value="startdate">Start Date</option>
                                <option value="enddate">End Date</option>
                                <option value="starttime">Starting Hour</option>
                                <option value="numhours">No. of hours</option>
                                <option value="workspacename">Workspace Name</option>
                                <option value="workspaceowner">Workspace Owner</option>
                                <option value="status">Status</option>
                            </select>
                            <input className={styles.SearchStaffScheduleInput} type="text" name="search" id="search" placeholder="Search for tasks..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>This is all the task assigned to {staffDetails.firstname} {staffDetails.familyname}</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Task Name: </th>
                                    <th>Start Date: </th>
                                    <th>Num of Days: </th>
                                    <th>Working Hours: </th>
                                    <th>Workspace Name: </th>
                                    <th>Workspace Owner: </th>
                                    <th>Status: </th>
                                    <th>Action: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allTask.filter((task) => {
                                    if (searchField === 'startdate') {
                                        return search.toLowerCase() === '' ? task : filterTasksByStartDate(task, search);
                                    } else if (searchField === 'enddate') {
                                        return search.toLowerCase() === '' ? task : filterTasksByEndDate(task, search);
                                    } else {
                                        return search.toLowerCase() === '' ? task : task[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).map((task, index) => (
                                    <tr key={index}>
                                        <td>{task.name}</td>
                                        <td>{startDate(task.startday, task.startmonth, task.startyear)}</td>
                                        <td>{task.numdays}</td>
                                        <td>{parseInt(task.numdays) * parseInt(task.numhours)}</td>
                                        <td>{task.workspacename}</td>
                                        <td>{task.workspaceowner}</td>
                                        <td>
                                            <>
                                                {task.status === 'Done' ? (
                                                    <>Done</>
                                                ) : (
                                                    <>On Going</>
                                                )}
                                            </>
                                        </td>
                                        <td>
                                            <>
                                                {task.status === 'Done' ? (
                                                    <button style={{ backgroundColor: 'grey' }}>DONE</button>
                                                ) : (
                                                    <button style={{ backgroundColor: 'red' }} onClick={() => OpenRemoveUserFromTaskPopup(task.id, task.name, task.workspacename, task.workspaceowner)}>Remove Task &#10006;</button>
                                                )}
                                            </>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.Container}>
                            {allStaffWorkspace.map((ws, index) => (
                                <React.Fragment key={index}>
                                    <h1>Working hour for workspace {ws.name}: {ws.hours} Hours</h1>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} onClick={Back}>BACK</button>
                        </div>
                        {(removeUserFromTaskPopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {removeUserFromTaskPopup && (
                                        <>
                                            <button onClick={CloseRemoveUserFromTaskPopup} className={styles.ClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Confirm to remove {staffDetails.firstname} {staffDetails.familyname} from task {removeUser.name}?</h1>
                                            <button onClick={RemoveStaffFromTask} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Confirm &#10004;</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
}

export default SystemAdminViewStaffSchedule;