import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import MenuList from '../../AllUsers/MenuList/MenuList';
import styles from './ViewAllUser.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminViewAllUser() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allUser, setAllUser ] = useState([]);
    const [ suspendUserPopup, setSuspendUserPopup ] = useState(false);
    const [ suspendUsername, setSuspendUsername ] = useState('');
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { role: decryptedData.role, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all user
                                                axios.post('https://www.plannerforplanning.xyz/api/account.php?action=systemAdminGetAllUser', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no permission':
                                                            navigate('/stoptryingtobefunny');
                                                            break;
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setAllUser([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setAllUser(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const EditUser = (username) => {
        localStorage.setItem('SAEditUserUsername', username);
        navigate('/systemadminedituser');
    };
    const ChangePassword = (username) => {
        localStorage.setItem('SAChangePasswordTempUsername', username);
        navigate('/systemadminchangepassword');
    };
    const OpenSuspendUserPopup = (username) => {
        setSuspendUserPopup(true);
        setSuspendUsername(username);
    };
    const CloseSuspendUserPopup = () => {
        setSuspendUserPopup(false);
        setSuspendUsername('');
    };
    const SuspendUser = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { username: suspendUsername, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=suspendUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`User ${suspendUsername} has been suspended`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error suspending user, please contact customer support", 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'manager have active workspace':
                                addToast('Error suspending as manager have active workspace', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ReactivateUser = (username) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=reactivateUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`User ${username} has been reactivated`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('username');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username} ({userRole}), Search for a user here: </h1>
                        <form className={styles.SearchUserForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="username">Username</option>
                                <option value="firstname">First Name</option>
                                <option value="familyname">Family Name</option>
                                <option value="email">Email</option>
                                <option value="code">Code</option>
                                <option value="number">Number</option>
                                <option value="role">Role</option>
                            </select>
                            <input type="text" className={styles.SearchUserInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.ViewUserWrapper}>
                        <h1 className={styles.TableTitle}>This is all the user that exists in PLANNER</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Username: </th>
                                    <th>First Name: </th>
                                    <th>Family Name: </th>
                                    <th>Email: </th>
                                    <th>Code: </th>
                                    <th>Number: </th>
                                    <th>Role: </th>
                                    <th>Activity: </th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUser.filter((user) => {
                                    return search.toLowerCase() === '' ? user : user[searchField].toLowerCase().includes(search.toLowerCase());
                                }).filter((user) => {
                                    return user.role !== 'system admin';
                                }).sort((a, b) => {
                                    // First sort by activity
                                        if (a.activity.toLowerCase() === 'active' && b.activity.toLowerCase() !== 'active') {
                                            return -1;
                                        }
                                        if (a.activity.toLowerCase() !== 'active' && b.activity.toLowerCase() === 'active') {
                                            return 1;
                                        }

                                        // Then sort by role to have 'manager' roles at the top
                                        if (a.role.toLowerCase() === 'manager' && b.role.toLowerCase() !== 'manager') {
                                            return -1;
                                        }
                                        if (a.role.toLowerCase() !== 'manager' && b.role.toLowerCase() === 'manager') {
                                            return 1;
                                        }

                                        // If both activity and role are the same, keep original order
                                        return 0;
                                }).map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.username}</td>
                                        <td>{user.firstname}</td>
                                        <td>{user.familyname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.code}</td>
                                        <td>{user.number}</td>
                                        <td>{user.role}</td>
                                        <td>{user.activity}</td>
                                        <td>
                                            <>
                                                <button onClick={() => EditUser(user.username)}>Edit</button>
                                                <button onClick={() => ChangePassword(user.username)}>Change Password</button>
                                                {user.activity === 'active' ? (
                                                    <button style={{ backgroundColor: 'red' }} onClick={() => OpenSuspendUserPopup(user.username)}>Suspend &#10006;</button>
                                                ) : (
                                                    <button style={{ backgroundColor: 'green' }} onClick={() => ReactivateUser(user.username)}>Reactivate &#10004;</button>
                                                )}
                                            </>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {suspendUserPopup && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                <button onClick={CloseSuspendUserPopup} className={styles.PopupClose}>&#10006;</button>
                                <h1 className={styles.PopupTitle}>Confirm to suspend {suspendUsername}?</h1>
                                <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} onClick={SuspendUser}>Confirm &#10004;</button>
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default SystemAdminViewAllUser;