import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import CountryCode from "../../CountryCode";
import PasswordStrength from "../../PasswordStrength";
import styles from './CreateUser.module.css';
import ToastContainer from '../ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';

function CreateUser() {
    const navigate = useNavigate();
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ inputs, setInputs ] = useState({
        username: "",
        password: "",
        confirmpassword: "",
        firstname: "",
        familyname: "",
        email: "",
        code: "",
        number: "",
        role: "",
    });
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordStrength, setPasswordStrength ] = useState(false);
    const [ checks, setChecks ] = useState({
        capsLetterCheck : false,
        numberCheck: false,
        passwordLengthCheck: false,
        specialCharCheck: false,
    });
    const [ selectedCountryCode, setSelectedCountryCode ] = useState("");
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs (values => ({...values, [name]:value}));
    };
    const handleFocus = () => {
        setPasswordStrength(true);
    };
    const handleBlur = () => {
        setPasswordStrength(false);
    };
    const handleKeyUp = (event) => {
        const { value } = event.target;
        const capsLetterCheck = /[A-Z]/.test(value);
        const numberCheck = /[0-9]/.test(value);
        const passwordLengthCheck = value.length > 7 && value.length < 21;
        const specialCharCheck = /[.,/:;'"{}\[\]!@#$%^&*()=_\-+]/.test(value);
        setChecks({
            capsLetterCheck,
            numberCheck,
            passwordLengthCheck,
            specialCharCheck,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const encryptedPayload = encrypt(JSON.stringify(inputs));
        axios.post('https://www.plannerforplanning.xyz/api/account.php?action=createUser', encryptedPayload).then(function(response) {
            const decryptedText = JSON.parse(decrypt(response.data));
            switch (decryptedText) {
                case true:
                    addToast('Registration successful, bringing you to login page', 'success');
                    setTimeout(() => {
                        navigate('/');
                    }, 1500);
                    break;
                case false:
                    addToast("Error registering", 'error');
                    break;
                case 'fields are missing':
                    addToast("Some fields are missing", 'error');
                    break;
                case 'password no match':
                    addToast("Passwords are not the same", 'error');
                    break;
                case 'name regex':
                    addToast("Name should only include alphabets and spacing", 'error');
                    break;
                case 'email regex':
                    addToast("Please enter a valid email", 'error');
                    break;
                case 'number regex': 
                    addToast("Please enter a valid number", 'error');
                    break;
                case 'username exist':
                    addToast("Username have been taken, please choose another one", 'error');
                    break
                case 'email exist':
                    addToast("Email have been taken, please choose another one", 'error');
                    break
                case 'number exist':
                    addToast("Number exist, please use another number", 'error');
                    break;
                case 'question regex':
                    addToast('Question and answer must only be alpha numeric', 'error');
                    break;
                case 'duplicated question':
                    addToast('Please set 3 security questions differently', 'error');
                    break;
                default:
                    break;
            }
            setSelectedCountryCode(""); // Reset selected country code
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handleGoBack = () => {
        navigate('/');
    };
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
        handleChange(event); // Propagate the change event to update the inputs state
    };
    return (
        <div className={styles.RegisterBody}>
            <div className={styles.RegisterWrapper}>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <button className={styles.RegisterBackButton} onClick={handleGoBack}>BACK</button>
                <h1 className={styles.RegisterWrapperTitle}>CREATE AN ACCOUNT WITH PLANNER</h1>
                <h1 className={styles.RegisterWrapperTitle2}>Do take note of case sensitive</h1>
                <form onSubmit={handleSubmit} className={styles.RegisterUserForm}>
                    <table cellSpacing="10" className={styles.RegisterTable}>
                        <tbody>
                            <tr>
                                <th>
                                    <label>Username: </label>
                                </th>
                                <td>
                                    <input type="text" name="username" placeholder="Enter your username" value={inputs.username} onChange={handleChange} required></input> 
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Password: </label>
                                </th>
                                <td>
                                    <div className={styles.RegisterUserPasswordContainer}>
                                        <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" value={inputs.password} onChange={handleChange} 
                                        onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp} required/>
                                        <div className={styles.IconContainer}>
                                            {showPassword ? (
                                                <IoMdEyeOff className='icon' onClick={togglePasswordVisibility} />
                                            ) : (
                                                <IoMdEye className='icon' onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.PasswordStrength}>
                                        {passwordStrength ? <PasswordStrength capsLetterFlag={checks.capsLetterCheck ? styles.valid : styles.invalid} 
                                                            numberFlag={checks.numberCheck ? styles.valid : styles.invalid}
                                                            passwordLengthFlag={checks.passwordLengthCheck ? styles.valid : styles.invalid}
                                                            specialCharFlag={checks.specialCharCheck ? styles.valid : styles.invalid}/> : null}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Confirm Password: </label>
                                </th>
                                <td>
                                    <input type={showPassword ? "text" : "password"} name="confirmpassword" placeholder="Confirm Password" value={inputs.confirmpassword} onChange={handleChange} required/>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>First Name: </label>
                                </th>
                                <td>
                                    <input type="text" name="firstname" placeholder="Enter you first name" value={inputs.firstname} onChange={handleChange} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Family Name: </label>
                                </th>
                                <td>
                                    <input type="text" name="familyname" placeholder="Enter your last name" value={inputs.familyname} onChange={handleChange} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Email: </label>
                                </th>
                                <td>
                                    <input type="text" name="email" placeholder="Enter your email" value={inputs.email} onChange={handleChange} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Code: </label>
                                </th>
                                <td>
                                    <CountryCode onChange={handleCountryCodeChange} value={selectedCountryCode} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Number: </label>
                                </th>
                                <td>
                                    <input type="text" name="number" placeholder="Your phone number" value={inputs.number} onChange={handleChange} required />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <label>Role: </label>
                                </th>
                                <td>
                                    <select className={styles.RegisterUserSelect} name="role" onChange={handleChange} value={inputs.role} required>
                                        <option id="null" value="">Choose your role: </option>
                                        <option id="manager" value="manager">Manager (Create a work space and add your staff in, or choose to join a workspace)</option>
                                        <option id="staff" value="staff">Staff (Wait for your manager to add you into a workspace)</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" align="right">
                                    {checks.capsLetterCheck && checks.numberCheck && checks.passwordLengthCheck && checks.specialCharCheck ? (
                                        <button className={styles.RegisterCreateButton} type="submit">CREATE</button>
                                    ) : (
                                        <h1 style={{ color: 'black' }}>Ensure password strength to unlock register button </h1>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    );
};

export default CreateUser;