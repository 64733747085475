import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import MenuList from '../../AllUsers/MenuList/MenuList';
import styles from './EditUser.module.css';
import CountryCode from '../../CountryCode';
import DatePicker from "react-datepicker";
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminEditUser() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const tempUsername = localStorage.getItem('SAEditUserUsername');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        console.log('Removing toast with id:', id);
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ selectedCountryCode, setSelectedCountryCode ] = useState("");
    const [ userData, setUserData ] = useState({
        id: '',
        username: '',
        firstname: '',
        familyname: '',
        dob: '',
        email: '',
        code: '',
        number: '',
        role: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        console.log(decryptedData);
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: tempUsername, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get user data
                                                axios.post('https://www.plannerforplanning.xyz/api/account.php?action=getUserByUsername', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    console.log(decryptedData1);
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                        case 'no result found':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setUserData(decryptedData1);
                                                            setSelectedCountryCode(decryptedData1.code);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData (values => ({...values, [name]:value}));
    };
    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
        handleChange(event); // Propagate the change event to update the inputs state
    };
    const handleDOBChange = (date) => {
        setSelectedDate(date);
        setUserData(prevUserData => ({ ...prevUserData, dob: date }));
    };
    const EditUser = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...userData, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=editUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        console.log(decryptedData);
                        switch (decryptedData) {
                            case true:
                                addToast("Update profile is successful", 'success');
                                setTimeout(() => {
                                    navigate('/systemadminviewalluser');
                                }, 1500);
                                break;
                            case false:
                                addToast("Error updating profile", 'error');
                                break;
                            case 'name regex':
                                addToast("Please enter a valid name", 'error');
                                break;
                            case 'email regex':
                                addToast("Please enter a valid email", 'error');
                                break;
                            case 'number regex':
                                addToast("Please enter a valid number", 'error');
                                break;
                            case 'email exist':
                                addToast("This email have been taken, please use another email", 'error');
                                break;
                            case 'number exist':
                                addToast("This number have been taken, please use another number", 'error');
                                break;
                            case 'all fields required':
                                addToast("All fields are required", 'error');
                                break;
                            case 'no query found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('SAEditUserUsername');
        navigate('/systemadminviewalluser');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <h1 className={styles.UpdateTitle}>Update Profile for {tempUsername}</h1>
                    <form onSubmit={EditUser} className={styles.UpdateProfileForm}>
                        <table cellSpacing="10">
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Username: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="username" value={userData.username} onChange={handleChange} readOnly></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>First Name: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="firstname" value={userData.firstname} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Family Name: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="familyname" value={userData.familyname} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>DOB: (Optional) </label>
                                    </td>
                                    <td>
                                        <DatePicker className={styles.UpdateCalendar} name='dob' selected={selectedDate} onChange={handleDOBChange}
                                            dateFormat="dd/MM/yyyy" placeholderText="Select DOB" isClearable showYearDropdown scrollableYearDropdown
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Email: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="email" value={userData.email} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Code: </label>
                                    </td>
                                    <td>
                                        <CountryCode onChange={handleCountryCodeChange} value={selectedCountryCode} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Number: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="number" value={userData.number} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Role: </label>
                                    </td>
                                    <td>
                                        <input type="text" className={styles.NormalInput} name="role" value={userData.role} onChange={handleChange} readOnly></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" align="right">
                                        <button type="submit" className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>UPDATE</button>
                                        <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} style={{ marginLeft: '20px' }} onClick={Back}>BACK</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </Layout>
        </Layout>
    );
};

export default SystemAdminEditUser;