import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffViewWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('staffTempName');
    const workspaceOwner = localStorage.getItem('staffTempOwner');
    const workspaceTier = localStorage.getItem('staffTempTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceDetails, setWorkspaceDetails ] = useState([]);
    const [ workspaceStaff, setWorkspaceStaff ] = useState([]);
    const [ leaveWorkspacePopup, setLeaveWorkspacePopup ] = useState(false);
    const [ addSkillPopup, setAddSkillPopup ] = useState(false);
    const deleteSkillArray = [];
    const addSkillArray = [];
    const [ addTimeslotPopup, setAddTimeslotPopup ] = useState(false);
    const deleteTimeslotArray = [];
    const addTimeslotArray = [];
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    getWorkspaceDetails();
                                    getWorkspaceStaff();
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const getWorkspaceDetails = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace details
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceDetails(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getWorkspaceStaff = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace staff
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceStaffDetails', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setWorkspaceStaff([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceStaff(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('staffTempName');
        localStorage.removeItem('staffTempOwner');
        localStorage.removeItem('staffTempTier');
        navigate('/staffviewallworkspace');
    };
    const OpenLeaveWorkspacePopup = () => {
        setLeaveWorkspacePopup(true);
    };
    const CloseLeaveWorkspacePopup = () => {
        setLeaveWorkspacePopup(false);
    };
    const LeaveWorkspace = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=staffLeaveWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully left ${workspaceName}!`, 'success');
                                setTimeout(() => {
                                    navigate('/staffviewallworkspace');
                                }, 1500);
                                break;
                            case false:
                                addToast(`Error leaving ${workspaceName}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenAddSkillPopup = () => {
        setAddSkillPopup(true);
    };
    const CloseAddSkillPopup = () => {
        setAddSkillPopup(false);
    };
    const DeleteSkill = (name, owner, skill) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, skill: skill, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully removed skill ${skill}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error removing skill ${skill}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AddSkill = (name, owner, skill) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: name, workspaceOwner: owner, skill: skill, staffUsername: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=updateUserSkills', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully added skill ${skill}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error adding skill ${skill}, please try again later`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenAddTimeslotPopup = () => {
        setAddTimeslotPopup(true);
    };
    const CloseAddTimeslotPopup = () => {
        setAddTimeslotPopup(false);
    };
    const DeleteTimeslot = (name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully remove ${timeslot}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error removing ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AddTimeslot = (name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
        const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=addUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully added ${timeslot}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error adding ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('username');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, search for your co-workers here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="username">Username</option>
                                <option value="firstname">First Name</option>
                                <option value="familyname">Family Name</option>
                                <option value="email">Email</option>
                                <option value="role">Role</option>
                                <option value="skillset">Skills</option>
                                <option value="timeslot">Timeslot</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Username: </th>
                                    <th>First Name: </th>
                                    <th>Family Name: </th>
                                    <th>Email: </th>
                                    <th>Role: </th>
                                    <th>Skills: </th>
                                    <th>Timeslot: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {workspaceStaff.filter((user) => {
                                    if (searchField === 'skillset') {
                                        return workspaceDetails.some(ws => ws.username === user.username && ws.skillset.toLowerCase().includes(search.toLowerCase()));
                                    } else if (searchField === 'timeslot') {
                                        return workspaceDetails.some(ws => ws.username === user.username && ws.timeslot.toLowerCase().includes(search.toLowerCase()));
                                    } else {
                                        return search.toLowerCase() === '' ? user : user[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                    // return search.toLowerCase() === '' ? user : user[searchField].toLowerCase().includes(search.toLowerCase());
                                }).map((user, index) => (
                                    <tr key={index}>
                                        <td><img src={`./pp/${user.picture}`} /></td>
                                        {username === user.username ? (<td>{user.username}(me)</td>) : (<td>{user.username}</td>)}
                                        <td>{user.firstname}</td>
                                        <td>{user.familyname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>{workspaceDetails.find(w => w.username === user.username)?.skillset || 'None'}</td>
                                        <td>{workspaceDetails.find(w => w.username === user.username)?.timeslot || 'None'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={OpenLeaveWorkspacePopup}>Leave Workspace</button>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={OpenAddSkillPopup}>Add Skills</button>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={OpenAddTimeslotPopup}>Add Timeslot</button>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={Back}>BACK</button>
                        </div>
                    </div>
                    {(addSkillPopup || addTimeslotPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {addSkillPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseAddSkillPopup}>&#10006;</button>
                                        <h1 className={styles.TableTitle}>Remove skills: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th>Skill: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(workspace => workspace.username === username).map((workspace, index) => {
                                                const stackItem = workspace.skillset.split(', ');
                                                stackItem.forEach(item => {
                                                    deleteSkillArray.push(item);
                                                });
                                                return (
                                                    <tbody key={index}>
                                                        {(() => {
                                                            let rows = [];
                                                            for (var i = 0; i < deleteSkillArray.length; i++) {
                                                                let skill = deleteSkillArray[i];
                                                                rows.push(
                                                                    <tr key={i}>
                                                                        <td>{skill}</td>
                                                                        <td>
                                                                            {skill !== 'none' ? (
                                                                                <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => DeleteSkill(workspace.name, workspace.owner, skill)}>Delete Skill &#10006;</button>
                                                                            ) : (
                                                                                <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return rows;
                                                        })()}
                                                    </tbody>
                                                )
                                            })}
                                        </table>
                                        <h1 className={styles.TableTitle}>Add Skills: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th>Skill: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(workspace => workspace.username === workspaceOwner).map((workspace, index) => {
                                                const stackItem = workspace.skillset.split(', ');
                                                stackItem.forEach(item => {
                                                    addSkillArray.push(item);
                                                });
                                                const diffArray = addSkillArray.filter(item => !deleteSkillArray.includes(item));
                                                if (diffArray.length === 0) {
                                                    return (
                                                        <tbody key={index}>
                                                            <tr>
                                                                <td>none</td>
                                                                <td><button style={{ backgroundColor: 'grey' }}>No Skill</button></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                } else {
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = [];
                                                                for (var i = 0; i < diffArray.length; i++) {
                                                                    let skill = diffArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{skill}</td>
                                                                            <td>
                                                                                <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => AddSkill(workspace.name, workspace.owner, skill)}>Add Skill &#10004;</button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                }
                                            })}
                                        </table>
                                    </>
                                )}
                                {addTimeslotPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseAddTimeslotPopup}>&#10006;</button>
                                        <h1 className={styles.TableTitle}>Remove timeslot: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th>Timeslot: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(workspace => workspace.username === username).map((workspace, index) => {
                                                const timeSlotItem = workspace.timeslot.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    deleteTimeslotArray.push(item);
                                                });
                                                return (
                                                    <tbody key={index}>
                                                        {(() => {
                                                            let rows = [];
                                                            for (var i = 0; i < deleteTimeslotArray.length; i++) {
                                                                let timeslot = deleteTimeslotArray[i];
                                                                rows.push(
                                                                    <tr key={i}>
                                                                        <td>{timeslot}</td>
                                                                        <td>
                                                                            {timeslot === 'none' ? (
                                                                                <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                            ) : (
                                                                                <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => DeleteTimeslot(workspace.name, workspace.owner, timeslot)}>Delete Timeslot &#10006;</button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return rows;
                                                        })()}
                                                    </tbody>
                                                )
                                            })}
                                        </table>
                                        <h1 className={styles.TableTitle}>Add Timeslot: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th>Timeslot: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(workspace => workspace.username === workspaceOwner).map((workspace, index) => {
                                                const timeSlotItem = workspace.timeslot.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    addTimeslotArray.push(item);
                                                });
                                                const diffArray = addTimeslotArray.filter(item => !deleteTimeslotArray.includes(item));
                                                if (diffArray.length === 0) {
                                                    return (
                                                        <tbody key={index}>
                                                            <tr>
                                                                <td>none</td>
                                                                <td><button style={{ backgroundColor: 'grey' }}>No Timeslot</button></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                } else {
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = []
                                                                for (var i = 0; i < diffArray.length; i++) {
                                                                    let timeslot = diffArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{timeslot}</td>
                                                                            <td>
                                                                                <button style={{ backgroundColor: 'green', color: 'white'}} onClick={() => AddTimeslot(workspace.name, workspace.owner, timeslot)}>Add Timeslot &#10004;</button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                }
                                            })}
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {leaveWorkspacePopup && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDarkWithFlex : styles.PopupLightWithFlex}>
                                {leaveWorkspacePopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseLeaveWorkspacePopup}>&#10006;</button>
                                        <h1 className={styles.TableTitle}>Are you sure to leave {workspaceName}</h1>
                                        <h2 className={styles.TableTitle}>Owned by {workspaceOwner} ?</h2>
                                        <div className={styles.ButtonContainer}>
                                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={LeaveWorkspace}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffViewWorkspace;