import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import { handleLogout } from '../../logout';
import styles from './NoWorkspace.module.css';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerNoWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ managerInput, setManagerInput ] = useState({
        ownerUsername: '',
        workspaceName: '',
        workspaceTier: '',
        staffUsername: '',
        ownerEmail: '',
    });
    const [ openExtra, setOpenExtra ] = useState(false);
    const [ workspace, setWorkspace ] = useState([]);
    const [ deleteRequestPopup, setDeleteRequestPopup ] = useState(false);
    const [ deleteRequest, setDeleteRequest ] = useState({
        staffUsername: username,
        workspaceName: '',
        workspaceOwner: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response) {
                        const decryptedData = JSON.parse(decrypt(response.data));
                        switch (decryptedData) {
                            case 'no records found':
                            case 'no cookie found':
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                setManagerInput(values => ({
                                    ...values,
                                    ownerUsername: decryptedData.username,
                                    staffUsername: decryptedData.username,
                                    workspaceTier: decryptedData.tier,
                                }));
                                if (decryptedData.active === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'manager') {
                                    // CSRF Token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
                                        const decryptedToken = JSON.parse(decrypt(response1.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspace', encryptedPayload, { withCredentials: true }).then(function(response2) {
                                                    const decryptedData1 = JSON.parse(decrypt(response2.data));
                                                    if (Array.isArray(decryptedData1)) {
                                                        if (decryptedData1.length === 0) {
                                                            // Do nothing
                                                        } else {
                                                            let active = false;
                                                            for (let i = 0; i < decryptedData1.length; i++) {
                                                                if (decryptedData1[i].status === 'approved') {
                                                                    active = true;
                                                                    break;
                                                                }
                                                            }
                                                            if (active === true) {
                                                                navigate('/managerviewallworkspace');
                                                            } else {
                                                                setOpenExtra(true);
                                                                setWorkspace(decryptedData1);
                                                            }
                                                        }
                                                    } else {
                                                        switch (decryptedData1) {
                                                            case 'no query found':
                                                            case 'all fields required':
                                                                addToast('Error has occurred, please contact customer support', 'error');
                                                                break;
                                                            case 'no workspace found':
                                                                setWorkspace([]);
                                                                break;
                                                            case 'token error':
                                                                addToast('Suspicious activity, please contact customer support', 'error');
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const handleManagerInput = (event) => {
        setManagerInput(prev => ({...prev, [event.target.name]: event.target.value}));
    };
    const createWorkspace = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...managerInput, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=createWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText1 = JSON.parse(decrypt(response1.data));
                        switch(decryptedText1) {
                            case true:
                                addToast("Workspace created successfully", 'success');
                                setTimeout(() => {
                                    navigate('/homepage');
                                }, 1000);
                                break;
                            case false:
                                addToast("Error creating workspace, please contact customer support", 'error');
                                break;
                            case 'all fields required':
                                addToast("All fields are required", 'error');
                                break;
                            case 'name regex':
                                addToast("Please enter a valid name, alphanumeric and white space only", 'error');
                                break;
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const JoinWorkspace = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...managerInput, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=requestAccess', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText1 = JSON.parse(decrypt(response1.data));
                        switch (decryptedText1) {
                            case true:
                                addToast(`Success in sending request to ${managerInput.workspaceName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                                break;
                            case false:
                                addToast(`Error sending request to join ${managerInput.workspaceName}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'manager no workspace':
                                addToast('This owner does not exist or does not have an active workspace', 'error');
                                break;
                            case 'duplicate':
                                addToast('You are already in this workspace', 'warning');
                                break;
                            case 'free version exceed 10':
                                addToast('Owner is free version and does not have any more slots for you', 'error');
                                break;
                            case 'name regex':
                                addToast('Please enter a valid name', 'error');
                                break;
                            case 'email regex':
                                addToast('Please enter a valid email', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenDeleteRequest = (name, owner) => {
        setDeleteRequestPopup(true);
        setDeleteRequest(values => ({
            ...values,
            staffUsername: username,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const CloseDeleteRequest = () => {
        setDeleteRequestPopup(false);
        setDeleteRequest(values => ({
            ...values,
            staffUsername: username,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const DeleteRequest = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...deleteRequest, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deletePendingWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText1 = JSON.parse(decrypt(response1.data));
                        switch (decryptedText1) {
                            case true:
                                addToast("You can find a better one!", 'success');
                                setTimeout(() => {
                                    navigate('/homepage');
                                }, 1500);
                                break;
                            case false:
                            case 'no query found':
                            case 'all fields required':
                                addToast("Error has occured, please try again later", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const acceptInvitation = (name, owner) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=acceptInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText1 = JSON.parse(decrypt(response1.data));
                        switch (decryptedText1) {
                            case true:
                                addToast(`Welcome to ${name}!`, 'success');
                                setTimeout(() => {
                                    navigate('/homepage');
                                }, 1500);
                                break;
                            case false:
                                addToast("Hmmm... something went wrong, please try again later", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;                    
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const rejectInvitation = (name, owner) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=rejectInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText1 = JSON.parse(decrypt(response1.data));
                        switch (decryptedText1) {
                            case true:
                                addToast(`Successful rejecting ${name}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Hmmm... something went wrong, please try again later", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;                    
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.BorderDark : styles.BorderLight}>
                        <form onSubmit={createWorkspace} className={styles.Form}>
                            <h1 className={styles.Title}>Seems like you do not have a workspace yet</h1>
                            <h1 className={styles.Title}>Create one to get started now</h1>
                            <input type="text" name="username" placeholder="Enter your username" onChange={handleManagerInput} value={username} readOnly/>
                            <input type="text" name="workspaceName" placeholder="Enter your workspace name" onChange={handleManagerInput} required />
                            <input type='hidden' name='workspaceTier' value={userTier} />
                            <div className={styles.ButtonContainer}>
                                <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>CREATE</button>
                            </div>
                        </form><br /><br />
                        <h1 className={styles.MiddleText}>OR</h1>
                        <form onSubmit={JoinWorkspace} className={styles.Form}>
                            <h1 className={styles.Title}>Join an active workspace</h1>
                            <input type="text" name="staffUsername" onChange={handleManagerInput} value={username} readOnly />
                            <input type="text" name="workspaceName" placeholder="Enter your workspace name" onChange={handleManagerInput} required />
                            <input type='text' name='ownerEmail' placeholder='Enter workspace owner email' onChange={handleManagerInput} required />
                            <input type='hidden' name='workspaceTier' onChange={handleManagerInput} value={userTier} />
                            <div className={styles.ButtonContainer}>
                                <button type='submit' className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>SUBMIT REQUEST</button>
                            </div>
                        </form>
                    </div>
                    {openExtra && (
                        <>
                            <h1 className={styles.WrapperTitle}>The following applications are still being assessed: </h1>
                            {workspace.length > 0 ? (
                                workspace.filter((ws) => ws.status === 'managerPending').length > 0 ? (
                                    workspace.filter((ws) => ws.status === 'managerPending').map((ws, index) => (
                                        <React.Fragment key={index}>
                                            <h1 className={styles.WorkspaceDetails}>{index + 1}. {ws.name}, by {ws.owner}</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} onClick={() => OpenDeleteRequest(ws.name, ws.owner)}>Cancel Request</button>
                                            </div>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>The following workspace have removed you: </h1>
                            {workspace.length > 0 ? (
                                workspace.filter((ws) => ws.status === 'managerDeleted').length > 0 ? (
                                    workspace.filter((ws) => ws.status === 'managerDeleted').map((ws, index) => (
                                        <React.Fragment key={index}>
                                            <h1 className={styles.WorkspaceDetails}>{index + 1}. {ws.name}, by {ws.owner}</h1>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>You have previously deleted you account, this is the workspace you have left: </h1>
                            {workspace.length > 0 ? (
                                workspace.filter((ws) => ws.status === 'suspended').length > 0 ? (
                                    workspace.filter((ws) => ws.status === 'suspended').map((ws, index) => (
                                        <React.Fragment key={index}>
                                            <h1 className={styles.WorkspaceDetails}>{index + 1}. {ws.name}, by {ws.owner}</h1>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>The following workspace have sent you an invite: </h1>
                            {workspace.length > 0 ? (
                                workspace.filter((ws) => ws.status === 'staffPending').length > 0 ? (
                                    workspace.filter((ws) => ws.status === 'staffPending').map((ws, index) => (
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight} key={index}>
                                            <thead>
                                                <tr>
                                                    <th>Name of workspace: </th>
                                                    <th>Owner: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr> 
                                                    <td>{ws.name}</td>
                                                    <td>{ws.owner}</td>
                                                    <td>
                                                        <button style={{ backgroundColor: 'green' }} onClick={() => acceptInvitation(ws.name, ws.owner)}>Accept &#10004;</button>
                                                        <button style={{ backgroundColor: 'red' }} onClick={() => rejectInvitation(ws.name, ws.owner)}>Reject &#10007;</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                        </>
                    )}
                    {(deleteRequestPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {deleteRequestPopup && (
                                    <>
                                        <button onClick={CloseDeleteRequest} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to delete request to {deleteRequest.workspaceName}?</h1>
                                        <button onClick={DeleteRequest} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default ManagerNoWorkspace;