import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout, Table } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspaceBid.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffViewWorkspaceBid() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('staffViewBidName');
    const workspaceOwner = localStorage.getItem('staffViewBidOwner');
    const workspaceTier = localStorage.getItem('staffViewBidTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceBids, setWorkspaceBids ] = useState([]);
    const [ rejectedBids, setRejectedBids ] = useState([]);
    const [ bidPopup, setBidPopup ] = useState(false);
    const [ cancelBid, setCancelBid ] = useState({
        id: '',
        name: '',
        staffUsername: '',
    });
    const [ showRejectReasonPopup, setShowRejectedReasonPopup ] = useState(false);
    const [ rejectedId, setRejectedId ] = useState('');
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    setCancelBid(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: decryptedData.username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get own bids
                                                axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=staffGetOwnBid', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all firelds required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setWorkspaceBids([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setWorkspaceBids(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: decryptedData.username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get own bids
                                                axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=staffGetRejectedBid', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all firelds required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setRejectedBids([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setRejectedBids(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const OpenCancelBidPopup = (id, name) => {
        setBidPopup(true);
        setCancelBid(values => ({
            ...values,
            id: id,
            name: name,
        }));
    };
    const CloseCancelBidPopup = () => {
        setBidPopup(false);
        setCancelBid(values => ({
            ...values,
            id: '',
            name: '',
        }));
    };
    const CancelBid = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { ...cancelBid, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=staffCancelBid', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast(`Successfully cancelled bid to ${cancelBid.name}!`, 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error cancelling, please try again later', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                case 'no result found':
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ShowRejectedReason = (id) => {
        setShowRejectedReasonPopup(true);
        setRejectedId(id);
    };
    const CloseRejectedReason = () => {
        setShowRejectedReasonPopup(false);
        setRejectedId();
    };
    const RemoveRejectedBid = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { taskId: rejectedId, staffUsername: username, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=removeStaffRejectedBidFromLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast('Acknowledgement success', 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error cancelling, please try again later', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                case 'no result found':
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('staffViewBidName');
        localStorage.removeItem('staffViewBidOwner');
        localStorage.removeItem('staffViewBidTier')
        navigate('/staffviewalltask');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('taskname');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} workspaceTier={workspaceTier} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, Search for your own bids here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="taskname">Task Name</option>
                                <option value="comment">Comment</option>
                                <option value="status">Status</option>                         
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Bids for workspace {workspaceName}, owned by {workspaceOwner}</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Task Name: </th>
                                    <th>Workspace Name: </th>
                                    <th>Username: </th>
                                    <th>Comment: </th>
                                    <th>Status: </th>
                                    <th>Action: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {workspaceBids.filter((bid) => {
                                    return search.toLowerCase() === '' ? bid : bid[searchField].toLowerCase().includes(search.toLowerCase());
                                }).map((bid, index) => (
                                    <tr key={index}>
                                        <td>{bid.taskname}</td>
                                        <td>{bid.workspacename}</td>
                                        <td>{bid.username}</td>
                                        <td>{bid.comment}</td>
                                        <td>{bid.status}</td>
                                        <td>
                                            {bid.status === 'approved' && (
                                                <button style={{ backgroundColor: 'grey' }}>Approved</button>
                                            )}
                                            {bid.status === 'pending' && (
                                                <button onClick={() => OpenCancelBidPopup(bid.id, bid.taskname)} style={{ backgroundColor: 'red', color: 'white' }}>Cancel Bid &#10006;</button>
                                            )}
                                            {bid.status === 'rejected' && (
                                                rejectedBids.some(rb => rb.taskid === bid.taskid) ? (
                                                    <button onClick={() => ShowRejectedReason(bid.taskid)} style={{ backgroundColor: '#f44336' }}>Check Reason</button>
                                                ) : (
                                                    <button style={{ backgroundColor: 'grey' }}>Rejected</button>
                                                )
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.ButtonContainer}>
                        <button onClick={Back} className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>BACK</button>
                    </div>
                    {(bidPopup || showRejectReasonPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDarkWithFlex : styles.PopupLightWithFlex}>
                                {bidPopup && (
                                    <>
                                        <button onClick={CloseCancelBidPopup} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to cancel bid for {cancelBid.name}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={CancelBid}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {showRejectReasonPopup && (
                                    <>
                                        <button onClick={CloseRejectedReason} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Bid Rejected</h1>
                                        {rejectedBids.filter((rb => rb.taskid === rejectedId)).map((bid, index) => (
                                            <table key={index} className={`${darkTheme ? styles.TableDark : styles.TableLight} ${styles.TableFont}`}>
                                                <tbody>
                                                    <tr>
                                                        <td>Task Name: </td>
                                                        <td>{bid.taskname}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reason: </td>
                                                        <td>{bid.reason}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status: </td>
                                                        <td>{bid.remark === 'bidReject' ? 'Bid Rejected' : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan='2'>
                                                            <button onClick={RemoveRejectedBid} style={{ backgroundColor: 'green', color: 'white'}}>Noted &#10004;</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffViewWorkspaceBid;