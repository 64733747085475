import React from 'react';
import styles from './Toast.module.css';

const Toast = ({ message, type, onClose }) => {
    const ToastType = 'Toast' + type;
    const handleRefresh = () => {
        window.location.reload();
    };
    return (
        <>
            <div className={`${styles.Toast} ${styles[ToastType]}`}>
                <span>{message}</span>
                <div className={styles.ButtonContainer}>
                    <button className={styles.CloseToast} onClick={onClose}>&#10006;</button>
                </div>
            </div>
        </>
    );
};

export default Toast;