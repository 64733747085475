import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspace.module.css';
import Select from 'react-select';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminViewWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('SAViewWorkspaceTempName');
    const workspaceOwner = localStorage.getItem('SAViewWorkspaceTempOwner');
    const workspaceTier = localStorage.getItem('SAViewWorkspaceTempTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ workspaceStaff, setWorkspaceStaff ] = useState([]);
    const [ workspaceDetails, setWorkspaceDetails ] = useState([]);
    const [ allUser, setAllUser ] = useState([]);
    const [ numOfStaff, setNumOfStaff ] = useState(0);
    const [ tempUsername, setTempUsername ] = useState('');
    const [ removeUserConfirmPopup, setRemoveUserConfirmPopup ] = useState(false);
    const [ addSkillsForStaffPopup, setAddSkillsForStaffPopup ] = useState(false);
    const [ addTimeslotForStaffPopup, setAddTimeslotForStaffPopup ] = useState(false);
    const addSkillArray = [];
    const deleteSkillArray = [];
    const deleteTimeslotArray = [];
    const addTimeslotArray = [];
    const [ rejectStaffRequestPopup ,setRejectStaffRequestPopup ] = useState(false);
    const [ rejectStaffRequestUsername, setRejectStaffRequestUsername ] = useState('');
    const [ inviteStaffPopup, setInviteStaffPopup ] = useState(false);
    const [ selectedStaff, setSelectedStaff ] = useState(null);
    const [ invites, setInvites ] = useState({
        staffEmail: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
        userTier: workspaceTier,
    });
    const [ cancelInviteUsername, setCancelInviteUsername ] = useState('');
    const [ cancelInvitePopup, setCancelInvitePopup ] = useState(false);
    const [ deleteWorkspacePopup, setDeleteWorkspacePopup ] = useState(false);
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    staffInWorkspace();
                                    getWorkspaceDetails();
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const staffInWorkspace = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get all staff usernames in workspace
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceStaffDetails', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no result found':
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceStaff(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getWorkspaceDetails = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get workspace details status
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspaceDetails', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no result found':
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error has occurred, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setWorkspaceDetails(decryptedData1);
                                // Calculate number of approved or pending staff
                                let staff = decryptedData1.filter(item =>
                                    item.status === "approved" ||
                                    item.status === "staffPending" ||
                                    item.status === "managerPending"
                                );
                                setNumOfStaff(staff.length);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    useEffect(() => {
        if (workspaceStaff && userRole) {
            axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
                const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { role: userRole, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        // Get all user in planner
                        axios.post('https://www.plannerforplanning.xyz/api/account.php?action=systemAdminGetAllUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            let decryptedData = JSON.parse(decrypt(response1.data));
                            switch (decryptedData) {
                                case 'no result found':
                                case 'all fields required':
                                case 'no query found':
                                    addToast('Error has occurred, please contact customer support', 'success');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    decryptedData = decryptedData.filter(item => {
                                        return !workspaceStaff.some(ws => ws.username === item.username);
                                    });
                                    decryptedData = decryptedData.filter(item => {
                                        return item.role !== 'system admin';
                                    });
                                    setAllUser(decryptedData);
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
            }).catch(error => {
                console.error('Request failed: ', error);
            });
        }
    }, [workspaceStaff, userRole]);
    const ViewStaffSchedule = (username) => {
        localStorage.setItem('SAViewStaffScheduleUsername', username);
        navigate('/systemadminviewstaffschedule');
    };
    const showConfirmRemoveUserPopup = (username) => {
        setTempUsername(username);
        setRemoveUserConfirmPopup(true);
    };
    const closeConfirmRemoveUserPopup = () => {
        setTempUsername('');
        setRemoveUserConfirmPopup(false);
    };
    const deleteUserFromWorkspace = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: tempUsername, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=suspendUserFromWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast("Sad to see someone leaving workspace, but the next one will be better!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error deleting from workspace, determination is strong", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const showAddSkillPopup = (username) => {
        setTempUsername(username);
        setAddSkillsForStaffPopup(true);
    };
    const closeAddSkillPopup = () => {
        setTempUsername('')
        setAddSkillsForStaffPopup(false);
    };
    const addSkillsForStaff = (skillname) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { skill: skillname, workspaceName: workspaceName, workspaceOwner: workspaceOwner, staffUsername: tempUsername, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=updateUserSkills', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully added ${skillname} to ${tempUsername}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast('Something went wrong, please contact customer support', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const removeSkillsForStaff = (skillname) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, staffUsername: tempUsername, skill: skillname, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Success deleting ${skillname} from ${tempUsername}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast('Error deleting, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                            case 'no result found':
                            case 'no query found':
                                addToast('Error occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const showAddTimeslotPopup = (username) => {
        setAddTimeslotForStaffPopup(true);
        setTempUsername(username);
    };
    const CloseAddTimeslotPopup = () => {
        setAddTimeslotForStaffPopup(false);
        setTempUsername('');
    };
    const DeleteTimeslot = (username, name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully remove ${timeslot} to ${username}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error removing ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const AddTimeslot = (name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: tempUsername, workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=addUserTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully added ${timeslot} to ${tempUsername}`, 'success');
                                getWorkspaceDetails();
                                break;
                            case false:
                                addToast(`Error adding ${timeslot}`, 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const approveUser = (username) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=approveUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully approved ${username} into ${workspaceName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error accepting user, please contact customer support", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'user not found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error("Request failed: ", error);
                    });
            }
        }).catch(error => {
            console.error("Request failed: ", error);
        });
    };
    const OpenRejectStaffRequest = (username) => {
        setRejectStaffRequestPopup(true);
        setRejectStaffRequestUsername(username);  
    };
    const CloseRejectStaffRequest = () => {
        setRejectStaffRequestPopup(false);
        setRejectStaffRequestUsername('');
    };
    const rejectUser = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { staffUsername: rejectStaffRequestUsername, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=rejectUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast("Painful rejection, unless you do not know him!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Error rejecting, seems like his determination is strong?", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const ShowInviteStaffPopup = () => {
        setInviteStaffPopup(true);
    };
    const CloseInviteStaffPopup = () => {
        setInviteStaffPopup(false);
    };
    const handleStaffChange = (selectedOption) => {
        setSelectedStaff(selectedOption);
        const staff = selectedOption ? selectedOption.map(option => option.value) : []; // Map selected options to their values
        setInvites(values => ({
            ...values,
            staffEmail: staff,
        }));
    };
    const SubmitInviteForm = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...invites, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=systemAdminSendInvite', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast("Invitation have been sent successfully, goodluck recruiting new staff!", 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Invitation fail to send, please contact customer support", 'error');
                                break;
                            case 'email does not exist':
                                addToast("Email does not exist at our end, please double check", 'error');
                                break;
                            case 'free version one manager':
                                addToast("Free version cannot have more than 1 manager in the workspace", 'error');
                                break;
                            case 'user not premium':
                                addToast('One of the user is using free version and have an active or pending workspace', 'error');
                                break;
                            case 'same':
                                addToast('One of the user is already in the workspace', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenCancelInvitePopup = (username) => {
        setCancelInviteUsername(username);
        setCancelInvitePopup(true);
    };
    const CloseCancelInvitePopup = () => {
        setCancelInviteUsername('');
        setCancelInvitePopup(false);
    };
    const CancelInvitation = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ownerUsername: workspaceOwner, workspaceName: workspaceName, staffUsername: cancelInviteUsername, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deletePendingInvite', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully cancelled invitation to ${cancelInviteUsername}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error cancelling invitation', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenDeleteWorkspacePopup = () => {
        setDeleteWorkspacePopup(true);
    };
    const CloseDeleteWorkspacePopup = () => {
        setDeleteWorkspacePopup(false);
    }
    const DeleteWorkspace = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ownerUsername: workspaceOwner, workspaceName: workspaceName, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully deleted ${workspaceName}`, 'success');
                                setTimeout(() => {
                                    navigate('/systemadminviewallworkspace');
                                }, 1500);
                                break;
                            case false:
                                addToast('Something else not done yet?', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                                addToast('Error, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('SAViewWorkspaceTempName');
        localStorage.removeItem('SAViewWorkspaceTempOwner');
        localStorage.removeItem('SAViewWorkspaceTempTier');
        navigate('/systemadminviewallworkspace');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('username');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username} ({userRole}), Search for user here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="username">Username</option>
                                <option value="firstname">First Name</option>
                                <option value="familyname">Family Name</option>
                                <option value="email">Email</option>
                                <option value="code">Code</option>
                                <option value="number">Number</option>
                                <option value="role">Role</option>
                                <option value="skillset">Skill</option>
                                <option value="timeslot">Timeslot</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier}) workspace, owned by {workspaceOwner}</h1>
                        <div className={styles.NumberOfStaffContainer}>
                            {workspaceTier === 'free' ? 
                                <h2>Current user count: {numOfStaff} / 10</h2> :
                                <h2>Current user count: {numOfStaff} / 10,000</h2>
                            }
                        </div>
                        {workspaceTier === 'free' && numOfStaff >= 10 ? (
                            <div className={styles.MaxOutHeader}>
                                <h2>This workspace have maxed out free version of 10 user in workspace</h2>
                            </div>
                        ) : (
                            <div className={styles.ButtonContainer}>
                                <button onClick={ShowInviteStaffPopup} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Send Invitation</button>
                                {numOfStaff === 1 && (
                                    <button onClick={OpenDeleteWorkspacePopup} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Delete Workspace</button>
                                )}
                            </div>
                        )}
                    </div>
                    <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Username: </th>
                                <th>First Name: </th>
                                <th>Family Name: </th>
                                <th>Email: </th>
                                <th>Code: </th>
                                <th>Number: </th>
                                <th>Role: </th>
                                <th>Skills: </th>
                                <th>Timeslot: </th>
                                <th>Status: </th>
                                <th>Actions: </th>
                            </tr>
                        </thead>
                        <tbody>
                            {workspaceStaff.filter((user) => {
                                if (searchField === 'skillset') {
                                    return workspaceDetails.some(ws => ws.username === user.username && ws.skillset.toLowerCase().includes(search.toLowerCase()));
                                } else if (searchField === 'timeslot') {
                                    return workspaceDetails.some(ws => ws.username === user.username && ws.timeslot.toLowerCase().includes(search.toLowerCase()));
                                } else {
                                    return search.toLowerCase() === '' ? user : user[searchField].toLowerCase().includes(search.toLowerCase());
                                }
                            }).sort((a, b) => {
                                const aIsManager = workspaceStaff.find(user => user.username === a.username && user.role === 'manager');
                                const bIsManager = workspaceStaff.find(user => user.username === b.username && user.role === 'manager');
                                const aApproved = workspaceDetails.find(user => user.username === a.username && user.status === 'approved');
                                const bApproved = workspaceDetails.find(user => user.username === b.username && user.status === 'approved');
                                const aPending = workspaceDetails.find(user => user.username === a.username && user.status === 'staffPending');
                                const bPending = workspaceDetails.find(user => user.username === b.username && user.status === 'staffPending');
                                const aMPending = workspaceDetails.find(user => user.username === a.username && user.status === 'managerPending');
                                const bMPending = workspaceDetails.find(user => user.username === a.username && user.status === 'managerPending');
                                const aDeleted = workspaceDetails.find(user => user.username === a.username && user.status === 'staffDeleted');
                                const bDeleted = workspaceDetails.find(user => user.username === a.username && user.status === 'staffDeleted');
                                const aMDeleted = workspaceDetails.find(user => user.username === a.username && user.status === 'managerDeleted');
                                const bMDeleted = workspaceDetails.find(user => user.username === a.username && user.status === 'managerDeleted');
                                const aRejected = workspaceDetails.find(user => user.username === a.username && user.status === 'staffRejected');
                                const bRejected = workspaceDetails.find(user => user.username === a.username && user.status === 'staffRejected');
                                const aMRejected = workspaceDetails.find(user => user.username === a.username && user.status === 'managerRejected');
                                const bMRejected = workspaceDetails.find(user => user.username === a.username && user.status === 'managerRejected');
                                const aSuspend = workspaceDetails.find(user => user.username === a.username && user.status === 'suspended');
                                const bSuspend = workspaceDetails.find(user => user.username === a.username && user.status === 'suspended');
                                if (aIsManager && !bIsManager) {
                                    return -1; // a comes before b
                                } else if (!aIsManager && bIsManager) {
                                    return 1; // b comes before a
                                } else {
                                    if (aApproved && !bApproved) {
                                        return -1; // a comes before b
                                    } else if (!aApproved && bApproved) {
                                        return 1; // b comes before a
                                    } else {
                                        if(aPending && !bPending) {
                                            return -1 // a comes before b
                                        } else if (!aPending && bPending) {
                                            return 1 // b comes before a
                                        } else {
                                            if (aMPending && !bMPending) {
                                                return -1;
                                            } else if (!aMPending && bMPending) {
                                                return 1
                                            } else {
                                                if (aDeleted && !bDeleted) {
                                                    return -1;
                                                } else if (!aDeleted && bDeleted) {
                                                    return 1;
                                                } else {
                                                    if (aMDeleted && !bMDeleted) {
                                                        return -1;
                                                    } else if (!aMDeleted && bMDeleted) {
                                                        return 1;
                                                    } else {
                                                        if (aRejected && !bRejected) {
                                                            return -1;
                                                        } else if (!aRejected && bRejected) {
                                                            return 1;
                                                        } else {
                                                            if (aMRejected && !bMRejected) {
                                                                return -1;
                                                            } else if (!aMRejected && bMRejected) {
                                                                return 1;
                                                            } else {
                                                                if (aSuspend && !bSuspend) {
                                                                    return -1;
                                                                } else if (!aSuspend && bSuspend) {
                                                                    return 1;
                                                                } else {
                                                                    return 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }).map((user, index) => (
                                <tr key={index}>
                                    <td><img src={`./pp/${user.picture}`} /></td>
                                    <td>{user.username}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.familyname}</td>
                                    <td>{user.email}</td>
                                    <td>{user.code}</td>
                                    <td>{user.number}</td>
                                    <td>{user.role}</td>
                                    <td>{workspaceDetails.find(w => w.username === user.username)?.skillset || 'None'}</td>
                                    <td>{workspaceDetails.find(w => w.username === user.username)?.timeslot || 'None'}</td>
                                    <td>
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffRejected') && (
                                            <>Rejected by staff</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'managerRejected') && (
                                            <>Rejected by manager</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffPending') && (
                                            <>Pending from staff</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'managerPending') && (
                                            <>Pending from manager</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'staffDeleted') && (
                                            <>Staff Left</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'managerDeleted') && (
                                            <>Removed by manager</>
                                        )}
                                        {workspaceDetails.find(workspaceUser => workspaceUser.username === user.username && workspaceUser.status === 'approved') && (
                                            <>Active</>
                                        )}
                                        {workspaceDetails.find((workspaceUser) => workspaceUser.username === user.username && workspaceUser.status === 'suspended') && (
                                            <>Suspended</>
                                        )}
                                    </td>
                                    <td>
                                        {workspaceDetails.find((w) => w.username === user.username && w.status === 'managerPending') && (
                                            <>
                                                <button style={{ backgroundColor: 'green' }} onClick={() => approveUser(user.username)}>Approve &#10004;</button>
                                                <button style={{ backgroundColor: 'red' }} onClick={() => OpenRejectStaffRequest(user.username)}>Reject &#10006;</button>
                                            </>
                                        )}
                                        {workspaceDetails.find((w) => w.username === user.username && w.status === 'staffPending') && (
                                            <button style={{ backgroundColor: 'red' }} onClick={() => OpenCancelInvitePopup(user.username)}>Cancel Invitation &#10006;</button>
                                        )}
                                        {workspaceDetails.find((w) => w.username === user.username && w.status === 'approved' && w.username !== workspaceOwner) && (
                                            <>
                                                <button onClick={() => ViewStaffSchedule(user.username)}>View Schedule</button>
                                                <button onClick={() => showConfirmRemoveUserPopup(user.username)} style={{ backgroundColor: 'red' }}>Remove &#10006;</button>
                                                <button onClick={() => showAddSkillPopup(user.username)} >Add Skills</button>
                                                <button onClick={() => showAddTimeslotPopup(user.username)}>Add Time</button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.ButtonContainer}>
                        <button onClick={Back} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>BACK</button>
                    </div>
                    {(removeUserConfirmPopup || addSkillsForStaffPopup || addTimeslotForStaffPopup || cancelInvitePopup || deleteWorkspacePopup || rejectStaffRequestPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDarkWithFlex : styles.PopupLightWithFlex}>
                                {removeUserConfirmPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={closeConfirmRemoveUserPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to remove staff {tempUsername}</h1>
                                        <h1 className={styles.PopupTitle}>From Workspace {workspaceName}</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={deleteUserFromWorkspace} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Confirm Delete &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {addSkillsForStaffPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={closeAddSkillPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle} style={{ marginTop: '20px' }}>Skills that have been added: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '20px 25px' }}>Skills: </th>
                                                    <th style={{ padding: '20px 25px' }}>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(w => w.username === tempUsername).map((workspace, index) => {
                                                const timeSlotItem = workspace.skillset.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    deleteSkillArray.push(item);
                                                });
                                                return (
                                                    <tbody key={index}>
                                                        {(() => {
                                                            let rows = []
                                                            for (var i = 0; i < deleteSkillArray.length; i++) {
                                                                let skill = deleteSkillArray[i];
                                                                rows.push(
                                                                    <tr key={i}>
                                                                        <td>{skill}</td>
                                                                        <td>
                                                                            {skill === 'none' ? (
                                                                                <button style={{ backgroundColor: 'grey' }}>No Skill</button>
                                                                            ) : (
                                                                                <button onClick={() => removeSkillsForStaff(skill)} style={{ backgroundColor: 'red', color: 'white' }}>Delete Skill &#10006;</button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return rows;
                                                        })()}
                                                    </tbody>
                                                )
                                            })}
                                        </table>
                                        <h1 className={styles.PopupTitle}>Add skills for {tempUsername}: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '20px 25px' }}>Skills: </th>
                                                    <th style={{ padding: '20px 25px' }}>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(w => w.username === workspaceOwner).map((workspace, index) => {
                                                const timeSlotItem = workspace.skillset.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    addSkillArray.push(item);
                                                });
                                                const diffArray = addSkillArray.filter(item => !deleteSkillArray.includes(item));
                                                if (diffArray.length === 0) {
                                                    return (
                                                        <tbody key={index}>
                                                            <tr>
                                                                <td>none</td>
                                                                <td><button style={{ backgroundColor: 'grey' }}>No Skill</button></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                } else {
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = []
                                                                for (var i = 0; i < diffArray.length; i++) {
                                                                    let skill = diffArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{skill}</td>
                                                                            <td>
                                                                                {skill === 'none' ? (
                                                                                    <button style={{ backgroundColor: 'grey' }}>No Skill</button>
                                                                                ) : (
                                                                                    <button onClick={() => addSkillsForStaff(skill)} style={{ backgroundColor: 'green' }}>Add Skill &#10004;</button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                }
                                            })}
                                        </table>
                                    </>
                                )}
                                {addTimeslotForStaffPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseAddTimeslotPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Current timeslot for {tempUsername}: </h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '20px 25px' }}>Timeslot: </th>
                                                    <th style={{ padding: '20px 25px' }}>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(w => w.username === tempUsername).map((workspace, index) => {
                                                const timeSlotItem = workspace.timeslot.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    deleteTimeslotArray.push(item);
                                                });
                                                return (
                                                    <tbody key={index}>
                                                        {(() => {
                                                            let rows = []
                                                            for (var i = 0; i < deleteTimeslotArray.length; i++) {
                                                                let timeslot = deleteTimeslotArray[i];
                                                                rows.push(
                                                                    <tr key={i}>
                                                                        <td>{timeslot}</td>
                                                                        <td>
                                                                            {timeslot === 'none' ? (
                                                                                <button style={{ backgroundColor: 'grey' }}>No Timeslot</button>
                                                                            ) : (
                                                                                <button style={{ backgroundColor: 'red' }} onClick={() => DeleteTimeslot(workspace.username, workspace.name, workspace.owner, timeslot)}>Delete Timeslot &#10006;</button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return rows;
                                                        })()}
                                                    </tbody>
                                                )
                                            })}
                                        </table>
                                        <h1 className={styles.PopupTitle}>Add timeslot for {tempUsername}</h1>
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '20px 25px' }}>Timeslot: </th>
                                                    <th style={{ padding: '20px 25px' }}>Action: </th>
                                                </tr>
                                            </thead>
                                            {workspaceDetails.filter(w => w.username === workspaceOwner).map((workspace, index) => {
                                                const timeSlotItem = workspace.timeslot.split(', ');
                                                timeSlotItem.forEach(item => {
                                                    addTimeslotArray.push(item);
                                                });
                                                const diffArray = addTimeslotArray.filter(item => !deleteTimeslotArray.includes(item));
                                                if (diffArray.length === 0) {
                                                    return (
                                                        <tbody key={index}>
                                                            <tr>
                                                                <td>none</td>
                                                                <td><button style={{ backgroundColor: 'grey' }}>No Timeslot</button></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                } else {
                                                    return (
                                                        <tbody key={index}>
                                                            {(() => {
                                                                let rows = [];
                                                                for (var i = 0; i < diffArray.length; i++) {
                                                                    let timeslot = diffArray[i];
                                                                    rows.push(
                                                                        <tr key={i}>
                                                                            <td>{timeslot}</td>
                                                                            <td>
                                                                                {timeslot === 'none' ? (
                                                                                    <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                                ) : (
                                                                                    <button style={{ backgroundColor: 'green' }} onClick={() => AddTimeslot(workspace.name, workspace.owner, timeslot)}>Add Timeslot</button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return rows;
                                                            })()}
                                                        </tbody>
                                                    )
                                                }
                                            })}
                                        </table>
                                    </>
                                )}
                                {cancelInvitePopup && (
                                    <>
                                        <button onClick={CloseCancelInvitePopup} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to cancel invitation sent to {cancelInviteUsername}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={CancelInvitation} className={styles.ConfirmButton}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {deleteWorkspacePopup && (
                                    <>
                                        <button onClick={CloseDeleteWorkspacePopup} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to delete workspace {workspaceName}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={DeleteWorkspace} className={styles.ConfirmButton}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {rejectStaffRequestPopup && (
                                    <>
                                        <button onClick={CloseRejectStaffRequest} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to reject {rejectStaffRequestUsername}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={rejectUser} className={styles.ConfirmButton}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {inviteStaffPopup && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {inviteStaffPopup && (
                                    <>
                                        <button onClick={CloseInviteStaffPopup} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Who do you want to invite to this workspace '{workspaceName}'</h1>
                                        <form onSubmit={SubmitInviteForm}>
                                            <Select className={styles.CustomDropdownMenu} value={selectedStaff} onChange={handleStaffChange} options={allUser.map(user => ({ label: user.email, value: user.email }))} isClearable isMulti placeholder="Search for staff: " required />
                                            <div className={styles.ButtonContainer}>
                                                <button type='submit' className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Invite</button>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default SystemAdminViewWorkspace;