import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './ViewAllWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerViewAllWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allWorkspace, setAllWorkspace ] = useState([]);
    const [ allWorkspaceStaff, setAllWorkspaceStaff ] = useState([]);
    const [ confirmDeletePopup, setConfirmDeletePopup ] = useState(false);
    const [ deletingWorkspaceName, setDeletingWorkspaceName ] = useState('');
    const [ deletingWorkspaceOwner, setDeletingWorkspaceOwner ] = useState('');
    const [ showPopup, setShowPopup ] = useState(false);
    const [ popup, setPopup ] = useState({
        ownerUsername: '',
        workspaceName: '',
        workspaceTier: '',
    });
    const [ showPopup2, setShowPopup2 ] = useState(false);
    const [ popup2, setPopup2 ] = useState({
        workspaceName: '',
        workspaceOwner: '',
        skill: '',
    });
    const [ showTimeslotPopup, setShowTimeslotPopup ] = useState(false);
    const [ timeslotPopup, setTimeslotPopup ] = useState({
        workspaceName: '',
        workspaceOwner: '',
        timeslot: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response) {
                        const decryptedData = JSON.parse(decrypt(response.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                                break;
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } 
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    useEffect(() => {
        if (username, userRole, userTier) {
            if (userRole === 'manager') {
                getManagerWorkspaceDetails();
                getAllManagerWorkspaceStaff();
            } else {
                navigate('/stoptryingtobefunny');
            }
        }
    }, [navigate, username, userRole, userTier])
    const getManagerWorkspaceDetails = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    // Get all the workspace this manager is in
                    const input = { username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getAllManagerWorkspace', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error, please contact system admin', 'error');
                                break;
                            case 'no result found':
                                navigate('/managernoworkspace');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setAllWorkspace(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error("Request failed: ", error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getAllManagerWorkspaceStaff = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response1) {
            const decryptedToken = JSON.parse(decrypt(response1.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    // Get all the workspace this manager is in
                    const input = { username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getAllManagerWorkspaceStaff', encryptedPayload, { withCredentials: true }).then(function(response2) {
                        const decryptedData1 = JSON.parse(decrypt(response2.data));
                        switch(decryptedData1) {
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error, please contact system admin', 'error');
                                break;
                            case 'no result found':
                                navigate('/managernoworkspace');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setAllWorkspaceStaff(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error("Request failed: ", error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const calculateNumberOfStaff = (workspaceName, workspaceOwner) => {
        // Filter the workspace array to find entries with matching name and owner
        const matchingWorkspaces = allWorkspaceStaff.filter(workspace => workspace.name === workspaceName && workspace.owner === workspaceOwner);
        const numberOfStaff = matchingWorkspaces.length; // Count the number of matching entries
        return numberOfStaff;
    };
    const viewWorkspace = (name, owner, tier) => {
        localStorage.setItem('managerViewWorkspaceTempName', name);
        localStorage.setItem('managerViewWorkspaceTempOwner', owner);
        localStorage.setItem('managerViewWorkspaceTempTier', tier);
        navigate('/managerviewworkspace');
    };
    const ConfirmDeletePopup = (name, owner) => {
        setDeletingWorkspaceName(name);
        setDeletingWorkspaceOwner(owner);
        setConfirmDeletePopup(true);
    };
    const CloseConfirmDeletePopup = () => {
        setDeletingWorkspaceName('');
        setDeletingWorkspaceOwner('');
        setConfirmDeletePopup(false);
    };
    const deleteWorkspace = () => {
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: deletingWorkspaceName, ownerUsername: deletingWorkspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Workspace ${deletingWorkspaceName} have been deleted`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error occured, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handlePopUp = () => {
        setShowPopup(true);
    }
    const handleClosePopup = () => {
        setShowPopup(false);
    }
    const handleNameChange = (event) => {
        const { name, value } = event.target;
        setPopup(values => ({...values, [name]:value}));
        setPopup(values => ({...values, ownerUsername: username, workspaceTier: userTier }));
    };
    const addWorkspace = (event) => {
        event.preventDefault();
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...popup, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=createWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Workspace have been created successfully', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                                break;
                            case false:
                                addToast('Error creating workspace, please try again later', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handlePopup2 = (name, owner) => {
        setShowPopup2(true);
        setPopup2(prevTask => ({
            ...prevTask,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const handleClosePopup2 = () => {
        setShowPopup2(false);
        setPopup2(prevTask => ({
            ...prevTask,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const handleSkillChange = (event) => {
        const { name, value } = event.target;
        setPopup2(values => ({...values, [name]:value}));
    };
    const addSkill = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...popup2, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=addSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Success adding skill ${popup2.skill} to your workspace`, 'success');
                                getManagerWorkspaceDetails();
                                setPopup2(values => ({...values, skill: ''}));
                                break;
                            case 'all fields required':
                            case 'no result found':
                            case 'no query found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'name regex':
                                addToast('Name can only be alphanumeric', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'duplicate skill':
                                addToast('This skill is been tagged to workspace', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const deleteSkill = (name, owner, skill) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: name, workspaceOwner: owner, skillName: skill, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully deleted skill ${skill}`, 'success');
                                getManagerWorkspaceDetails();
                                break;
                            case false:
                                addToast('Error deleting, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                            case 'no result found':
                            case 'no query found':
                                addToast("Error occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspcious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenTimeslotPopup = (name, owner) => {
        setTimeslotPopup(values => ({
            ...values,
            workspaceName: name,
            workspaceOwner: owner,
        }));
        setShowTimeslotPopup(true);
    };
    const CloseTimeslotPopup = () => {
        setTimeslotPopup(values => ({
            ...values,
            workspaceName: '',
            workspaceOwner: '',
        }));
        setShowTimeslotPopup(false);
    };
    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        setTimeslotPopup(values => ({...values, [name]:value}));
    };
    const CreateTimeslot = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...timeslotPopup, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=createWorkspaceTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully created a timeslot ${timeslotPopup.timeslot}`, 'success');
                                getManagerWorkspaceDetails();
                                setTimeslotPopup(values => ({...values, timeslot: ''}));
                                break;
                            case false:
                                addToast('Error creating a timeslot, please try again later', 'error');
                                break;
                            case 'name regex':
                                addToast('Time slot only accept alpha numeric', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const DeleteTimeslot = (name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspaceTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:  
                                addToast(`Successfully deleted timeslot ${timeslot}`, 'success');
                                getManagerWorkspaceDetails();
                                break;
                            case false:
                                addToast('Error deleting timeslot, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'no timeslot found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, Search for workspace here</h1>
                        <form className={styles.SearchForm} onSubmit={(event) => event.preventDefault()}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Name</option>
                                <option value="numstaff">No. of Staff</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder={`Search for ${searchField}`}
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>This is all your active workspace currently</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Name: </th>
                                    <th>No. of member</th>
                                    <th>Tier</th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allWorkspace.filter((workspace) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    if (searchField === 'numstaff') {
                                        return search === '' ? workspace : numberOfStaff === parseInt(search, 10);
                                    } else {
                                        return search.toLowerCase() === '' ? workspace : workspace[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).filter((workspace) => {
                                    return workspace.username === username;
                                }).map((workspace, key) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    return (
                                        <tr key={key}>
                                            <td>{workspace.name}</td>
                                            <td>{numberOfStaff}</td>
                                            <td>{workspace.tier}</td>
                                            <td>
                                                <button onClick={() => viewWorkspace(workspace.name, workspace.owner, workspace.tier)}>View</button>
                                                <button onClick={() => handlePopup2(workspace.name, workspace.owner)}>Add Skills</button>
                                                <button onClick={() => OpenTimeslotPopup(workspace.name, workspace.owner)}>Add Timeslot</button>
                                                {numberOfStaff === 1 && (
                                                    <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => ConfirmDeletePopup(workspace.name, workspace.owner)}>Delete &#10006;</button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {userTier === 'premium' && (
                            <div className={styles.ButtonContainer}>
                                <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} onClick={handlePopUp}>Create Workspace</button>
                            </div>
                        )}
                        {(confirmDeletePopup || showPopup || showPopup2 || showTimeslotPopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {confirmDeletePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseConfirmDeletePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Are you sure you want to delete {deletingWorkspaceName}</h1>
                                            <h1 className={styles.PopupTitle}>Owned By {deletingWorkspaceOwner}</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={deleteWorkspace} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Confirm Delete &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {showPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={handleClosePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Create a Workspace</h1>
                                            <div className={styles.FormContainer}>
                                                <form className={darkTheme ? styles.FormDark : styles.FormLight} onSubmit={addWorkspace}>
                                                    <input type='text' className={styles.Input} name='workspaceName' placeholder='Enter Workspace Name' onChange={handleNameChange} /><br /><br />
                                                    <div className={styles.ButtonContainer}>
                                                        <button type='submit' className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>CREATE</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                    {showPopup2 && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={handleClosePopup2}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Workspace Skill Set</h1>
                                            <div className={styles.SkillTableContainer}>
                                                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                    <thead>
                                                        <tr>
                                                            <th>Workspace Name: </th>
                                                            <th>Workspace Owner: </th>
                                                            <th>Skills: </th>
                                                            <th>Action: </th>
                                                        </tr>
                                                    </thead>
                                                    {allWorkspace.map((workspace, key) => {
                                                        const show = workspace.name === popup2.workspaceName && workspace.owner === workspace.username;
                                                        if (show === true && workspace.skillset !== '') {
                                                            const stackArray = [];
                                                            let stack = workspace.skillset;
                                                            const stackItems = stack.split(', ');
                                                            stackItems.forEach(item => {
                                                                stackArray.push(item);
                                                            });
                                                            return (
                                                                <tbody key={key}>
                                                                    {(() => {
                                                                        let rows = [];
                                                                        for (var i = 0; i < stackArray.length; i++) {
                                                                            let stackValue = stackArray[i];
                                                                            rows.push(
                                                                                <tr key={i}>
                                                                                    <td>{workspace.name}</td>
                                                                                    <td>{workspace.owner}</td>
                                                                                    <td>{stackValue}</td>
                                                                                    <td>
                                                                                        {stackValue === 'none' ? (
                                                                                            <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                                        ) : (
                                                                                            <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => deleteSkill(workspace.name, workspace.owner, stackValue)}>Delete &#10006;</button>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        return rows;
                                                                    })()}
                                                                </tbody>
                                                            );
                                                        } else if (show === true && workspace.skillset === '') {
                                                            return (
                                                                <tbody key={key}>
                                                                    <tr>
                                                                        <td>No</td>
                                                                        <td>Skills</td>
                                                                        <td>Input</td>
                                                                        <td>
                                                                            <button style={{ backgroundColor: 'grey' }}>NO</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            );
                                                        }
                                                    })}
                                                </table>
                                            </div>
                                            <div className={styles.FormContainer}>
                                                <form className={darkTheme ? styles.FormDark : styles.FormLight} onSubmit={addSkill}>
                                                    <h1 className={styles.FormTitle}>Enter New Skill Here: </h1><br />
                                                    <input type="text" className={styles.Input} name="skill" placeholder='Enter new skills' onChange={handleSkillChange} value={popup2.skill} required /><br /><br />
                                                    <button type="submit" className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>ADD</button>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                    {showTimeslotPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseTimeslotPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Workspace Timeslot</h1>
                                            <div className={styles.SkillTableContainer}>
                                                <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                    <thead>
                                                        <tr>
                                                            <th>Timeslot: </th>
                                                            <th>Action: </th>
                                                        </tr>
                                                    </thead>
                                                    {allWorkspace.filter(workspace => workspace.name === timeslotPopup.workspaceName && workspace.username === timeslotPopup.workspaceOwner).map((workspace, index) => {
                                                        if (workspace.timeslot !== '') {
                                                            const timeslotArray = [];
                                                            let timeslot = workspace.timeslot;
                                                            const stackItems = timeslot.split(', ');
                                                            stackItems.forEach(item => {
                                                                timeslotArray.push(item);
                                                            });
                                                            return (
                                                                <tbody key={index}>
                                                                    {(() => {
                                                                        let rows = [];
                                                                        for (var i = 0; i < timeslotArray.length; i++) {
                                                                            let timeslotValue = timeslotArray[i];
                                                                            rows.push(
                                                                                <tr key={i}>
                                                                                    <td>{timeslotValue}</td>
                                                                                    <td>
                                                                                        {timeslotValue === 'none' ? (
                                                                                            <button style={{ backgroundColor: 'grey'}}>None</button>
                                                                                        ) : (
                                                                                            <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => DeleteTimeslot(workspace.name, workspace.owner, timeslotValue)}>Delete &#10006;</button>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        return rows;
                                                                    })()}
                                                                </tbody>
                                                            );
                                                        } else {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td>None</td>
                                                                        <td><button style={{ backgroundColor: 'grey' }}>None</button></td>
                                                                    </tr>
                                                                </tbody>
                                                            );
                                                        }
                                                    })}
                                                </table>
                                            </div>
                                            <div className={styles.FormContainer}>
                                                <form className={darkTheme ? styles.FormDark : styles.FormLight} onSubmit={CreateTimeslot}>
                                                    <h1 className={styles.FormTitle}>Add in a timeslot: </h1><br />
                                                    <input type='text' className={styles.Input} name='timeslot' placeholder='Enter a timing in 24 hours: ' onChange={handleTimeChange} value={timeslotPopup.timeslot} required/><br /><br />
                                                    <button type='submit' className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>SAVE</button>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
}

export default ManagerViewAllWorkspace;