import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import MenuList from '../MenuList/MenuList';
import axios from 'axios';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import PasswordStrength from "../../PasswordStrength";
import ToastContainer from '../ToastContainer/ToastContainer';
import { handleLogout } from '../../logout';
import styles from './ChangePassword.module.css';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ChangePassword() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ userData, setUserData ] = useState({
        username: '',
        firstname: '',
        familyname: '',
    });
    const [ showOldPassword, setShowOldPassword ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordStrength, setPasswordStrength ] = useState(false);
    const [ checks, setChecks ] = useState({
        capsLetterCheck : false,
        numberCheck: false,
        passwordLengthCheck: false,
        specialCharCheck: false,
    });
    const handleFocus = () => {
        setPasswordStrength(true);
    };
    const handleBlur = () => {
        setPasswordStrength(false);
    };
    const handleKeyUp = (event) => {
        const { value } = event.target;
        const capsLetterCheck = /[A-Z]/.test(value);
        const numberCheck = /[0-9]/.test(value);
        const passwordLengthCheck = value.length > 7 && value.length < 21;
        const specialCharCheck = /[.,/:;'"{}\[\]!@#$%^&*()=_\-+]/.test(value);
        setChecks({
            capsLetterCheck,
            numberCheck,
            passwordLengthCheck,
            specialCharCheck,
        });
    };
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else {
                                    setUsername(decryptedData.username);
                                    setUserRole(decryptedData.role);
                                    setUserTier(decryptedData.tier);
                                    setUserData(decryptedData);
                                }
                                break;
                        }
                    }).catch(error => {
                        console.error('Error fetching user information:', error);
                    });
            }
        }).catch(error => {
            console.error('Error fetching user information:', error);
        });
    }, [navigate]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(values => ({...values, [name]: value}));
    };
    const ChangePassword = (event) => {
        event.preventDefault();
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...userData, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post("https://www.plannerforplanning.xyz/api/account.php?action=changePassword", encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        console.log(decryptedText);
                        switch(decryptedText) {
                            case false:
                                addToast("Error changing password, please contact customer support", 'error');
                                break;
                            case true:
                                addToast("Password change is a successful", 'success');
                                setTimeout(() => {
                                    navigate('/viewprofile');
                                }, 1500);
                                break;
                            case 'password wrong':
                                addToast('Old password is wrong, please re-enter', 'error');
                                break;
                            case 'all fields required':
                                addToast("All fields are required", 'error');
                                break;
                            case 'password no match':
                                addToast("Password does not match", 'error');
                                break;
                            case 'invalid username or password':
                                addToast("Error validating your profile, please contact customer support", 'error');
                                break;
                            case 'old and new same':
                                addToast("Old and New password are the same", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(prevState => !prevState);
    };
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.ChangePasswordBodyDark : styles.ChangePasswordBody}>
                    <div className={styles.ChangePasswordWrapper}>
                        <h1 className={styles.ChangePasswordTitle}>Change Password</h1>
                        <form onSubmit={ChangePassword} className={styles.ChangePasswordForm}>
                            <table cellSpacing="10">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>Username: </label>
                                        </td>
                                        <td>
                                            <input type="text" name="username" value={userData.username} readOnly></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>First Name: </label>
                                        </td>
                                        <td>
                                            <input type="text" name="firstname" value={userData.firstname} readOnly></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Family Name: </label>
                                        </td>
                                        <td>
                                            <input type="text" name="familyname" value={userData.familyname} readOnly></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Old Password: </label>
                                        </td>
                                        <td>
                                            <div className={styles.PasswordContainer}>
                                                <input type={showOldPassword ? "text" : "password"} name="oldpassword" placeholder="Enter your old password" onChange={handleChange} required/>
                                                <div className={styles.IconContainer}>
                                                    {showOldPassword ? (
                                                        <IoMdEyeOff className='icon' onClick={toggleOldPasswordVisibility} />
                                                    ) : (
                                                        <IoMdEye className='icon' onClick={toggleOldPasswordVisibility} />
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Password: </label>
                                        </td>
                                        <td>
                                            <div className={styles.PasswordContainer}>
                                                <input type={showPassword ? "text" : "password"} name="newpassword" placeholder="Password" onChange={handleChange} 
                                                onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp} required/>
                                                <div className={styles.IconContainer}>
                                                    {showPassword ? (
                                                        <IoMdEyeOff className='icon' onClick={togglePasswordVisibility} />
                                                    ) : (
                                                        <IoMdEye className='icon' onClick={togglePasswordVisibility} />
                                                    )}
                                                </div>
                                                <div className={styles.PasswordStrength}>
                                                    {passwordStrength ? <PasswordStrength capsLetterFlag={checks.capsLetterCheck ? styles.valid : styles.invalid} 
                                                                        numberFlag={checks.numberCheck ? styles.valid : styles.invalid}
                                                                        passwordLengthFlag={checks.passwordLengthCheck ? styles.valid : styles.invalid}
                                                                        specialCharFlag={checks.specialCharCheck ? styles.valid : styles.invalid}/> : null}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Confirm Password: </label>
                                        </td>
                                        <td>
                                            <input type={showPassword ? "text" : "password"} name="confirmpassword" placeholder="Confirm Password" onChange={handleChange} required/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="right">
                                            {checks.capsLetterCheck && checks.numberCheck && checks.passwordLengthCheck && checks.specialCharCheck && (
                                                <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButton}>SAVE</button>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default ChangePassword;