import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './ChangeTier.module.css';
import ToastContainer from '../ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ChangeTier() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user data
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedDetails = JSON.parse(decrypt(response1.data));
                        switch (decryptedDetails) {
                            case 'no records found':
                            case 'no cookie found':
                            case 'no query found':
                                navigate('/');
                                break;
                            default:
                                if (decryptedDetails.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else {
                                    setUsername(decryptedDetails.username);
                                    setUserRole(decryptedDetails.role);
                                    setUserTier(decryptedDetails.tier);
                                }
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const downgradePlan = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no query found':
                case 'no cookie found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=downgradeTier', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Plan have been downgraded', 'success');
                                localStorage.setItem('userTier', 'free');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'cannot find user':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'more than one active workspace':
                                addToast('You have more than one active workspace, please ensure you only have one active workspace', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity detected, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const upgradePlan = () => {
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input  = { username: username, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=upgradeTier', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Plan have been upgraded', 'success');
                                localStorage.setItem('userTier', 'premium');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'cannot find user':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <div className={darkTheme ? styles.ChangeTierBodyDark : styles.ChangeTierBody}>
                    <ToastContainer toasts={toast} removeToast={removeToast} />
                    <div className={styles.ChangeTierWrapper}>
                        <div className={styles.TierContainer}>
                            <div className={styles.Card}>
                                {userTier === 'free' ? ( // Free version
                                    <h1>Free Version (Current version)</h1>
                                ) : (
                                    <h1>Free Version</h1>
                                )}
                                {userRole === 'manager' && (
                                    <>
                                        <h3>Able to create only one workspace</h3>
                                        <h3>Up to 10 users in workspace</h3>
                                        <h3>Only one manager in workspace</h3>
                                        <h3>Unlimited task</h3>
                                        <h3>No credit card needed</h3>
                                    </>
                                )}
                                {userRole === 'staff' && (
                                    <>
                                        <h3>Able to join only one workspace</h3>
                                        <h3>No credit card needed</h3>
                                    </>
                                )}
                            </div>
                            <div className={styles.ButtonContainer}>
                                {userTier === 'premium' && (
                                    <button onClick={downgradePlan} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Choose free plan</button> 
                                )}
                            </div>
                        </div>
                        <div className={styles.TierContainer}>
                            <div className={styles.Card}>
                                {userTier === 'premium' ? ( // Premium version
                                    <h1>Premium Version (Current version)</h1>
                                ) : (
                                    <h1>Premium Version</h1>
                                )}
                                {userRole === 'manager' && (
                                    <>
                                        <h3>Able to create up to 10 workspace</h3>
                                        <h3>Up to 1000 users in workspace</h3>
                                        <h3>Multiple manager in workspace</h3>
                                        <h3>Unlimited task</h3>
                                        <h3>Staff able to bid for task</h3>
                                    </>
                                )}
                                {userRole === 'staff' && (
                                    <>
                                        <h3>Able to join up to 10 workspace</h3>
                                        <h3>24/7 priority customer support</h3>
                                    </>
                                )}
                            </div>
                            <div className={styles.ButtonContainer}>
                                {userTier === 'free' && (
                                    <button onClick={upgradePlan} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Upgrade your plan</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </Layout>
        
    );
};

export default ChangeTier;