import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import Select from 'react-select';
import styles from './ViewAllWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminViewAllWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allWorkspace, setAllWorkspace ] = useState([]);
    const [ allManager, setAllManager ] = useState([]);
    const [ confirmDeletePopup, setConfirmDeletePopup ] = useState(false);
    const [ deletingWorkspaceName, setDeletingWorkspaceName ] = useState('');
    const [ deletingWorkspaceOwner, setDeletingWorkspaceOwner ] = useState('');
    const [ skillPopup, setSkillPopup ] = useState(false);
    const [ skill, setSkill ] = useState({
        workspaceName: '',
        workspaceOwner: '',
        skill: '',
    });
    const [ showTimeslotPopup, setShowTimeslotPopup ] = useState(false);
    const [ timeslotPopup, setTimeslotPopup ] = useState({
        workspaceName: '',
        workspaceOwner: '',
        timeslot: '',
    });
    const [ createWorkspacePopup, setCreateWorkspacePopup ] = useState(false);
    const [ createWorkspace, setCreateWorkspace ] = useState({
        workspaceOwner: '',
        workspaceName: '',
    });
    const [ selectedManager, setSelectedManager ] = useState(null);
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    useEffect(() => {
        if (username, userRole, userTier) {
            if (userRole === 'system admin') {
                retrieveAllWorkspace();
                getAllManager();
            } else {
                navigate('/stoptryingtobefunny');
            }
        }
    }, [navigate, username, userRole, userTier]);
    const retrieveAllWorkspace = () => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { role: userRole, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get all workspace
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=systemAdminGetAllWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no permission':
                                navigate('/stoptryingtobefunny');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setAllWorkspace(decryptedData1);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setAllWorkspace(decryptedData1);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const getAllManager = () => {
        // CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
            const decryptedToken = JSON.parse(decrypt(response2.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { role: userRole, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get all manager
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=systemAdminGetAllUser', encryptedPayload, { withCredentials: true }).then(function(response3) {
                        const decryptedData1 = JSON.parse(decrypt(response3.data));
                        switch(decryptedData1) {
                            case 'no permission':
                                navigate('/stoptryingtobefunny');
                                break;
                            case 'no query found':
                            case 'all fields required':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'no result found':
                                setAllManager([]);
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                let manager = [];
                                for (let i = 0; i < decryptedData1.length; i++) {
                                    if (decryptedData1[i].role === 'manager') {
                                        manager.push(decryptedData1[i]);
                                    }
                                }
                                setAllManager(manager);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const calculateNumberOfStaff = (workspaceName, workspaceOwner) => {
        // Filter the workspace array to find entries with matching name and owner
        const matchingWorkspaces = allWorkspace.filter(workspace => workspace.name === workspaceName && workspace.owner === workspaceOwner);
        const numberOfStaff = matchingWorkspaces.length; // Count the number of matching entries
        return numberOfStaff;
    };
    const viewWorkspace = (name, owner, tier) => {
        localStorage.setItem('SAViewWorkspaceTempName', name);
        localStorage.setItem('SAViewWorkspaceTempOwner', owner);
        localStorage.setItem('SAViewWorkspaceTempTier', tier);
        navigate('/systemadminviewworkspace');
    };
    const ConfirmDeletePopup = (name, owner) => {
        setDeletingWorkspaceName(name);
        setDeletingWorkspaceOwner(owner);
        setConfirmDeletePopup(true);
    };
    const CloseConfirmDeletePopup = () => {
        setDeletingWorkspaceName('');
        setDeletingWorkspaceOwner('');
        setConfirmDeletePopup(false);
    };
    const deleteWorkspace = () => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: deletingWorkspaceName, ownerUsername: deletingWorkspaceOwner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast('Workspace have been deleted', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error occured, please try again later', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast('Error occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenSkillsPopup = (name, owner) => {
        setSkillPopup(true);
        setSkill(prevTask => ({
            ...prevTask,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const CloseSkillsPopup = () => {
        setSkillPopup(false);
        setSkill(prevTask => ({
            ...prevTask,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const handleSkillChange = (event) => {
        const { name, value } = event.target;
        setSkill(values => ({...values, [name]:value}));
    };
    const addSkill = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...skill, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=addSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast('Success adding skill to your workspace', 'success');
                                retrieveAllWorkspace();
                                setSkill(values => ({...values, skill: ''}));
                                break;
                            case 'no query found':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                                addToast('Skill fields must be inputted', 'error');
                            case 'name regex':
                                addToast('Name can only be alphanumeric', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'duplicated skill':
                                addToast('This skill have been added into workspace', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const deleteSkill = (name, owner, skill) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: name, workspaceOwner: owner, skillName: skill, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=removeSkill', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully deleted skill ${skill}`, 'success');
                                retrieveAllWorkspace();
                                break;
                            case false:
                                addToast('Error deleting, please contact customer support', 'error');
                                break;
                            case 'all fields required':
                            case 'no query found':
                            case 'no result found':
                                addToast("Error has occured, please contact customer support", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenTimeslotPopup = (name, owner) => {
        setTimeslotPopup(values => ({
            ...values,
            workspaceName: name,
            workspaceOwner: owner,
        }));
        setShowTimeslotPopup(true);
    };
    const CloseTimeslotPopup = () => {
        setTimeslotPopup(values => ({
            ...values,
            workspaceName: '',
            workspaceOwner: '',
        }));
        setShowTimeslotPopup(false);
    };
    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        setTimeslotPopup(values => ({...values, [name]:value}));
    };
    const CreateTimeslot = (event) => {
        event.preventDefault();
            // Token
            axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...timeslotPopup, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=createWorkspaceTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully created a timeslot ${timeslotPopup.timeslot}`, 'success');
                                retrieveAllWorkspace();
                                setTimeslotPopup(values => ({...values, timeslot: ''}));
                                break;
                            case false:
                                addToast('Error creating a timeslot, please try again later', 'error');
                                break;
                            case 'name regex':
                                addToast('Time slot only accept alpha numeric', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'duplicated timeslot':
                                addToast('Timeslot have been added into workspace', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const DeleteTimeslot = (name, owner, timeslot) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { workspaceName: name, workspaceOwner: owner, timeslot: timeslot, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deleteWorkspaceTimeslot', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`Successfully deleted timeslot ${timeslot}`, 'success');
                                retrieveAllWorkspace();
                                break;
                            case false:
                                addToast('Error deleting timeslot, please try again later', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            case 'no timeslot found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenCreateWorkspacePopup = () => {
        setCreateWorkspacePopup(true);
    };
    const CloseCreateWorkspacePopup = () => {
        setCreateWorkspacePopup(false);
    };
    const handleNameChange = (event) => {
        const { name, value } = event.target;
        setCreateWorkspace(values => ({...values, [name]:value}));
    };
    const managerOption = allManager.map(user => ({
        value: `${user.username}`,
        label: `${user.username}, (${user.firstname} ${user.familyname})`,
    }));
    const handleManagerChange = (selectedOption) => {
        if (selectedOption === null) {
            setCreateWorkspace(prevTask => ({
                ...prevTask,
                workspaceOwner: '',
            }));
        } else {
            setSelectedManager(selectedOption);
            setCreateWorkspace(prevTask => ({
                ...prevTask,
                workspaceOwner: selectedOption.value,
            }));
        }
    };
    const addWorkspace = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
        const decryptedToken = JSON.parse(decrypt(response.data));
        switch (decryptedToken) {
            case 'no cookie found':
            case 'no query found':
                addToast('Error has occured, please contact customer support', 'error');
                break;
            default:
                const input = { ...createWorkspace, token: decryptedToken };
                const encryptedPayload = encrypt(JSON.stringify(input));
                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=systemAdminCreateWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                    const decryptedData = JSON.parse(decrypt(response1.data));
                    switch (decryptedData) {
                        case true:
                            addToast('Workspace have been created successfully', 'success');
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                            break;
                        case false:
                            addToast('Error creating workspace, please try again later', 'error');
                            break;
                        case 'no query found':
                        case 'all fields required':
                        case 'no result found':
                            addToast('Error has occured, please contact customer support', 'error');
                            break;
                        case 'more than one workspace':
                            addToast('Chosen user is free tier and have one active workspace', 'error');
                            break;
                        case 'token error':
                            addToast('Suspicious activity, please contact customer support', 'error');
                            break;
                        default:
                            break;
                    }
                }).catch(error => {
                    console.error('Request failed: ', error);
                });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username} ({userRole}), Search for workspace here</h1>
                        <form className={styles.SearchWorkspaceForm} onSubmit={(event) => event.preventDefault()}>
                            <select className={darkTheme ? styles.SearchWorkspaceSelectDark : styles.SearchWorkspaceSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Name</option>
                                <option value="owner">Owner</option>
                                <option value="numstaff">No. of Staff</option>
                            </select>
                            <input type="text" className={styles.SearchWorkspaceInput} name="search" id="search" placeholder={`Search for ${searchField}`}
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>This is all the active workspace in PLANNER</h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Name: </th>
                                    <th>Owner: </th>
                                    <th>No. of member</th>
                                    <th>Tier</th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allWorkspace.filter((workspace) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    if (searchField === 'numstaff') {
                                        return search === '' ? workspace : numberOfStaff === parseInt(search, 10);
                                    } else {
                                        return search.toLowerCase() === '' ? workspace : workspace[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).filter((workspace) => {
                                    return workspace.owner === workspace.username;
                                }).map((workspace, key) => {
                                    const numberOfStaff = calculateNumberOfStaff(workspace.name, workspace.owner);
                                    return (
                                        <tr key={key}>
                                            <td>{workspace.name}</td>
                                            <td>{workspace.owner}</td>
                                            <td>{numberOfStaff}</td>
                                            <td>{workspace.tier}</td>
                                            <td>
                                                <button onClick={() => viewWorkspace(workspace.name, workspace.owner, workspace.tier)}>View</button>
                                                <button onClick={() => OpenSkillsPopup(workspace.name, workspace.owner)}>Add Skills</button>
                                                <button onClick={() => OpenTimeslotPopup(workspace.name, workspace.owner)}>Add Timeslot</button>
                                                {numberOfStaff === 1 && (
                                                    <button style={{ backgroundColor: 'red' }} onClick={() => ConfirmDeletePopup(workspace.name, workspace.owner)}>Delete &#x2717;</button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} onClick={OpenCreateWorkspacePopup}>Create Workspace</button>
                        </div>
                        {(confirmDeletePopup || skillPopup || showTimeslotPopup || createWorkspacePopup) && (
                            <div className={styles.PopupContainer}>
                                <ToastContainer toasts={toast} removeToast={removeToast} />
                                <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                    {confirmDeletePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseConfirmDeletePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Are you sure you want to delete {deletingWorkspaceName}</h1>
                                            <h1 className={styles.PopupTitle}>Owned By {deletingWorkspaceOwner}</h1>
                                            <div className={styles.ButtonContainer}>
                                                <button onClick={deleteWorkspace} className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>Confirm Delete &#10004;</button>
                                            </div>
                                        </>
                                    )}
                                    {skillPopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseSkillsPopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Workspace Skillset: </h1>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th>Workspace Name: </th>
                                                        <th>Workspace Owner: </th>
                                                        <th>Skills: </th>
                                                        <th>Action: </th>
                                                    </tr>
                                                </thead>
                                                {allWorkspace.map((workspace, index) => {
                                                    const show = workspace.name === skill.workspaceName && workspace.owner === workspace.username;
                                                    if (show === true && workspace.skillset !== '') {
                                                        const stackArray = [];
                                                        let stack = workspace.skillset;
                                                        const stackItems = stack.split(', ');
                                                        stackItems.forEach(item => {
                                                            stackArray.push(item);
                                                        });
                                                        return (
                                                            <tbody key={index}>
                                                                {(() => {
                                                                    let rows = [];
                                                                    for (var i = 0; i < stackArray.length; i++) {
                                                                        let stackValue = stackArray[i];
                                                                        rows.push(
                                                                            <tr key={i}>
                                                                                <td>{workspace.name}</td>
                                                                                <td>{workspace.owner}</td>
                                                                                <td>{stackValue}</td>
                                                                                <td>
                                                                                    {stackValue === 'none' ? (
                                                                                        <button style={{ backgroundColor: 'grey'}}>None</button>
                                                                                    ) : (
                                                                                        <button style={{ backgroundColor: 'red' }} onClick={() => deleteSkill(workspace.name, workspace.owner, stackValue)}>Delete &#10006;</button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return rows;
                                                                })()}
                                                            </tbody>
                                                        );
                                                    } else if (show === true && workspace.skillset === '') {
                                                        return (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td>No</td>
                                                                    <td>Skills</td>
                                                                    <td>Input</td>
                                                                    <td>
                                                                        <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        );
                                                    }
                                                })}
                                            </table>
                                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                                <h1 className={styles.PopupTitle}>Enter New Skill Here: </h1>
                                                <form className={styles.AddSkillForm} onSubmit={addSkill}>
                                                    <input type="text" className={styles.AddSkillInput} name="skill" placeholder='Enter new skills' onChange={handleSkillChange} value={skill.skill} required /><br />
                                                    <button type="submit" className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>ADD</button>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                    {showTimeslotPopup && (
                                        <>
                                            <button onClick={CloseTimeslotPopup} className={styles.ClosePopup}>&#10006;</button>
                                            <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                                                <thead>
                                                    <tr>
                                                        <th>Workspace Name: </th>
                                                        <th>Workspace Owner: </th>
                                                        <th>Timeslot: </th>
                                                        <th>Action: </th>
                                                    </tr>
                                                </thead>
                                                {allWorkspace.filter(workspace => workspace.name === timeslotPopup.workspaceName && workspace.username === timeslotPopup.workspaceOwner).map((workspace, index) => {
                                                    if (workspace.timeslot !== '') {
                                                        const timeslotArray = [];
                                                        let timeslot = workspace.timeslot;
                                                        const stackItems = timeslot.split(', ');
                                                        stackItems.forEach(item => {
                                                            timeslotArray.push(item);
                                                        });
                                                        return (
                                                            <tbody key={index}>
                                                                {(() => {
                                                                    let rows = [];
                                                                    for (var i = 0; i < timeslotArray.length; i++) {
                                                                        let timeslotValue = timeslotArray[i];
                                                                        rows.push(
                                                                            <tr key={i}>
                                                                                <td>{workspace.name}</td>
                                                                                <td>{workspace.owner}</td>
                                                                                <td>{timeslotValue}</td>
                                                                                <td>
                                                                                    {timeslotValue === 'none' ? (
                                                                                        <button style={{ backgroundColor: 'grey'}}>None</button>
                                                                                    ) : (
                                                                                        <button style={{ backgroundColor: 'red' }} onClick={() => DeleteTimeslot(workspace.name, workspace.owner, timeslotValue)}>Delete &#10006;</button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return rows;
                                                                })()}
                                                            </tbody>
                                                        );
                                                    } else {
                                                        return (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td>No</td>
                                                                    <td>Skills</td>
                                                                    <td>Input</td>
                                                                    <td>
                                                                        <button style={{ backgroundColor: 'grey' }}>None</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        );
                                                    }
                                                })}
                                            </table>
                                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                                <form className={styles.TimeslotForm} onSubmit={CreateTimeslot}>
                                                    <h1>Add in a timeslot: </h1>
                                                    <input type='text' className={styles.SearchWorkspaceInput} name='timeslot' value={timeslotPopup.timeslot} placeholder='Enter a timing in 24 hours: ' onChange={handleTimeChange} required/>
                                                    <button type='submit'>SAVE</button>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                    {createWorkspacePopup && (
                                        <>
                                            <button className={styles.ClosePopup} onClick={CloseCreateWorkspacePopup}>&#10006;</button>
                                            <h1 className={styles.PopupTitle}>Create a workspace</h1>
                                            <div className={styles.CreateFormWorkspaceContainer}>
                                                <form className={darkTheme ? styles.CreateWorkspaceFormDark : styles.CreateWorkspaceForm} onSubmit={addWorkspace}>
                                                    <input type='text' className={styles.CreateWorkspaceInput} name='workspaceName' placeholder='Enter Workspace Name' onChange={handleNameChange} />
                                                    <Select className={styles.DropdownMenu} value={selectedManager} onChange={handleManagerChange} options={managerOption} isClearable placeholder="Search for managers" />
                                                    <button type='submit' className={darkTheme ? styles.ButtonDark : styles.ButtonLight}>CREATE</button>
                                                </form>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default SystemAdminViewAllWorkspace;