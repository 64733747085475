import React from 'react';

const PasswordStrength = ({ capsLetterFlag, numberFlag, passwordLengthFlag, specialCharFlag }) => {
    return (
        <div>
            <p className={capsLetterFlag}>Must contain 1 captial letter</p>
            <p className={numberFlag}>Must contain a number</p>
            <p className={passwordLengthFlag}>Must be between 8 to 20 characters</p>
            <p className={specialCharFlag}>Must contain 1 special character</p>
        </div>
    );
}

export default PasswordStrength;