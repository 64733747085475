import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { handleLogout } from '../../logout';
import styles from './PendingWorkspace.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffPendingWorkspace() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ pendingWorkspace, setPendingWorkspace ] = useState([]);
    const [ staffInputs, setStaffInputs ] = useState({
        staffUsername: '',
        workspaceName: "",
        ownerEmail: '',
    });
    const [ deleteRequestPopup, setDeleteRequestPopup ] = useState(false);
    const [ deleteRequest, setDeleteRequest ] = useState({
        staffUsername: '',
        workspaceName: '',
        workspaceOwner: '',
    });
    const [ rejectInvitePopup, setRejectInvitePopup ] = useState(false);
    const [ rejectInvitationPopup, setRejectInvitationPopup ] = useState({
        workspaceName: '',
        workspaceOwner: '',
        staffUsername: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    setStaffInputs(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    setDeleteRequest(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    setRejectInvitationPopup(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) { 
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no workspace found':
                                                            setPendingWorkspace([]);
                                                            break;
                                                        default:
                                                            let staffActiveWorkspace = false;
                                                            for (var i = 0; i < decryptedData1.length; i++) {
                                                                if (decryptedData1[i].status === 'approved') {
                                                                    staffActiveWorkspace = true;
                                                                }
                                                            }
                                                            if (staffActiveWorkspace === true) {
                                                                navigate('/staffviewschedule');
                                                            } else {
                                                                setPendingWorkspace(decryptedData1);
                                                            }
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny')
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const OpenDeleteRequest = (name, owner) => {
        setDeleteRequestPopup(true);
        setDeleteRequest(values => ({
            ...values,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const CloseDeleteRequest = () => {
        setDeleteRequestPopup(false);
        setDeleteRequest(values => ({
            ...values,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const DeleteRequest = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...deleteRequest, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=deletePendingWorkspace', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast("You can find a better one!", 'success');
                                setTimeout(() => {
                                    navigate('/homepage');
                                }, 1500);
                                break;
                            case false:
                            case 'no query found':
                            case 'all fields required':
                                addToast("Error has occured, please try again later", 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const acceptInvitation = (name, owner) => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { staffUsername: username, workspaceName: name, workspaceOwner: owner, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=acceptInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Welcome to ${name}!`, 'success');
                                setTimeout(() => {
                                    navigate('/homepage');
                                }, 1500);
                                break;
                            case false:
                                addToast("Hmmm... something went wrong, please try again later", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;                    
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenRejectInvite = (name, owner) => {
        setRejectInvitePopup(true);
        setRejectInvitationPopup(values => ({
            ...values,
            workspaceName: name,
            workspaceOwner: owner,
        }));
    };
    const CloseRejectInvite = () => {
        setRejectInvitePopup(false);
        setRejectInvitationPopup(values => ({
            ...values,
            workspaceName: '',
            workspaceOwner: '',
        }));
    };
    const rejectInvitation = () => {
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...rejectInvitationPopup, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=rejectInvitation', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successful rejecting ${rejectInvitationPopup.workspaceName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast("Hmmm... something went wrong, please try again later", 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;                    
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handleInput = (event) => {
        setStaffInputs(prev => ({...prev, [event.target.name]: event.target.value}));
    };
    const requestAccess = (event) => {
        event.preventDefault();
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...staffInputs, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=requestAccess', encryptedPayload, { withCredentials: true } ).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast('Request sent successfully, goodluck!', 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error have occured, please try again later', 'error');
                                break;
                            case 'manager no workspace':
                                addToast('This manager do not have a active workspace, please contact the manager or choose another workspace to join', 'error');
                                break;
                            case 'free version exceed 10':
                                addToast('Workspace is using free version and have 10 users in workspace', 'error');
                                break;
                            case 'name regex':
                                addToast('Please enter a valid name', 'error');
                                break;
                            case 'email regex':
                                addToast('Please enter a valid email', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'duplicate':
                                addToast('You have requested for this workspace already', 'error');
                                break;                                
                            default:
                                addToast("Something went wrong, please check and submit again", 'error');
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div>
                        <form className={darkTheme ? styles.JoinDark : styles.JoinLight} onSubmit={requestAccess}>
                            <h1 className={styles.JoinHeader}>Join new workspace here: </h1>
                            <div className={styles.InputBox}>
                                <input type="text" name="staffUsername" value={username} readOnly />
                                <input type="text" name="workspaceName" placeholder="Enter the workspace name here" onChange={handleInput} required />
                                <input type="text" name="ownerEmail" placeholder="Enter workspace owner email here" onChange={handleInput} required />
                            </div>
                            <div className={styles.ButtonContainer}>
                                <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>REQUEST</button>
                            </div>
                        </form>
                        <div className={styles.Wrapper}>
                            <h1 className={styles.WrapperTitle}>The following applications are still being assessed: </h1>
                            {pendingWorkspace.length > 0 ? (
                                pendingWorkspace.filter((workspace) => workspace.status === 'managerPending').length > 0 ? (
                                    pendingWorkspace.filter((workspace) => workspace.status === 'managerPending').map((workspace, index) => (
                                            <React.Fragment key={index}>
                                                <h1 className={styles.WorkspaceDetails}>{index + 1}. {workspace.name}, by {workspace.owner}</h1>
                                                <div className={styles.ButtonContainer}>
                                                    <button className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight} onClick={() => OpenDeleteRequest(workspace.name, workspace.owner)}>Cancel Request</button>
                                                </div>
                                            </React.Fragment>
                                        ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>The following workspace have removed you: </h1>
                            {pendingWorkspace.length > 0 ? (
                                pendingWorkspace.filter((workspace) => workspace.status === 'managerDeleted').length > 0 ? (
                                    pendingWorkspace.filter((workspace) => workspace.status === 'managerDeleted').map((workspace, index) => (
                                            <React.Fragment key={index}>
                                                <h1 className={styles.WorkspaceDetails}>{index + 1}. {workspace.name}, by {workspace.owner}</h1>
                                            </React.Fragment>
                                        ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>You have previously deleted you account, this is the workspace you have left: </h1>
                            {pendingWorkspace.length > 0 ? (
                                pendingWorkspace.filter((workspace) => workspace.status === 'suspended').length > 0 ? (
                                    pendingWorkspace.filter((workspace) => workspace.status === 'suspended').map((workspace, index) => (
                                        <React.Fragment key={index}>
                                            <h1 className={styles.WorkspaceDetails}>{index + 1}. {workspace.name}, by {workspace.owner}</h1>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                            <h1 className={styles.WrapperTitle}>The following workspace have sent you an invite: </h1>
                            {pendingWorkspace.length > 0 ? (
                                pendingWorkspace.filter((workspace) => workspace.status === 'staffPending').length > 0 ? (
                                    pendingWorkspace.filter((workspace) => workspace.status === 'staffPending').map((workspace, index) => (
                                        <table className={darkTheme ? styles.TableDark : styles.TableLight} key={index}>
                                            <thead>
                                                <tr>
                                                    <th>Name of workspace: </th>
                                                    <th>Owner: </th>
                                                    <th>Action: </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr> 
                                                    <td>{workspace.name}</td>
                                                    <td>{workspace.owner}</td>
                                                    <td>
                                                        <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => acceptInvitation(workspace.name, workspace.owner)}>Accept &#10004;</button>
                                                        <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => OpenRejectInvite(workspace.name, workspace.owner)}>Reject &#10006;</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ))
                                ) : (
                                    <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                                )
                            ) : (
                                <h1 className={styles.WorkspaceDetails} style={{ color: 'red' }}>No Such Workspace</h1>
                            )}
                        </div>
                    </div>
                    {(deleteRequestPopup || rejectInvitePopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {deleteRequestPopup && (
                                    <>
                                        <button onClick={CloseDeleteRequest} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to delete request to {deleteRequest.workspaceName}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={DeleteRequest} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                                {rejectInvitePopup && (
                                    <>
                                        <button onClick={CloseRejectInvite} className={styles.ClosePopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Confirm to reject {rejectInvitePopup.workspaceName}?</h1>
                                        <div className={styles.ButtonContainer}>
                                            <button onClick={rejectInvitation} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Confirm &#10004;</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffPendingWorkspace;