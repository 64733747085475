import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import MenuList from '../MenuList/MenuList';
import axios from 'axios';
import CountryCode from '../../CountryCode';
import ToastContainer from '../ToastContainer/ToastContainer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleLogout } from '../../logout';
import styles from './UpdateProfile.module.css';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function UpdateProfile() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ imgURL, setImgURL ] = useState(null);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ selectedCountryCode, setSelectedCountryCode ] = useState("");
    const [ userData, setUserData ] = useState({
        id: '',
        username: '',
        firstname: '',
        familyname: '',
        dob: '',
        email: '',
        code: '',
        number: '',
        picture: '',
        role: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user data
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                }
                                setUserData(decryptedData);
                                setImgURL(process.env.PUBLIC_URL + decryptedData['picture']);
                                setSelectedCountryCode(decryptedData['code']);
                                setSelectedDate(decryptedData['dob'] === 'none' ? '' : decryptedData['dob']);
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const selectFile = (event) => {
        event.preventDefault();
        document.getElementById('select-file').click();
    };
    const handleFileChange = (event) => {
        event.preventDefault();
        const file = (event.target.files[0]);
        if (file) {
            // Get file extension
            const fileExtension = file.name.split('.').pop().toLowerCase();
            // Check if the file is an image with a .png or .jpg/jpeg extension
            if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
                uploadFile(file);
            } else {
                addToast("Please select a valid image file (.png or .jpg/jpeg)", 'error');
            }
        }
    };
    const uploadFile = (file) => {
        const data = new FormData();
        data.append('file', file);
        data.append('username', username);
        axios.post('https://www.plannerforplanning.xyz/api/account.php?action=updatePP', data).then(function(response) {
            const decryptedText = JSON.parse(decrypt(response.data));
            switch(decryptedText) {
                case true:
                    addToast("Changing profile pic is a success, enjoy your beautiful picture!", 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                    break;
                case false:
                    addToast("Error changing profile picture, please try again later", 'error');
                    break;
                case 'no query found':
                case 'all fields required':
                case 'no result found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                case 'no file selected':
                    addToast("No files selected", 'error');
                    break;
                case 'upload fail':
                    addToast('Changing of profile picture failed', 'error');
                    break;
                default:
                    break;
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData (values => ({...values, [name]:value}));
    };
    const EditUser = (event) => {
        event.preventDefault();
        // Get CRSF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true}).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support');
                    break;
                default:
                    const input = { ...userData, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=editUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast("Update profile is successful", 'success');
                                setTimeout(() => {
                                    navigate('/viewprofile');
                                }, 1500);
                                break;
                            case false:
                                addToast("Error updating profile", 'error');
                                break;
                            case 'name regex':
                                addToast("Please enter a valid name", 'error');
                                break;
                            case 'email regex':
                                addToast("Please enter a valid email", 'error');
                                break;
                            case 'number regex':
                                addToast("Please enter a valid number", 'error');
                                break;
                            case 'email exist':
                                addToast("This email have been taken, please use another email", 'error');
                                break;
                            case 'number exist':
                                addToast("This number have been taken, please use another number", 'error');
                                break;
                            case 'all fields required':
                                addToast("All fields are required", 'error');
                                break;
                            case 'no query found':
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
        handleChange(event); // Propagate the change event to update the inputs state
    };
    const handleDOBChange = (date) => {
        setSelectedDate(date);
        setUserData(prevUserData => ({ ...prevUserData, dob: date }));
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.UpdateProfileBodyDark : styles.UpdateProfileBody}>
                    <div className={styles.UpdateProfileWrapper}>
                        <h1 className={styles.UpdateProfileWrapperTitle}>Update Profile Form</h1>
                        <div className={styles.ChangeProfilePicture}>
                            <h2 className={styles.ChangeProfilePictureTitle}>Click on profile picture to upload a new one!</h2>
                            {imgURL && <img src={"./pp/" + userData.picture} onClick={selectFile} className="pp" alt="Profile Picture" />}
                            <input type="file" name="pp" id="select-file" onChange={handleFileChange} className={styles.PictureInput} accept=".png, .jpg, .jpeg"/>
                        </div>
                        <form onSubmit={EditUser} className={styles.UpdateProfileForm}>
                            <table cellSpacing="10">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>Username: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="username" value={userData.username} onChange={handleChange} readOnly></input><br/> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>First Name: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="firstname" value={userData.firstname} onChange={handleChange} required></input><br/> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Family Name: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="familyname" value={userData.familyname} onChange={handleChange} required></input><br/> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>DOB: (Optional) </label>
                                        </td>
                                        <td>
                                            <DatePicker className={styles.UpdateCalendar} name='dob' selected={selectedDate} onChange={handleDOBChange}
                                                dateFormat="dd/MM/yyyy" placeholderText="Select DOB" isClearable showYearDropdown scrollableYearDropdown
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Email: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="email" value={userData.email} onChange={handleChange} required></input><br/> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Code: </label>
                                        </td>
                                        <td>
                                            <CountryCode onChange={handleCountryCodeChange} value={selectedCountryCode} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Number: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="number" value={userData.number} onChange={handleChange} required></input><br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>Role: </label>
                                        </td>
                                        <td>
                                            <input type="text" className={styles.NormalInput} name="role" value={userData.role} onChange={handleChange} readOnly></input><br/> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="right">
                                            <button type="submit" className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButton}>UPDATE</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default UpdateProfile;