import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import CountryCode from "../../CountryCode";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import MenuList from '../../AllUsers/MenuList/MenuList';
import PasswordStrength from '../../PasswordStrength';
import Select from 'react-select';
import styles from './CreateUser.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function SystemAdminCreateUser() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allWorkspace, setAllWorkspace ] = useState([]);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordStrength, setPasswordStrength ] = useState(false);
    const [ checks, setChecks ] = useState({
        capsLetterCheck : false,
        numberCheck: false,
        passwordLengthCheck: false,
        specialCharCheck: false,
    });
    const [ selectedCountryCode, setSelectedCountryCode ] = useState("");
    const [ selectedWorkspace, setSelectedWorkspace ] = useState([]);
    const [ userApprovedBoolean, setUserApproveBoolean ] = useState(false);
    const [ userApprove, setUserApprove ] = useState(false);
    const [ inputs, setInputs ] = useState({
        username: '',
        password: '',
        confirmpassword: '',
        firstname: '',
        familyname: '',
        email: '',
        code: '',
        number: '',
        role: '',
        selectedworkspace: '',
        userapprove: userApprove ? 1 : 0,
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.role === 'system admin') {
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { role: decryptedData.role, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=systemAdminGetAllWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no permission':
                                                            navigate('/stoptryingtobefunny');
                                                            break;
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setAllWorkspace([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            let duplicated = [];
                                                            for (let i = 0; i < decryptedData1.length; i++) {
                                                                if (decryptedData1[i].owner === decryptedData1[i].username) {
                                                                    duplicated.push(decryptedData1[i]);
                                                                }
                                                            }
                                                            setAllWorkspace(duplicated);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs (values => ({...values, [name]:value}));
    };
    const handleFocus = () => {
        setPasswordStrength(true);
    };
    const handleBlur = () => {
        setPasswordStrength(false);
    };
    const handleKeyUp = (event) => {
        const { value } = event.target;
        const capsLetterCheck = /[A-Z]/.test(value);
        const numberCheck = /[0-9]/.test(value);
        const passwordLengthCheck = value.length > 7 && value.length < 21;
        const specialCharCheck = /[.,/:;'"{}\[\]!@#$%^&*()=_\-+]/.test(value);
        setChecks({
            capsLetterCheck,
            numberCheck,
            passwordLengthCheck,
            specialCharCheck,
        });
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
        handleChange(event); // Propagate the change event to update the inputs state
    };
    const workspaceOptions = allWorkspace.map(workspace => ({
        value: `${workspace.name}, ${workspace.owner}`,
        label: `${workspace.name}, ${workspace.owner}`,
    }));
    const handleWorkspaceChange = (selectedOption) => {
        setSelectedWorkspace(selectedOption);
        setInputs(prevTask => ({
            ...prevTask,
            selectedworkspace: selectedOption.value,
        }));
        if (selectedOption.length === 0) {
            setUserApproveBoolean(false);
        } else {
            setUserApproveBoolean(true);
        }
    };
    const handleCheckboxChange = () => {
        let newValue = false;
        if (userApprove === true) {
            newValue = false;
        } else if (userApprove === false) {
            newValue = true;
        }
        setUserApprove(newValue);
        setInputs(prevTask => ({
            ...prevTask,
            userapprove: newValue ? 1 : 0,
        }));
    };
    const CreateUser = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...inputs, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/account.php?action=systemAdminCreateUser', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case true:
                                addToast(`User ${inputs.username} has been successfully created`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error registering, please try again later', 'error');
                                break;
                            case 'all fields required':
                                addToast('All fields are required', 'error');
                                break;
                            case 'password no match':
                                addToast("Passwords are not the same", 'error');
                                break;
                            case 'name regex':
                                addToast("Name should only include alphabets and spacing", 'error');
                                break;
                            case 'email regex':
                                addToast("Please enter a valid email", 'error');
                                break;
                            case 'number regex': 
                                addToast("Please enter a valid number", 'error');
                                break;
                            case 'username exist':
                                addToast("Username have been taken, please choose another one", 'error');
                                break
                            case 'email exist':
                                addToast("Email have been taken, please choose another one", 'error');
                                break;
                            case 'number exist':
                                addToast("Number have been taken, please choose another one", 'error');
                                break;
                            case 'fail to add staff to workspace':
                                addToast(`Staff account created but failed to add staff to ${inputs.selectedworkspace}`, 'error');
                                break;
                            case 'user not registered':
                                addToast('Error registering user', 'error');
                                break;
                            case 'free version exceed 10':
                                addToast.error('You are using free version and have 10 users in your workspace', 'error');
                                break
                            case 'error creating a user':
                                addToast('Error creating a user account', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <h1 className={styles.Title}>Hi {username} ({userRole}), Create a new user: </h1>
                    <h1 className={styles.Title}>Ensure password strength to unlock create button</h1>
                    <form onSubmit={CreateUser} className={styles.CreateUserForm}>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Username: </label>
                                    </td>
                                    <td>
                                        <input type="text" name="username" placeholder="Username" value={inputs.username} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Password: </label>
                                    </td>
                                    <td>
                                        <div className={styles.PasswordContainer}>
                                            <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" value={inputs.password} onChange={handleChange} 
                                                        onFocus={handleFocus} onBlur={handleBlur} onKeyUp={handleKeyUp} required/>
                                            <div className={styles.IconContainer}>
                                                {showPassword ? (
                                                    <IoMdEyeOff className='icon' onClick={togglePasswordVisibility} />
                                                ) : (
                                                    <IoMdEye className='icon' onClick={togglePasswordVisibility} />
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.PasswordStrength}>
                                            {passwordStrength ? <PasswordStrength capsLetterFlag={checks.capsLetterCheck ? styles.valid : styles.invalid} 
                                                                numberFlag={checks.numberCheck ? styles.valid : styles.invalid}
                                                                passwordLengthFlag={checks.passwordLengthCheck ? styles.valid : styles.invalid}
                                                                specialCharFlag={checks.specialCharCheck ? styles.valid : styles.invalid}/> : null}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Confirm Password: </label>
                                    </td>
                                    <td>
                                        <input type={showPassword ? "text" : "password"} name="confirmpassword" placeholder="Confirm Password" value={inputs.confirmpassword} onChange={handleChange} required/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>First Name: </label>
                                    </td>
                                    <td>
                                        <input type="text" name="firstname" placeholder="First Name" value={inputs.firstname} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Family Name: </label>
                                    </td>
                                    <td>
                                        <input type="text" name="familyname" placeholder="Family Name" value={inputs.familyname} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Email: </label>
                                    </td>
                                    <td>
                                        <input type="text" name="email" placeholder="Email" value={inputs.email} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Code: </label>
                                    </td>
                                    <td>
                                        <CountryCode onChange={handleCountryCodeChange} value={selectedCountryCode} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Number: </label>
                                    </td>
                                    <td>
                                        <input type="text" name="number" placeholder="Number" value={inputs.number} onChange={handleChange} required></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Role: </label>
                                    </td>
                                    <td>
                                        <select name="role" className={styles.UserSelect} onChange={handleChange} value={inputs.role}>
                                            <option id="null" value="">Choose your role: </option>
                                            <option id="manager" value="manager">Manager</option>
                                            <option id="staff" value="staff">Staff</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Workspace: (Optional) </label>
                                    </td>
                                    <td className={styles.ReactSelect}>
                                        <Select className={styles.DropdownMenu} value={selectedWorkspace} onChange={handleWorkspaceChange} options={workspaceOptions} isClearable placeholder="Search for workspace"/>
                                        {userApprovedBoolean && (
                                            <div className={styles.CheckBoxContainer}>
                                                <input type="checkbox" onChange={handleCheckboxChange}/> User to accept
                                            </div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" align="right">
                                        {checks.capsLetterCheck && checks.numberCheck && checks.passwordLengthCheck && checks.specialCharCheck ? (
                                            <button className={darkTheme ? styles.ButtonDark : styles.ButtonLight} type="submit">CREATE</button>
                                        ) : (
                                            <h1>Fulfill password strength to unlock button</h1>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </Layout>
        </Layout>
    );
};

export default SystemAdminCreateUser;