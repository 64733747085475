import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, EditOutlined, UserAddOutlined, RobotFilled, UserOutlined, DesktopOutlined, TeamOutlined, BarsOutlined, FileTextOutlined,
  CustomerServiceFilled, PoweroffOutlined, DollarTwoTone, BellOutlined, DeleteOutlined
} from '@ant-design/icons';
import { HiOutlineSun, HiOutlineMoon } from 'react-icons/hi';
import styles from './MenuList.module.css';
import ToastContainer from '../ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';

const MenuList = ({ darkTheme, username, userTier, userRole, onLogout, toggleTheme, collapsed, setCollapsed }) => {
  useEffect(() => {
    RetrieveNotification();
  }, [username]);
  const [ toast, setToast ] = useState([]);
  const addToast = (message, type) => {
      setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
  };
  const removeToast = (id) => {
      setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };
  // Convert hex string to WordArray
  const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
  const randomString = () => {
      // Generate 16 random bytes (128 bits)
      const array = new Uint8Array(16);
      window.crypto.getRandomValues(array);
  
      // Convert the byte array to a hexadecimal string
      const hexString = Array.from(array)
          .map(byte => byte.toString(16).padStart(2, '0'))
          .join('');
      return hexString;
  };
  // Encryption function
  const encrypt = (data) => {
      const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
      const ivHex = process.env.REACT_APP_PLANNER_API_IV;
      const key = hexToWordArray(keyHex);
      const iv = hexToWordArray(ivHex);
      const encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString(); // Returns a base64 encoded string
  };
  // Decryption function
  const decrypt = (encryptedData) => {
      const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
      const ivHex = process.env.REACT_APP_PLANNER_API_IV;
      const key = hexToWordArray(keyHex);
      const iv = hexToWordArray(ivHex);
      const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
      });
      return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
  };
  const [ notificationPopup, setNotificationPopup ] = useState(false);
  const [ notificationLog, setNotificationLog ] = useState([]);
  const [ unreadNotiCount, setUnreadNotiCount ] = useState('');
  const [ feedBackPopup, setFeedbackPopup ] = useState(false);
  const [ feedbackFormLog, setFeedbackFormLog ] = useState([]);
  const [ feedbackForm, setFeedbackForm ] = useState({
    username: '',
    feedback: '',
    tier: '',
  });
  const maxLength = 1000;
  const [ deleteFeedbackPopup, setDeleteFeedbackPopup ] = useState(false);
  const [ deleteFeedbackId, setDeleteFeedbackId ] = useState('');
  const setDarkTheme = () => {
    const newTheme = !darkTheme;
    localStorage.setItem('theme', newTheme);
    toggleTheme(newTheme);
  };
  const handleCollapsedClick = () => {
    setCollapsed(prev => !prev);
    localStorage.setItem('collapsed', !collapsed)
  };
  const OpenNotificationPopup = () => {
    setNotificationPopup(true);
    RetrieveNotification();
    MarkAsRead();
  };
  const ClosePopup = () => {
    setNotificationPopup(false);
  };
  const DeleteNotification = (content, workspacename, workspaceowner) => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { content: content, username: username, workspaceName: workspacename, workspaceOwner: workspaceowner, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/notification.php?action=deleteNotifications', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case true:
                    addToast('Successfully deleted notification', 'success');
                    RetrieveNotification();
                    break;
                  case false:
                    addToast('Error deleting notification, please try again later', 'error');
                    break;
                  case 'no query found':
                  case 'all fields required':
                  case 'no result found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const RetrieveNotification = () => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { username: username, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/notification.php?action=retrieveNotifications', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case 'no query found':
                  case 'all fields required':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'no result found':
                    setNotificationLog([]);
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    const sortedNotifications = decryptedData.sort((a, b) => {
                      return new Date(b.created) - new Date(a.created); // Sort in descending order
                    });
                    let count = 0;
                    for (let i = 0; i < sortedNotifications.length; i++) {
                      if (sortedNotifications[i].userread === 'no') {
                        count += 1;
                      }
                    }
                    setUnreadNotiCount(count);
                    setNotificationLog(sortedNotifications);
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const MarkAsRead = () => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { username: username, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/notification.php?action=markNotiAsRead', encryptedPayload, { withCredentials: true }).then(function(response1) {
                console.log(response1.data);
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case true:
                    addToast('6 Unread Notification have been marked read');
                    break;
                  case 'no query found':
                  case 'all fields required':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'no result found':
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    break;
                }
              }).catch(error => {});
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const OpenFeedbackForm = () => {
    setFeedbackPopup(true);
    if (userRole === 'system admin') {
      RetrieveFeedbackForSystemAdmin();
    } else {
      RetrieveFeedback();
    }
  };
  const CloseFeedbackForm = () => {
    setFeedbackPopup(false);
  };
  const RetrieveFeedback = () => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { username: username, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/feedback.php?action=retrieveFeedback', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case 'no query found':
                  case 'all fields required':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'no result found':
                    setFeedbackFormLog([]);
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    setFeedbackFormLog(decryptedData);
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const RetrieveFeedbackForSystemAdmin = () => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { role: userRole, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/feedback.php?action=systemAdminGetFeedback', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case 'no query found':
                  case 'all fields required':
                  case 'no permission':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'no result found':
                    setFeedbackFormLog([]);
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    setFeedbackFormLog(decryptedData);
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const handleFeedbackChange = (event) => {
    const { name, value } = event.target;
    setFeedbackForm(values => ({
      ...values,
      username: username,
      tier: userTier,
    }));
    if (value.length <= maxLength) {
      setFeedbackForm(values => ({...values, [name]:value}));
    }
  };
  const SubmitFeedBackForm = (event) => {
    event.preventDefault();
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { ...feedbackForm, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/feedback.php?action=createFeedback', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case true:
                    addToast('Your feedback form have been submitted', 'success');
                    RetrieveFeedback();
                    setFeedbackForm(values => ({...values, feedback: '', }));
                    break;
                  case false:
                    addToast('Error occured, please try again later', 'error');
                    break;
                  case 'no query found':
                  case 'all fields required':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'no result found':
                    addToast('Ticket opened with same feedback given', 'error');
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const FeedbackResolved = (id) => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { feedbackId: id, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/feedback.php?action=resolveFeedback', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case true:
                    addToast(`Ticket ${id} has been marked as resolved`, 'success');
                    RetrieveFeedbackForSystemAdmin();
                    break;
                  case false:
                    addToast(`Error resolving ticket ${id}`, 'error');
                    break;
                  case 'no query found':
                  case 'all fields required':
                  case 'no result found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  case 'token error':
                    addToast('Suspicious activity, please contact customer support', 'error');
                    break;
                  default:
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const OpenDeleteFeedbackPopup = (id) => {
    setDeleteFeedbackPopup(true);
    setDeleteFeedbackId(id);
  };
  const CloseDeleteFeedbackPopup = () => {
    setDeleteFeedbackPopup(false);
    setDeleteFeedbackId('');
  };
  const DeleteFeedback = () => {
    // Token
    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
      const decryptedToken = JSON.parse(decrypt(response.data));
      switch (decryptedToken) {
          case 'no cookie found':
          case 'no query found':
              addToast('Error has occured, please contact customer support', 'error');
              break;
          default:
              const input = { feedbackId: deleteFeedbackId, token: decryptedToken };
              const encryptedPayload = encrypt(JSON.stringify(input));
              axios.post('https://www.plannerforplanning.xyz/api/feedback.php?action=deleteFeedback', encryptedPayload, { withCredentials: true }).then(function(response1) {
                const decryptedData = JSON.parse(decrypt(response1.data));
                switch (decryptedData) {
                  case true:
                    addToast(`Successfully deleted ticket ${deleteFeedbackId}`, 'success');
                    CloseDeleteFeedbackPopup();
                    RetrieveFeedback();
                    break;
                  case false:
                    addToast(`Error deleting ticket ${deleteFeedbackId}, please try again later`, 'error');
                    break;
                  case 'no query found':
                  case 'all fields required':
                  case 'no result found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                  default:
                    break;
                }
              }).catch(error => {
                console.error('Request failed: ', error);
              });
      }
    }).catch(error => {
      console.error('Request failed: ', error);
    });
  };
  const items = [
    {
      label: <Link to="/homepage">HOME</Link>,
      icon: <HomeOutlined />,
      key: 'home'
    },
    {
      label: "Profile",
      icon: <UserOutlined />,
      key: 'profile',
      children: [
        {
          label: <Link to="/viewprofile">View Own Profile</Link>,
          icon: <UserOutlined />,
          key: 'viewprofile',
        },
        {
          label: <Link to="/changepassword">Change Password</Link>,
          icon: <EditOutlined />,
          key: 'changepassword'
        },
      ]
    },
    {
      label: "User",
      icon: <RobotFilled />,
      key: 'user',
      children: [
        {
          label: <Link to="/systemadmincreateuser">Create User</Link>,
          icon: <UserAddOutlined />,
          key: 'create',
        },
        {
          label: <Link to="/systemadminviewalluser">View All User</Link>,
          icon: <UserOutlined />,
          key: 'viewalluser'
        },
      ]
    },
    {
      label: "Workspace",
      icon: <DesktopOutlined />,
      key: 'workspace',
      children: [
        {
          label: <Link to="/staffviewallworkspace">View Your Workspace</Link>,
          icon: <TeamOutlined />,
          key: 'staffviewallworkspace',
        },
        {
          label: <Link to="/systemadminviewallworkspace">View All Workspace</Link>,
          icon: <TeamOutlined />,
          key: 'sysviewallworkspace',
        },
        {
          label: <Link to="/managerviewallworkspace">View All Workspace</Link>,
          icon: <TeamOutlined />,
          key: 'managerviewallworkspace',
        },
      ]
    },
    {
      label: 'Tasks',
      icon: <BarsOutlined />,
      key: 'task',
      children: [
        {
          label: <Link to="/systemadminviewalltask">View All Task</Link>,
          icon: <FileTextOutlined />,
          key: 'sysviewalltask',
        },
        {
          label: <Link to="/managerviewalltask">View All Task</Link>,
          icon: <FileTextOutlined />,
          key: 'managerviewalltask',
        },
        {
          label: <Link to="/staffviewalltask">View All Task</Link>,
          icon: <FileTextOutlined />,
          key: 'staffviewalltask',
        },
      ]
    },
  ];

  let filteredItems = [...items];

  if (userRole === 'system admin') { // System admin
    // Workspace
    filteredItems = filteredItems.map(item => {
      if (item.key === 'workspace') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'staffviewallworkspace' && child.key !== 'managerviewallworkspace')
        };
      }
      return item;
    });
    // Task
    filteredItems = filteredItems.map(item => {
      if (item.key === 'task') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'managerviewalltask' && child.key !== 'staffviewalltask')
        };
      }
      return item;
    });
  }

  if (userRole === 'manager') { // Manager
    // User
    filteredItems = filteredItems.filter(item => item.key !== 'user');
    // Workspace
    filteredItems = filteredItems.map(item => {
      if (item.key === 'workspace') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'sysviewallworkspace' && child.key !== 'staffviewallworkspace')
        };
      }
      return item;
    });
    // Tasks
    filteredItems = filteredItems.map(item => {
      if (item.key === 'task') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'sysviewalltask' && child.key !== 'staffviewalltask')
        };
      }
      return item;
    });
  }

  if (userRole === 'staff') { // Staff
    // User
    filteredItems = filteredItems.filter(item => item.key !== 'user');
    // Workspace
    filteredItems = filteredItems.map(item => {
      if (item.key === 'workspace') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'sysviewallworkspace' && child.key !== 'managerviewallworkspace')
        };
      }
      return item;
    });
    // Task
    filteredItems = filteredItems.map(item => {
      if (item.key === 'task') {
        return {
          ...item,
          children: item.children.filter(child => child.key !== 'sysviewalltask' && child.key !== 'managerviewalltask')
        };
      }
      return item;
    });
  }

  let slider = [];
  slider = [
    {
      label: "Collapse",
      icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
      onClick: handleCollapsedClick,
      key: "fold"
    }
  ]

  let notification = [];
  if (userRole !== 'system admin') {
    notification = [
      {
        label: `Notification (${unreadNotiCount})`,
        icon: <BellOutlined />,
        onClick: OpenNotificationPopup,
        key: 'notification'
      }
    ]
  }

  let feedback = [];
  feedback = [
    {
      label: "Customer Support",
      icon: <CustomerServiceFilled />,
      onClick: OpenFeedbackForm,
      key: 'cs'
    }
  ]

  let role = [];
  if (userRole === 'manager') {
    role = [{
      label: `Manager (${username})`,
      key: 'manager',
    }];
  } else if (userRole === 'staff') {
    role = [{
      label: `Staff (${username})`,
      key: 'staff',
    }];
  } else if (userRole === 'system admin') {
    role = [{
      label: `System Admin (${username})`,
      key: 'system admin',
    }];
  }

  let tierItems = [];
  if (userTier === 'free') {
    tierItems = [{
      label: <Link to='/changetier'>Free Version</Link>,
      icon: <DollarTwoTone />,
      key: 'freeTierItem',
    }];
    filteredItems = filteredItems.filter(item => item.key !== 'bid');
  } else if (userTier === 'premium') {
    tierItems = [{
      label: <Link to='/changetier'>Premium Version</Link>,
      icon: <DollarTwoTone />,
      key: 'premiumTierItem',
    }];
  }

  const logoutItem = {
    label: <span onClick={onLogout}>Logout</span>,
    icon: <PoweroffOutlined />,
    key: 'logout'
  };

  const lightDarkTheme = {
    label: <span onClick={toggleTheme}>Change Theme</span>,
    icon: darkTheme ? <HiOutlineSun /> : <HiOutlineMoon />,
    onClick: setDarkTheme,
    key: 'toggleTheme'
  };

  const MenuItems = filteredItems.some(item => item.key === 'logout') ? filteredItems : [...slider, ...notification, ...role, ...tierItems, ...filteredItems, ...feedback, logoutItem, lightDarkTheme];

  return (
    <div className={`container ${collapsed ? 'collapsed' : ''}`}>
      <Menu mode="vertical" theme={darkTheme ? 'dark' : 'light'} items={MenuItems} className={styles.MenuBar} />
      {(notificationPopup || feedBackPopup || deleteFeedbackPopup) && (
        <div className={styles.PopupContainer}>
          <div className={darkTheme ? styles.PopupContainerDark : styles.PopupContainerLight}>
            <ToastContainer toasts={toast} removeToast={removeToast} />
            {notificationPopup && (
              <React.Fragment>
                <button onClick={ClosePopup} className={styles.CloseButton}>&#10006;</button>
                <h1 className={styles.Title}>Notifications</h1>
                {notificationLog.length > 0 ? (notificationLog.filter((notification) => {
                  return notification.username === username;
                }).map((notification, index) => (
                  <div className={darkTheme ? styles.NotificationContainerDark : styles.NotificationContainerLight} key={index}>
                    <h1 className={styles.BinIcon} onClick={() => DeleteNotification(notification.content, notification.workspacename, notification.workspaceowner)}><DeleteOutlined /></h1>
                    <h1 className={styles.Info}>{notification.content}</h1>
                    <h1 className={styles.Date}>{notification.created}</h1>
                    <h1 className={styles.Read}>{notification.userread === 'no' ? 'Unread' : 'Read'}</h1>
                    <h1 className={styles.WorkspaceName}>Name of workspace: {notification.workspacename}</h1>
                    <h1 className={styles.WorkspaceOwner}>Owner of workspace: {notification.workspaceowner}</h1>
                  </div>
                ))) : (
                  <h1 className={styles.Info}>No Notification</h1>
                )}
              </React.Fragment>
            )}
            {feedBackPopup && (
              <React.Fragment>
                <button onClick={CloseFeedbackForm} className={styles.CloseButton}>&#10006;</button>
                {userRole !== 'system admin' && (
                  <React.Fragment>
                    <h1 className={styles.PopupTitle}>Hi {username}, what feedback or complains you would like to let PLANNER know?</h1>
                    <form onSubmit={SubmitFeedBackForm} className={styles.Form}>
                      <textarea name='feedback' className={styles.FeedbackField} onChange={handleFeedbackChange} placeholder='Let us know what you would like to tell us...' value={feedbackForm.feedback} />
                      <div className={styles.CountCharacterContainer}>
                          {feedbackForm.feedback.length}/{maxLength}
                      </div>
                      <div className={styles.ButtonContainer}>
                        <button type='submit' className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButtonLight}>Submit</button>
                      </div>
                    </form>
                  </React.Fragment>
                )}
                <div className={styles.Wrapper}>
                  <h1 className={styles.PopupTitle}>{userRole === 'system admin' ? (
                    <>Hi {username}, this are all the tickets: </>
                  ) : (
                    <>Feedback Tickets: (please allow up to 3 working days for us to reply)</>
                  )}</h1>
                  <table className={styles.Table}>
                    <thead>
                      <tr>
                        <td>Ticket Number: </td>
                        {userRole === 'system admin' && (
                          <td>Username: </td>
                        )}
                        <td>Feedback: </td>
                        <td>Date Created: </td>
                        <td>Status: </td>
                        <td>Action: </td>
                      </tr>
                    </thead>
                    <tbody>
                      {feedbackFormLog.map((feedback, index) => (
                        <tr key={index}>
                          <td>{feedback.id}</td>
                          {userRole === 'system admin' && (
                            <td>{feedback.username}</td>
                          )}
                          <td>{feedback.feedback}</td>
                          <td>{feedback.created}</td>
                          <td>{feedback.status === 'unresolved' ? (
                            <>In Progress</>
                          ) : (
                            <>Resolved</>
                          )}</td>
                          <td>
                            {userRole === 'system admin' ? (
                              <>
                                {feedback.status === 'resolved' ? (
                                  <button style={{ backgroundColor: 'red' }} onClick={() => OpenDeleteFeedbackPopup(feedback.id)}>Delete &#10006;</button>
                                ) : (
                                  <button onClick={() => FeedbackResolved(feedback.id)}>Resolved</button>
                                )}
                              </>
                            ) : (
                              <>
                                <button style={{ backgroundColor: 'red' }} onClick={() => OpenDeleteFeedbackPopup(feedback.id)}>Delete &#10006;</button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
      {deleteFeedbackPopup && (
        <div className={styles.PopupContainer}>
          <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
            <React.Fragment>
              {deleteFeedbackPopup && (
                <React.Fragment>
                  <button className={styles.CloseButton} onClick={CloseDeleteFeedbackPopup}>&#10006;</button>
                  <div className={styles.FlexContainer}>
                    <h1 className={styles.PopupTitle}>Confirm to delete ticket {deleteFeedbackId}?</h1>
                    <div className={styles.ButtonContainer}>
                      <button onClick={DeleteFeedback} className={styles.ConfirmButton}>Confirm &#10004;</button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuList;