import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginForm from './components/AllUsers/LoginForm/LoginForm';
import CreateUser from './components/AllUsers/CreateUser/CreateUser';
import HomePage from './components/HomePage';
import FourOOne from './components/AllUsers/401/401';
import ViewProfile from './components/AllUsers/ViewProfile/ViewProfile';
import UpdateProfile from './components/AllUsers/UpdateProfile/UpdateProfile';
import ChangePassword from './components/AllUsers/UpdateProfile/ChangePassword';
import ChangeTier from './components/AllUsers/ChangeTier/ChangeTier';

// System Admin
import SystemAdminViewSchedule from './components/SystemAdmin/ViewSchedule/SystemAdminViewSchedule';
import SystemAdminCreateUser from './components/SystemAdmin/CreateUser/CreateUser';
import SystemAdminViewAllUser from './components/SystemAdmin/ViewAllUser/ViewAllUser';
import SystemAdminEditUser from './components/SystemAdmin/EditUser/EditUser';
import SystemAdminChangePassword from './components/SystemAdmin/ChangePassword/ChangePassword';
import SystemAdminViewAllWorkspace from './components/SystemAdmin/ViewAllWorkspace/ViewAllWorkspace';
import SystemAdminViewWorkspace from './components/SystemAdmin/ViewWorkspace/ViewWorkspace';
import SystemAdminViewStaffSchedule from './components/SystemAdmin/ViewStaffSchedule/ViewStaffSchedule';
import SystemAdminViewAllTask from './components/SystemAdmin/ViewAllTask/ViewAllTask';
import SystemAdminViewWorkspaceTask from './components/SystemAdmin/ViewWorkspaceTask/ViewWorkspaceTask';
import SystemAdminViewWorkspaceBids from './components/SystemAdmin/ViewWorkspaceBids/ViewWorkspaceBids';

// Manager
import ManagerNoWorkspace from './components/Manager/NoWorkspace/NoWorkspace';
import ManagerViewSchedule from './components/Manager/ViewSchedule/ManagerViewSchedule';
import ManagerViewAllWorkspace from './components/Manager/ViewAllWorkspace/ViewAllWorkspace';
import ManagerViewWorkspace from './components/Manager/ViewWorkspace/ViewWorkspace';
import ManagerViewStaffSchedule from './components/Manager/ViewStaffSchedule/ViewStaffSchedule';
import ManagerViewAllTask from './components/Manager/ViewAllTask/ViewAllTask';
import ManagerViewWorkspaceTask from './components/Manager/ViewWorkspaceTask/ViewWorkspaceTask';
import ManagerViewWorkspaceBids from './components/Manager/ViewWorkspaceBids/ViewWorkspaceBids';

// Staff
import StaffPendingWorkspace from './components/Staff/PendingWorkspace/PendingWorkspace';
import StaffViewSchedule from './components/Staff/ViewSchedule/StaffViewSchedule';
import StaffViewAllWorkspace from './components/Staff/ViewAllWorkspace/ViewAllWorkspace';
import StaffViewWorkspace from './components/Staff/ViewWorkspace/ViewWorkspace';
import StaffViewAllTask from './components/Staff/ViewAllTask/ViewAllTask';
import StaffViewWorkspaceTask from './components/Staff/ViewWorkspaceTask/ViewWorkspaceTask';
import StaffViewWorkspaceBid from './components/Staff/ViewWorkspaceBid/ViewWorkspaceBid';



const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginForm />
  },
  {
    path: '/register',
    element: <CreateUser />
  },
  {
    path: '/homepage',
    element: <HomePage />
  },
  {
    path: '/stoptryingtobefunny',
    element: <FourOOne />
  },
  {
    path: '/viewprofile',
    element: <ViewProfile />
  },
  {
    path: '/updateprofile',
    element: <UpdateProfile />
  },
  {
    path: '/changepassword',
    element: <ChangePassword />
  },
  {
    path: '/changetier',
    element: <ChangeTier />
  },
  
  // System Admin
  {
    path: '/systemadminviewschedule',
    element: <SystemAdminViewSchedule />
  },
  {
    path: '/systemadmincreateuser',
    element: <SystemAdminCreateUser />
  },
  {
    path: '/systemadminviewalluser',
    element: <SystemAdminViewAllUser />
  },
  {
    path: '/systemadminedituser',
    element: <SystemAdminEditUser />
  },
  {
    path: '/systemadminchangepassword',
    element: <SystemAdminChangePassword />
  },
  {
    path: '/systemadminviewallworkspace',
    element: <SystemAdminViewAllWorkspace />
  },
  {
    path: '/systemadminviewworkspace',
    element: <SystemAdminViewWorkspace />
  },
  {
    path: 'systemadminviewstaffschedule',
    element: <SystemAdminViewStaffSchedule />
  },
  {
    path: 'systemadminviewalltask',
    element: <SystemAdminViewAllTask />
  },
  {
    path: 'systemadminviewworkspacetask',
    element: <SystemAdminViewWorkspaceTask />
  },
  {
    path: 'systemadminviewworkspacebid',
    element: <SystemAdminViewWorkspaceBids />
  },


  // Manager
  {
    path: '/managernoworkspace',
    element: <ManagerNoWorkspace />
  },
  {
    path: '/managerviewschedule',
    element: <ManagerViewSchedule />
  },
  {
    path: '/managerviewallworkspace',
    element: <ManagerViewAllWorkspace />
  },
  {
    path: '/managerviewworkspace',
    element: <ManagerViewWorkspace />
  },
  {
    path: '/managerviewstaffschedule',
    element: <ManagerViewStaffSchedule />
  },
  {
    path: '/managerviewalltask',
    element: <ManagerViewAllTask />
  },
  {
    path: '/managerviewworkspacetask',
    element: <ManagerViewWorkspaceTask />
  },
  {
    path: '/managerviewworkspacebid',
    element: <ManagerViewWorkspaceBids />
  },

  // Staff
  {
    path: '/staffpendingworkspace',
    element: <StaffPendingWorkspace />
  },
  {
    path: '/staffviewschedule',
    element: <StaffViewSchedule />
  },
  {
    path: '/staffviewallworkspace',
    element: <StaffViewAllWorkspace />
  },
  {
    path: '/staffviewworkspace',
    element: <StaffViewWorkspace />
  },
  {
    path: '/staffviewalltask',
    element: <StaffViewAllTask />
  },
  {
    path: '/staffviewworkspacetask',
    element: <StaffViewWorkspaceTask />
  },
  {
    path: '/staffviewworkspacebid',
    element: <StaffViewWorkspaceBid />
  },
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
