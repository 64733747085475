import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from 'antd';
import MenuList from '../../AllUsers/MenuList/MenuList';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import { handleLogout } from '../../logout';
import styles from './ViewAllTask.module.css';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ManagerViewAllTask() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ allWorkspace, setAllWorkspace ] = useState([]);
    const [ allTask, setAllTask ] = useState([]); 
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'manager') {
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { username: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace
                                                axios.post('https://www.plannerforplanning.xyz/api/workspace.php?action=getAllManagerWorkspace', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch (decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            navigate('/managernoworkspace');
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support');
                                                            break;
                                                        default:
                                                            setAllWorkspace(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.log('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.log('Request failed: ', error);
                                    });
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { ownerUsername: decryptedData.username, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace task
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getAllWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch (decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support');
                                                            break;
                                                        case 'no result found':
                                                            setAllTask([]);
                                                            break;
                                                        default:
                                                            setAllTask(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });                      
                            } else {
                                navigate('/stoptryingtobefunny');
                            }
                        }
                    }).catch(error => {
                        console.log('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.log('Request failed: ', error);
        });
    }, [navigate]);
    const calculateNumberOfTask = (workspaceName, workspaceOwner) => {
        // Filter the workspace array to find entries with matching name and owner
        const matchingWorkspaces = allTask.filter(task => task.workspacename === workspaceName && task.workspaceowner === workspaceOwner && task.status === 'InProgress');
        const numberOfTask = matchingWorkspaces.length; // Count the number of matching entries
        return numberOfTask;
    };
    const viewWorkspaceTask = (name, owner, tier) => {
        localStorage.setItem('managerViewTaskTempName', name);
        localStorage.setItem('managerViewTaskTempOwner', owner);
        localStorage.setItem('managerViewTaskTempTier', tier);
        navigate('/managerviewworkspacetask');
    };
    const viewBids = (name, owner, tier) => {
        localStorage.setItem('managerTempBidWorkspaceName', name);
        localStorage.setItem('managerTempBidWorkspaceOwner', owner);
        localStorage.setItem('managerTempBidWorkspaceTier', tier);
        navigate('/managerviewworkspacebid');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchBoxTitle}>Hi {username}, Search for workspace here</h1>
                        <form className={styles.SearchForm} onSubmit={(event) => event.preventDefault()}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Name</option>
                                <option value="numtask">No. of Task</option>
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder='Search for workspace here'
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>This is all your active workspace currently: </h1>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Name: </th>
                                    <th>Owner: </th>
                                    <th>No. of Active Task: </th>
                                    <th>Actions: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allWorkspace.filter((workspace) => {
                                    const numberOfTask = calculateNumberOfTask(workspace.name, workspace.owner);
                                    if (searchField === 'numtask') {
                                        return search === '' ? workspace : numberOfTask === parseInt(search, 10);
                                    } else {
                                        return search.toLowerCase() === '' ? workspace : workspace[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).filter((workspace) => {
                                    return workspace.owner === workspace.username;
                                }).map((workspace, index) => {
                                    const numberOfTask = calculateNumberOfTask(workspace.name, workspace.owner);
                                    return (
                                        <tr key={index}>
                                            <td>{workspace.name}</td>
                                            <td>{workspace.owner}</td>
                                            <td>{numberOfTask}</td>
                                            <td>
                                                <button onClick={() => viewWorkspaceTask(workspace.name, workspace.owner, workspace.tier)}>View</button>
                                                {workspace.tier === 'premium' && (
                                                    <button onClick={() => viewBids(workspace.name, workspace.owner, workspace.tier)}>View Interest</button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default ManagerViewAllTask;