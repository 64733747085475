import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../../AllUsers/MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewWorkspaceTask.module.css';
import ToastContainer from '../../AllUsers/ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function StaffViewWorkspaceTask() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const workspaceName = localStorage.getItem('staffViewTaskName');
    const workspaceOwner = localStorage.getItem('staffViewTaskOwner');
    const workspaceTier = localStorage.getItem('staffViewTaskTier');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ tasks, setTasks ] = useState([]);
    const [ ownTasks, setOwnTasks ] = useState([]);
    const [ leaveTaskRecord, setLeaveTaskRecord ] = useState([]);
    const [ bids, setBids ] = useState([]);
    const [ rejectedStaffLeaveTaskPopup, setRejectedStaffLeaveTaskPopup ] = useState(false);
    const [ tempStaffLeavetaskId, setTempStaffLeaveTaskId ] = useState('');
    const booleanSetAllTask = localStorage.getItem('setAllTaskBoolean');
    const [ allTaskBoolean, setAllTaskBoolean ] = useState(booleanSetAllTask === null ? true : JSON.parse(booleanSetAllTask));
    const [ leaveTaskPopup, setLeaveTaskPopup ] = useState(false);
    const [ leaveTask, setLeaveTask ] = useState({
        taskId: '',
        taskName: '',
        staffUsername: '',
        reason: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
    });
    const maxLength = 1000;
    const [ rejectTaskPopup, setRejectTaskPopup ] = useState(false);
    const [ rejectTask, setRejectTask ] = useState({
        taskId: '', 
        taskName: '', 
        staffUsername: '', 
        workspaceName: workspaceName, 
        workspaceOwner: workspaceOwner,
        reason: '',
    });
    const [ bidCommentPopup, setBidCommentPopup ] = useState(false);
    const [ bidComment, setBidComment ] = useState({
        taskId: '',
        taskName: '',
        staffUsername: '',
        workspaceName: workspaceName,
        workspaceOwner: workspaceOwner,
        comment: '',
    });
    useEffect(() => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    navigate('/');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } else if (decryptedData.role === 'staff') {
                                    setRejectTask(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    setLeaveTask(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    setBidComment(values => ({
                                        ...values,
                                        staffUsername: decryptedData.username,
                                    }));
                                    // Get CSRF token
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all workspace task
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setTasks([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setTasks(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: decryptedData.username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get own task
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getStaffWorkspaceTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setOwnTasks([]);
                                                            break;
                                                        case 'token error':
                                                            addToast('Suspicious activity, please contact customer support', 'error');
                                                            break;
                                                        default:
                                                            setOwnTasks(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: decryptedData.username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all pending leave task application
                                                axios.post('https://www.plannerforplanning.xyz/api/task.php?action=getStaffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            toast.error('Error has occured, please contact customer support');
                                                            break;
                                                        case 'no result found':
                                                            setLeaveTaskRecord([]);
                                                            break;
                                                        default:
                                                            setLeaveTaskRecord(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed: ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                    axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response2) {
                                        const decryptedToken = JSON.parse(decrypt(response2.data));
                                        switch (decryptedToken) {
                                            case 'no cookie found':
                                            case 'no query found':
                                                addToast('Error has occured, please contact customer support');
                                                break;
                                            default:
                                                const input = { staffUsername: decryptedData.username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                                                const encryptedPayload = encrypt(JSON.stringify(input));
                                                // Get all bidded bids
                                                axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=staffGetOwnBid', encryptedPayload, { withCredentials: true }).then(function(response3) {
                                                    const decryptedData1 = JSON.parse(decrypt(response3.data));
                                                    switch(decryptedData1) {
                                                        case 'no query found':
                                                        case 'all fields required':
                                                            addToast('Error has occured, please contact customer support', 'error');
                                                            break;
                                                        case 'no result found':
                                                            setBids([]);
                                                            break;
                                                        default:
                                                            setBids(decryptedData1);
                                                            break;
                                                    }
                                                }).catch(error => {
                                                    console.error('Request failed, ', error);
                                                });
                                        }
                                    }).catch(error => {
                                        console.error('Request failed: ', error);
                                    });
                                } else {
                                    navigate('/stoptryingtobefunny');
                                }
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    const startDate = (day, month, year) => {
        return day + '/' + month + '/' + year;
    };
    const OpenStaffLeaveTaskReject = (id) => {
        setRejectedStaffLeaveTaskPopup(true);
        setTempStaffLeaveTaskId(id);
    };
    const CloseStaffLeaveTaskReject = () => {
        setRejectedStaffLeaveTaskPopup(false);
        setTempStaffLeaveTaskId('');
    };
    const SeeAllTask = () => {
        localStorage.setItem('setAllTaskBoolean', JSON.stringify(true));
        setAllTaskBoolean(true);
    };
    const SeeOwnTask = () => {
        localStorage.setItem('setAllTaskBoolean', JSON.stringify(false));
        setAllTaskBoolean(false);
    };
    const AcceptTask = (id, name) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { taskId: id, taskName: name, staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/task.php?action=staffAcceptTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast('Successfully accepting task allocated to you!', 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error accepting task, please try again later', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                case 'no result found':
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenRejectTaskPopup = (id, name) => {
        setRejectTaskPopup(true);
        setRejectTask(prevData => ({
            ...prevData,
            taskId: id,
            taskName: name,
        }));
    };
    const CloseRejectTaskPopup = () => {
        setRejectTaskPopup(false);
        setRejectTask(prevData => ({
            ...prevData,
            taskId: '',
            taskName: '',
        }));
    };
    const handleRejectTaskChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= maxLength) {
            setRejectTask(values => ({...values, [name]:value}));
        }
    };
    const RejectTask = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { ...rejectTask, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/task.php?action=staffRejectTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast(`Successfully rejected task allocated to you`, 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error accepting task, please try again later', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                                case 'duplicated record':
                                    addToast(`You have already sent a request to reject for this ${rejectTask.taskName}`, 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenLeaveTaskPopup = (id, name) => {
        setLeaveTask(values => ({
            ...values,
            taskId: id,
            taskName: name,
        }));
        setLeaveTaskPopup(true);
    };
    const CloseLeaveTaskPopup = () => {
        setLeaveTask(values => ({
            ...values,
            taskId: '',
            taskName: '',
            reason: '',
        }));
        setLeaveTaskPopup(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= maxLength) {
            setLeaveTask(values => ({...values, [name]:value}));
        }
    };
    const SubmitLeaveTaskForm = (event) =>{
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { ...leaveTask, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/task.php?action=staffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast('Reason have been sent to workspace manager!', 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error, please try again later', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                                case 'reason regex':
                                    addToast('Reasoning only accept alpha numeric', 'error');
                                    break;
                                case 'duplicated record':
                                    addToast('You have made a leave request for this task', 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const OpenBidCommentPopup = (id, name) => {
        setBidCommentPopup(true);
        setBidComment(prevData => ({
            ...prevData,
            taskId: id,
            taskName: name,
        }));
    };
    const CloseBidCommentPopup = () => {
        setBidCommentPopup(false);
        setBidComment(prevData => ({
            ...prevData,
            taskId: '',
            taskName: '',
            comment: '',
        }));
    };
    const handleBidCommentChange = (event) => {
        const { name, value } = event.target;
        if (value.length <= maxLength) {
            setBidComment(values => ({...values, [name]:value}));
        }
    }
    const BidFullStaffTask = (event) => {
        event.preventDefault();
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
        const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const input = { ...bidComment, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=bidFullStaffTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedText = JSON.parse(decrypt(response1.data));
                        switch (decryptedText) {
                            case true:
                                addToast(`Successfully bidded for ${bidComment.workspaceName}`, 'success');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                                break;
                            case false:
                                addToast('Error bidding, please try again later', 'error');
                                break;
                            case 'bid already':
                                addToast('You have already bidded this task', 'error');
                                break;
                            case 'token error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            case 'no query found':
                            case 'all fields required':
                            case 'no result found':
                            default:
                                addToast('Error has occured, please contact customer support', 'error');
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const BidAutoTask = (id, name) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { taskId: id, taskName: name, staffUsername: username, workspaceName: workspaceName, workspaceOwner: workspaceOwner, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/bid.php?action=bidAutoTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast(`Successfully bidded for ${name}`, 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error bidding, please try again later', 'error');
                                    break;
                                case 'bid already':
                                    addToast('You have already bidded this task', 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                case 'no result found':
                                default:
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const RemoveStaffLeaveTask = (taskid, taskname, username) => {
        // Token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
                switch (decryptedToken) {
                    case 'no cookie found':
                    case 'no query found':
                        addToast('Error has occured, please contact customer support', 'error');
                        break;
                    default:
                        const input = { taskId: taskid, taskName: taskname, staffUsername: username, token: decryptedToken };
                        const encryptedPayload = encrypt(JSON.stringify(input));
                        axios.post('https://www.plannerforplanning.xyz/api/task.php?action=removeStaffLeaveTask', encryptedPayload, { withCredentials: true }).then(function(response1) {
                            const decryptedText = JSON.parse(decrypt(response1.data));
                            switch (decryptedText) {
                                case true:
                                    addToast('Acknowledged success', 'success');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                    break;
                                case false:
                                    addToast('Error acknowledging, please try again later', 'error');
                                    break;
                                case 'token error':
                                    addToast('Suspicious activity, please contact customer support', 'error');
                                    break;
                                case 'no query found':
                                case 'all fields required':
                                case 'no result found':
                                default:
                                    addToast('Error has occured, please contact customer support', 'error');
                                    break;
                            }
                        }).catch(error => {
                            console.error('Request failed: ', error);
                        });
                }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    };
    const Back = () => {
        localStorage.removeItem('staffViewTaskName');
        localStorage.removeItem('staffViewTaskOwner');
        localStorage.removeItem('staffViewTaskTier');
        navigate('/staffviewalltask');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    // Search
    const [ search, setSearch ] = useState('');
    const [ searchField, setSearchField ] = useState('name');
    const searchRef = useRef();
    const formatDate = (day, month, year) => {
        // Ensure single-digit days and months are zero-padded
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedYear = year.toString();
        return `${formattedDay}/${formattedMonth}/${formattedYear}`;
    };
    const filterTasksByStartDate = (task, search) => {
        // Extract date components from the task
        const taskStartDate = formatDate(task.startday, task.startmonth, task.startyear);
    
        // Compare formatted dates
        return taskStartDate.toLowerCase().includes(search.toLowerCase());
    };
    const filterTasksByEndDate = (task, search) => {
        // Format task end date
        const taskEndDate = formatDate(task.endday, task.endmonth, task.endyear);
        
        // Compare formatted dates
        return taskEndDate.toLowerCase().includes(search.toLowerCase());
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} workspaceTier={workspaceTier} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.BackgroundDark : styles.BackgroundLight}>
                    <div className={darkTheme ? styles.SearchDark : styles.SearchLight}>
                        <h1 className={styles.SearchTitle}>Hi {username}, Search for task here: </h1>
                        <form className={styles.SearchForm}>
                            <select className={darkTheme ? styles.SearchSelectDark : styles.SearchSelectLight} value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                                <option value="name">Task Name</option>
                                <option value="staff">Staff</option>
                                <option value="startdate">Start Date</option>
                                <option value="enddate">End Date</option>
                                <option value="numdays">No. of Days</option>
                                <option value="numhours">No. of hours</option>
                                <option value="status">Status</option>                            
                            </select>
                            <input type="text" className={styles.SearchInput} name="search" id="search" placeholder="Search for staff..."
                            onChange={(e) => setSearch(e.target.value)} ref={searchRef}/>
                        </form>
                    </div>
                    <div className={styles.Wrapper}>
                        <h1 className={styles.TableTitle}>Workspace of {workspaceName} ({workspaceTier} workspace)</h1>
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={SeeAllTask}>All Task</button>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} style={{ marginLeft: '20px' }} onClick={SeeOwnTask}>Own Task</button>
                        </div>
                        <table className={darkTheme ? styles.TableDark : styles.TableLight}>
                            <thead>
                                <tr>
                                    <th>Task Name: </th>
                                    <th>Staff: </th>
                                    <th>Staff Status: </th>
                                    <th>Start Date: </th>
                                    <th>Start Time: </th>
                                    <th>No. of Days: </th>
                                    <th>No. of Hours: </th>
                                    <th>Status: </th>
                                    <th>Action: </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(allTaskBoolean ? tasks : ownTasks).filter((task) => {
                                    if (searchField === 'startdate') {
                                        return search.toLowerCase() === '' ? task : filterTasksByStartDate(task, search);
                                    } else if (searchField === 'enddate') {
                                        return search.toLowerCase() === '' ? task : filterTasksByEndDate(task, search);
                                    } else {
                                        return search.toLowerCase() === '' ? task : task[searchField].toLowerCase().includes(search.toLowerCase());
                                    }
                                }).map((task, index) => (
                                    <tr key={index}>
                                        <td>{task.name}</td>
                                        <td>{task.staff === 'none' || task.staff === '' ? 'none' : task.staff}</td>
                                        <td>
                                            {(() => {
                                                const valuesArray = task.staffstatus.split(', ').map(item => item.trim());
                                                let statusString = ''
                                                for (let i = 0; i < valuesArray.length; i++) {
                                                    if (valuesArray[i] === 'yes') {
                                                        if (statusString === '') {
                                                            statusString = 'Approved';
                                                        } else {
                                                            statusString += ', Approved';
                                                        }
                                                    } else if (valuesArray[i] === 'no') {
                                                        if (statusString === '') {
                                                            statusString = 'Pending';
                                                        } else {
                                                            statusString += ', Pending';
                                                        }
                                                    } else if (valuesArray[i] === '' || valuesArray[i] === 'none') {
                                                        statusString = 'none';
                                                    }
                                                }
                                                return statusString;
                                            })()}
                                        </td>
                                        <td>{startDate(task.startday, task.startmonth, task.startyear)}</td>
                                        <td>{task.starttime}</td>
                                        <td>{task.numdays}</td>
                                        <td>{task.numhours}</td>
                                        <td>{task.status}</td>
                                        <td>
                                            {task.status === 'blockout' && (
                                                <button style={{ backgroundColor: 'grey' }}>Blockout</button>
                                            )}
                                            {task.status === 'Done' ? (
                                                <button style={{ backgroundColor: 'grey' }}>Task Over</button>
                                            ) : (
                                                <>
                                                    {(() => {
                                                        let names = '';
                                                        let status = '';
                                                        if (task.staff === 'none' || task.staff === '') {
                                                            names = [];
                                                            status = [];
                                                        } else {
                                                            names = task.staff.split(', ');
                                                            status = task.staffstatus.split(', ');
                                                        }
                                                        let pos = 0;
                                                        let exist = false;
                                                        for (let i = 0; i < names.length; i++) { // Check if involved
                                                            if (names[i] === username) { // If involved then can leave task
                                                                exist = true;
                                                                pos = i
                                                            } else {
                                                                exist = false;
                                                            }
                                                        }
                                                        if (leaveTaskRecord.some(leave => parseInt(task.id) === parseInt(leave.taskid) && task.name === leave.taskname && leave.username === username && leave.status === 'pending')) {
                                                            return (
                                                                <button style={{ backgroundColor: 'grey' }}>Pending</button>
                                                            );
                                                        }
                                                        if (leaveTaskRecord.some(leave => parseInt(task.id) === parseInt(leave.taskid) && task.name === leave.taskname && leave.username === username && leave.status === 'managerRejected')) {
                                                            return (
                                                                <button onClick={() => OpenStaffLeaveTaskReject(task.id)} style={{ backgroundColor: '#f44336' }}>Reason</button>
                                                            );
                                                        }
                                                        if (names[pos] === username && status[pos] === 'no') {
                                                            return (
                                                                <>
                                                                    <button onClick={() => AcceptTask(task.id, task.name)} style={{ backgroundColor: 'green', color: 'white' }}>Accept &#10004;</button>
                                                                    <button onClick={() => OpenRejectTaskPopup(task.id, task.name)} style={{ backgroundColor: 'red', color: 'white' }}>Reject &#10006;</button>
                                                                </>
                                                            );
                                                        } else if (names[pos] === username && status[pos] === 'yes') {
                                                            return (
                                                                <button onClick={() => OpenLeaveTaskPopup(task.id, task.name)} style={{ backgroundColor: 'red', color: 'white' }}>Leave Task &#10006;</button>
                                                            );
                                                        }
                                                        if (exist === false) {
                                                            if (bids.some(bid => parseInt(bid.taskid) === parseInt(task.id) && bid.taskname === task.name && bid.username === username && bid.status !== 'approved')) {
                                                                return (
                                                                    <button style={{ backgroundColor: 'grey' }}>Interest Expressed</button>
                                                                );
                                                            } else if (names.length < parseInt(task.numstaff)) {
                                                                return (
                                                                    <button onClick={() => BidAutoTask(task.id, task.name)}>Express Interest</button>
                                                                );
                                                            } else if (names.length >= parseInt(task.numstaff)) {
                                                                return (
                                                                    <button onClick={() => OpenBidCommentPopup(task.id, task.name)}>Express Interest</button>
                                                                );
                                                            }
                                                        } else {
                                                            // Nothing to display
                                                        }
                                                    })()}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.ButtonContainer}>
                            <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={Back}>BACK</button>
                        </div>
                    </div>
                    {(leaveTaskPopup || bidCommentPopup || rejectTaskPopup || rejectedStaffLeaveTaskPopup) && (
                        <div className={styles.PopupContainer}>
                            <ToastContainer toasts={toast} removeToast={removeToast} />
                            <div className={darkTheme ? styles.PopupDark : styles.PopupLight}>
                                {leaveTaskPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseLeaveTaskPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Reason for leaving this task: </h1>
                                        <form onSubmit={SubmitLeaveTaskForm} className={styles.LeaveTaskForm}>
                                            <textarea type='text' name='reason' placeholder='Enter your reason for leaving task: ' value={leaveTask.reason} onChange={handleChange} />
                                            <div className={styles.CountCharacterContainer}>
                                                {leaveTask.reason.length}/{maxLength}
                                            </div>
                                            <div className={styles.ButtonContainer}>
                                                <button type='submit' className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>SEND</button>
                                            </div>
                                        </form>
                                    </>
                                )}
                                {bidCommentPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseBidCommentPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Reason for bidding this task: </h1>
                                        <form onSubmit={BidFullStaffTask} className={styles.LeaveTaskForm}>
                                            <textarea type='text' name='comment' placeholder='Enter your reason for bidding this task: ' value={bidComment.comment} onChange={handleBidCommentChange} />
                                            <div className={styles.CountCharacterContainer}>
                                                {bidComment.comment.length}/{maxLength}
                                            </div>
                                            <div className={styles.ButtonContainer}>
                                                <button type='submit' className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>SEND</button>
                                            </div>
                                        </form>
                                    </>
                                )}
                                {rejectTaskPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseRejectTaskPopup}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Reason for rejecting this task: </h1>
                                        <form onSubmit={RejectTask} className={styles.LeaveTaskForm}>
                                            <textarea type='text' name='reason' placeholder='Enter your reason for rejecting this task: ' value={rejectTask.reason} onChange={handleRejectTaskChange} />
                                            <div className={styles.CountCharacterContainer}>
                                                {rejectTask.reason.length}/{maxLength}
                                            </div>
                                            <div className={styles.ButtonContainer}>
                                                <button type='submit' className={darkTheme ? styles.SubmitDark : styles.SubmitLight}>SEND</button>
                                            </div>
                                        </form>
                                    </>
                                )}
                                {rejectedStaffLeaveTaskPopup && (
                                    <>
                                        <button className={styles.ClosePopup} onClick={CloseStaffLeaveTaskReject}>&#10006;</button>
                                        <h1 className={styles.PopupTitle}>Manager has rejected your request to leave task: </h1>
                                        {leaveTaskRecord.filter(task => parseInt(task.taskid) === parseInt(tempStaffLeavetaskId) && task.status === 'staffPending').map((task, index) => (
                                            <div className={styles.TableContainer} key={index}>
                                                <table className={styles.CreateTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Task ID: </td>
                                                            <td>{task.taskid}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Task Name: </td>
                                                            <td>{task.taskname}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status: </td>
                                                            <td>Manager Rejected</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reason for rejecting: </td>
                                                            <td>{task.reason}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" align="right">
                                                                <button className={darkTheme ? styles.SubmitDark : styles.SubmitLight} onClick={() => RemoveStaffLeaveTask(task.taskid, task.taskname, task.username)}>Noted &#10004;</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        </Layout>
    );
};

export default StaffViewWorkspaceTask;