import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuList from '../MenuList/MenuList';
import { Layout } from 'antd';
import { handleLogout } from '../../logout';
import styles from './ViewProfile.module.css';
import ToastContainer from '../ToastContainer/ToastContainer';
import CryptoJS from 'crypto-js';
const { Sider } = Layout;

function ViewProfile() {
    const navigate = useNavigate();
    const [ username, setUsername ] = useState('');
    const [ userRole, setUserRole ] = useState('');
    const [ userTier, setUserTier ] = useState('');
    const theme = localStorage.getItem('theme');
    const [ darkTheme, setDarkTheme ] = useState(theme !== '' ? JSON.parse(theme) : true);
    const col = localStorage.getItem('collapsed');
    const [ collapsed, setCollapsed ] = useState(col !== '' ? JSON.parse(col) : false);
    const [ toast, setToast ] = useState([]);
    const addToast = (message, type) => {
        setToast((prevToasts) => [...prevToasts, { id: Date.now(), message, type }]);
    };
    const removeToast = (id) => {
        setToast((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };
    const randomString = () => {
        // Generate 16 random bytes (128 bits)
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
    
        // Convert the byte array to a hexadecimal string
        const hexString = Array.from(array)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
        return hexString;
    };
    // Convert hex string to WordArray
    const hexToWordArray = (hex) => CryptoJS.enc.Hex.parse(hex);
    // Encryption function
    const encrypt = (data) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString(); // Returns a base64 encoded string
    };
    // Decryption function
    const decrypt = (encryptedData) => {
        const keyHex = process.env.REACT_APP_PLANNER_API_KEY;
        const ivHex = process.env.REACT_APP_PLANNER_API_IV;
        const key = hexToWordArray(keyHex);
        const iv = hexToWordArray(ivHex);
        const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return bytes.toString(CryptoJS.enc.Utf8); // Returns the original string
    };
    const [ userData, setUserData ] = useState({
        username: '',
        firstname: '',
        familyname: '',
        dob: '',
        email: '',
        code: '',
        number: '',
        role: '',
    });
    const [ imgURL, setImgURL ] = useState('');
    useEffect(() => {
        // Get CSRF token
        axios.post('https://www.plannerforplanning.xyz/api/csrf.php?action=createToken', {}, { withCredentials: true }).then(function(response) {
            const decryptedToken = JSON.parse(decrypt(response.data));
            switch (decryptedToken) {
                case 'no cookie found':
                case 'no query found':
                    addToast('Error has occured, please contact customer support', 'error');
                    break;
                default:
                    const randString = randomString();
                    const encryptedRandString = encrypt(JSON.stringify(randString));
                    const input = { randString: randString, encryptedRandString: encryptedRandString, token: decryptedToken };
                    const encryptedPayload = encrypt(JSON.stringify(input));
                    // Get user details
                    axios.post('https://www.plannerforplanning.xyz/api/cookie.php?action=getUserDetails', encryptedPayload, { withCredentials: true }).then(function(response1) {
                        const decryptedData = JSON.parse(decrypt(response1.data));
                        switch (decryptedData) {
                            case 'no cookie found':
                            case 'no result found':
                            case 'cookie has expired':
                            case 'no query found':
                                navigate('/');
                            case 'token error':
                            case 'string error':
                                addToast('Suspicious activity, please contact customer support', 'error');
                                break;
                            default:
                                setUsername(decryptedData.username);
                                setUserRole(decryptedData.role);
                                setUserTier(decryptedData.tier);
                                if (decryptedData.activity === 'suspended') {
                                    addToast('Your account have been suspended', 'warn');
                                    navigate('/');
                                } 
                                
                                if (decryptedData.dob === 'none') {
                                    var formattedDate = 'Have not set yet';
                                } else {
                                    const dob = new Date(decryptedData.dob); // Create a new Date object from the ISO 8601 date string
                                    var formattedDate = formatDate(dob); // Format the date
                                }
                                setUserData(decryptedData);
                                setUserData(prevUserData => ({
                                    ...prevUserData,
                                    dob: formattedDate // Replace the original date with the formatted one
                                }));
                                setImgURL(process.env.PUBLIC_URL + decryptedData['picture']);
                                break;
                        }
                    }).catch(error => {
                        console.error('Request failed: ', error);
                    });
            }
        }).catch(error => {
            console.error('Request failed: ', error);
        });
    }, [navigate]);
    // Function to format the date as 'DD MonthName YYYY'
    function formatDate(date) {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    const UpdateProfile = () => {
        navigate('/updateprofile');
    };
    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
    };
    const handleLogoutClick = () => {
        handleLogout(navigate);
    };
    return (
        <Layout>
            <Sider collapsed={collapsed} collapsible trigger={null} theme={darkTheme ? 'dark' : 'light'} className='sidebar'>
                <MenuList darkTheme={darkTheme} username={username} userTier={userTier} userRole={userRole} onLogout={handleLogoutClick} toggleTheme={toggleTheme} collapsed={collapsed} setCollapsed={setCollapsed} />
            </Sider>
            <Layout>
                <ToastContainer toasts={toast} removeToast={removeToast} />
                <div className={darkTheme ? styles.ViewProfileBodyDark : styles.ViewProfileBody}>
                    <div className={styles.ViewProfileWrapper}>
                        <h1 className={styles.ViewProfileTitle}>Review your profile details</h1>
                        <div className={styles.ProfilePicture}>
                            {imgURL && <img src={"./pp/" + userData.picture} alt={userData.picture} />}
                        </div><br />
                        <table className={styles.ViewProfileTable} cellSpacing="10">
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Username: </label>
                                    </td>
                                    <td>
                                        <label>{userData.username}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>First Name: </label>
                                    </td>
                                    <td>
                                        <label>{userData.firstname}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Family Name: </label>
                                    </td>
                                    <td>
                                        <label>{userData.familyname}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>DOB: </label>
                                    </td>
                                    <td>
                                        <label>{userData.dob === 'none' ? 'None' : userData.dob}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Email: </label>
                                    </td>
                                    <td>
                                        <label>{userData.email}</label> 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Code: </label>
                                    </td>
                                    <td>
                                        <label>{userData.code}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Number: </label>
                                    </td>
                                    <td>
                                        <label>{userData.number}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Role: </label>
                                    </td>
                                    <td>
                                        <label>{userData.role}</label> 
                                    </td>
                                </tr>
                                <tr>
                                <td colSpan="2" align="right">
                                    <button type="submit" onClick={UpdateProfile} className={darkTheme ? styles.SubmitButtonDark : styles.SubmitButton}>UPDATE PROFILE</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </Layout>
    );
};

export default ViewProfile;